<template>
  <div>
    <a-statistic
      :title="title"
      :value="value < 0 ? `-${currency}` + moneyFormat(value*-1).substr(1) : `${currency}` + moneyFormat(value).substr(1)"
      style="margin-right: 50px"
    />
  </div>
</template>

<script>
export default {
  props: ["title", "value", "currency"],
  methods: {
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      if (number < 0) {
        return formatter.format(number);
      }
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
