<template>
  <div>
    <div class="last-invoices">
      <div class="title" style="display: flex; justify-content: start">
        <h3><b>{{ $t('Last Invoices') }}</b></h3>
      </div>
      <a-row>
        <a-col :span="24">
          <a-table :pagination="false" :scroll="{ x: 1000 }" v-if="lastInvocieLoader" :columns="columns" :data-source="data"
            @change="onChange">
            <template #bodyCell="{ column, text, record }">
              <!-- <template v-if="column.key === 'btn'">
                <a-button @click="showDrawer(text)">
                  <EyeOutlined />
                </a-button>
              </template> -->
              <template v-if="column.key === 'customer_id'">
                <span>{{
                  record.customer_id != null ? record.customer_id.name : "-"
                }}</span>
              </template>
              <template v-if="column.key === 'branch_id'">
                <span>{{ record.branch_id != null ? record.branch_id.name : "-" }}</span>
              </template>
              <template v-if="column.key === 'invoice_date'">
                <span>{{ text ? text.substr(0, 10) : "-" }}</span>
              </template>
              <template v-if="column.key === 'products'">
                <span>{{ text.length }}</span>
              </template>
              <template v-if="column.key === 'amount'">
                <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
                  moneyFormat(text).substr(1) }}</span>
                    <!-- <span>{{ record.currency }}{{ moneyFormat(text).substr(1) }}</span> -->
              </template>
              <template v-if="column.key === 'type'">
                <div class="" style="display: flex;justify-content: start;align-items: center;">
                  <div class="">
                    <a-tag v-if="text == 'sell'" message="Sell" color="green">{{
                      $t("Sell")
                    }}</a-tag>
                    <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
                  </div>
                  <div class="">
                    <a-tag v-if="record.remainingAmount == 0" message="Paid" color="blue">{{
                      $t("Paid")
                    }}</a-tag>
                    <a-tag v-else message="Return" color="orange">{{ $t("No Paid") }}</a-tag>
                    <a-tag v-if="record.isReal" message="E-arşiv" color="red">{{
                      $t("E-arşiv")
                    }}</a-tag>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
          <Loader v-else />
          <a-drawer v-if="visibleDetailLoad" :title="invoiceDetail.invoice_no" placement="right" :closable="false"
            v-model:visible="visibleDetail" :after-visible-change="afterVisibleChange">
            <InvoiceDetail :data="invoiceDetail" />
          </a-drawer>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { EditOutlined, EyeOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import InvoiceDetail from "../invoice/InvoiceDetail.vue";
import Loader from "../shared/Loader.vue";

export default {
  props: ["columns", "data", "lastInvocieLoader"],
  components: { Loader, EditOutlined, EyeOutlined, InvoiceDetail },
  data() {
    return {
      visibleDetail: true,
      visibleDetailLoad: false,
      invoiceDetail: {},
    };
  },
  methods: {
    showDrawer(id) {
      this.visibleDetail = true;
      axios
        .get(`/api/invoice/${id}`)
        .then((result) => {
          this.invoiceDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.invoiceDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
