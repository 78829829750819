<template>
  <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
    <div class="logo"></div>
    <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
      <a-menu-item key="1">
        <router-link to="/home">
          <home-outlined />
          <span>Home</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="2">
        <router-link to="/invoices">
          <home-outlined />
          <span>Invoices</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="3">
        <router-link to="/products">
          <home-outlined />
          <span>Products</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu key="sub1">
        <template #icon>
          <home-outlined />
        </template>
        <template #title>Product Variables</template>
        <a-menu-item key="4"
          ><router-link to="/categories">Categories</router-link></a-menu-item
        >
        <a-menu-item key="5"><router-link to="/brands">Brands</router-link></a-menu-item>
        <a-menu-item key="6"
          ><router-link to="/materials">Materials</router-link></a-menu-item
        >
        <a-menu-item key="7"
          ><router-link to="/patterns">Patterns</router-link></a-menu-item
        >
        <a-menu-item key="8"
          ><router-link to="/seasons">Seasons</router-link></a-menu-item
        >
      </a-sub-menu>
      <a-menu-item key="9">
        <router-link to="/branches">
          <home-outlined />
          <span>Branches</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="10">
        <router-link to="/customers">
          <home-outlined />
          <span>Customers</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="11">
        <router-link to="/internals">
          <home-outlined />
          <span>Internals</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="12">
        <router-link to="/income-expenses">
          <home-outlined />
          <span>Income & Expenses</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu key="sub2">
        <template #icon>
          <home-outlined />
        </template>
        <template #title>Reports</template>
        <a-menu-item key="13"
          ><router-link to="/sale-report">Sale Reports</router-link></a-menu-item
        >
        <a-menu-item key="14"
          ><router-link to="/product-report">Product Reports</router-link></a-menu-item
        >
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  GlobalOutlined,
  HomeOutlined,
  EuroOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons-vue";
export default {
  props:["collapsed"],
  components: {
    UserOutlined,
    EuroOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BellOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    ArrowLeftOutlined,
    GlobalOutlined,
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
