<template>
  <div>
    <div class="main">
      <div class="left">
        <img style="width: 100%; height: 100%; opacity: 0.03" src="@/assets/images/login.png" alt="" />
      </div>
      <div class="rigth">
        <div class="form">
          <div class="" style="display: flex; justify-content: center; padding: 10px">
            <Language />
          </div>
          <div class="">
            <div class="" style="
                width: 180px;
                height: 180px;
                display: flex;
                justify-content: center;
                margin: 0px 0;
              ">
              <!-- <h1><b>TEXCOFA</b></h1> -->
              <img style="width: 100%; height: 100%" src="@/assets/images/logo/logo.png" alt="" />
            </div>
          </div>
          <div class="">
            <h1>{{ $t("Sign In") }}</h1>
          </div>
          <a-form layout="vertical" @submit="login()" @keyup.enter="login()">
            <a-form-item :label="$t('E-mail')">
              <a-input v-model:value="form.email" type="text" :placeholder="$t('E-mail')" />
            </a-form-item>
            <a-form-item :label="$t('Password')">
              <a-input-password v-model:value="form.password" type="password" :placeholder="$t('Password')" />
            </a-form-item>
            <a-button :loading="loading" type="button" @click="login()">{{
              $t("Login")
            }}</a-button>
          </a-form>
          <div class="" style="margin: 15px 0">
            <router-link to="/register">{{ $t("Sign Up") }}</router-link>
          </div>
          <div class="" style="margin: 15px 0">
            <router-link to="/forgot-password">{{ $t("Forgot Password?") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import axios from "axios";
import Language from "@/components/shared/Language.vue";
export default {
  components: {
    Language,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
      isError: false,
    };
  },
  mounted() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    axios
      .get(`api/locale/ip`)
      .then((result) => {
        if (localStorage.getItem("lang") == null) {
          localStorage.setItem("lang", result.data.lang);
          this.$i18n.locale = result.data.lang;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    async login() {
      this.loading = true;
      await axios
        .post("/api/auth/login", this.form)
        .then(async (result) => {
          if (result.data.token) {
            localStorage.setItem("token", result.data.token);
            axios.defaults.headers.common[
              "Authorization"
            ] = await `Bearer ${localStorage.getItem("token")}`;
            await axios
              .get("/api/auth/user")
              .then(async (result) => {
                localStorage.setItem("user", JSON.stringify(result.data));
                if (result.data.isActive) {
                  await notification.success({
                    message: this.$t("Success"),
                    description: this.$t("You are logged into the system"),
                    duration: 1.5,
                  });
                  this.loading = false;
                  await this.$router.push("/home");
                } else {
                  await notification.warning({
                    message: this.$t("no subscription"),
                    description: this.$t("You do not have any subscriptions"),
                    duration: 1.5,
                  });
                  this.loading = false;
                }
              })
              .catch((err) => {
                this.loading = false;
              });
          } else {
            this.isError = true;
            this.loading = false;
            await notification.warning({
              message: this.$t("Error"),
              description: this.$t("Email or Password is Incorrect"),
              duration: 1.5,
            });
          }
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.warning({
            message: this.$t("Error"),
            description: this.$t("Email or Password is Incorrect"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
}

.left {
  background-color: #001529;
  height: 100vh;
  width: 100%;
}

.rigth {
  background-color: #f0f2f5;
  height: 100vh;
  width: 60%;
  display: flex;
  justify-content: center;
}

.form {}

@media only screen and (max-width: 900px) {
  .left {
    display: none;
  }

  .rigth {
    width: 100%;
  }
}
</style>
