<template>
  <div>
    <div class="">
      <div class="info">
        <h3>{{$t('Info')}}</h3>
        <div class="type" style="margin-bottom: 10px">
          <span>{{$t('Type')}}:</span>
          <span style="margin-left: 15px">
            <a-tag v-if="data.type == 'income'" message="Income" color="green"
              >{{$t('Income')}}</a-tag
            >
            <a-tag v-else message="Exprens" color="yellow">{{$t('Exprens')}}</a-tag>
          </span>
        </div>
        <div class="name" style="margin-bottom: 10px">
          <span
            >{{$t('NO')}}: <b>{{ data.income_no }}</b></span
          >
        </div>
        <div class="title" style="margin-bottom: 10px">
          <span
            >{{$t('Title')}}: <b>{{ data.title }}</b></span
          >
        </div>
        <div class="price" style="margin-bottom: 10px">
          <span
            >{{$t('Price')}}: <b>{{data.price < 0 ? `-${data.currency}` + moneyFormat(data.price*-1).substr(1) : `${data.currency}` + moneyFormat(data.price).substr(1) }}</b></span
          >
          <!-- <span
            >{{$t('Price')}}: <b>{{data.currency}}{{ moneyFormat(data.price).substr(1) }}</b></span
          > -->
        </div>
        <div class="date" style="margin-bottom: 10px">
          <span
            >{{$t('Date')}}: <b>{{ data.createdAt.substr(0, 10) }}</b></span
          >
        </div>
      </div>
      <a-divider />
      <div class="desc">
        <h3>{{$t('Description')}}</h3>
        <p>{{ data.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
