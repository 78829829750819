<template>
  <div>
    <div class="info">
      <h3>{{ $t("Info") }}</h3>
      <div class="name" style="margin-bottom: 10px">
        <span>{{ $t("Total Sale Quantity") }}: <b>{{ `${this.data.totalSaleQuantity} ${$t("qty")}` }}</b></span>
      </div>
      <div class="name" style="margin-bottom: 10px">
        <span>{{ $t("Total Return Quantity") }}: <b>{{ `${this.data.totalReturnQuantity} ${$t("qty")}` }}</b></span>
      </div>
    </div>
    <a-divider />

    <a-table :scroll="{ x: 450 }" :columns="currnecyColumns" :data-source="this.data.totalSalePrice" @change="onChange">
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.key === 'totalPrice'">
          <span>{{
            text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
            moneyFormat(text).substr(1) }}</span>
        </template>
        <template v-if="column.key === 'totalSale'">
          <span>{{
            text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
            moneyFormat(text).substr(1) }}</span>
        </template>
        <template v-if="column.key === 'totalReturn'">
          <span>{{
            text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
            moneyFormat(text).substr(1) }}</span>
        </template>
      </template>
    </a-table>

    <a-divider />
    <div class="invoices">
      <h3>{{ $t("Invoices") }}</h3>
      <a-table :scroll="{ x: 1000 }" :columns="columns" :data-source="data" @change="onChange">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div class="" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 15px;
                ">
              <a-button @click="showDrawer(text)">
                <EyeOutlined />
              </a-button>
            </div>
          </template>
          <template v-if="column.key === 'branch_id'">
            <span>{{ record.branch_id != null ? record.branch_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'customer_id'">
            <span>{{ record.customer_id != null ? record.customer_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'invoice_date'">
            <span>{{ text ? text.substr(0, 10) : "-" }}</span>
          </template>
          <template v-if="column.key === 'amount'">
            <span>{{
              text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
              moneyFormat(text).substr(1) }}</span>
                <!-- <span>{{ record.currency }}{{ moneyFormat(text).substr(1) }}</span> -->
          </template>
          <template v-if="column.key === 'products'">
            <span>{{
              record.products
              ? text.reduce((accumulator, object) => {
                return accumulator + object.quantity;
              }, 0)
              : "0"
            }}</span>
          </template>
          <template v-if="column.key === 'type'">
            <a-tag v-if="text == 'sell'" message="Sell" color="green">{{
              $t("Sell")
            }}</a-tag>
            <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
          </template>
        </template>
      </a-table>
    </div>
    <a-drawer v-if="visibleDetailLoad" :title="`${$t('Invoice')} ` + invoiceDetail.invoice_no" placement="right"
      :closable="false" v-model:visible="visibleDetail" :after-visible-change="afterVisibleChange">
      <InvoiceDetail :data="invoiceDetail" />
    </a-drawer>
  </div>
</template>

<script>
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import InvoiceDetail from "@/components/invoice/InvoiceDetail.vue";

export default {
  props: ["data"],
  created() {
    console.log(this.data);
  },
  data() {
    return {
      currnecyColumns: [
        {
          title: this.$t("Currency"),
          dataIndex: "currency",
          key: "currency",
        },
        {
          title: this.$t("Total"),
          dataIndex: "totalPrice",
          key: "totalPrice",
        },
        {
          title: this.$t("Total Sale"),
          dataIndex: "totalSale",
          key: "totalSale",
        },
        {
          title: this.$t("Total Return"),
          dataIndex: "totalReturn",
          key: "totalReturn",
        },
      ],
      columns: [
        {
          title: this.$t("Invoice No"),
          dataIndex: "invoice_no",
          key: "invoice_no",
        },
        {
          title: this.$t("Branch"),
          dataIndex: "branch_id",
          key: "branch_id",
        },
        {
          title: this.$t("Customer"),
          dataIndex: "customer_id.name",
          key: "customer_id",
        },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "products",
        },
        {
          title: this.$t("Amount"),
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: this.$t("Date"),
          dataIndex: "invoice_date",
          key: "invoice_date",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: this.data.invoices,
      visibleDetail: false,
      invoiceDetail: {},
      visibleDetailLoad: true,
    };
  },
  methods: {
    async showDrawer(id) {
      this.invoiceDetail = await {};
      await axios
        .get(`/api/invoice/${id}`)
        .then((result) => {
          this.visibleDetail = true;
          this.invoiceDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.invoiceDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: { EyeOutlined, DeleteOutlined, EditOutlined, InvoiceDetail },
};
</script>

<style lang="scss" scoped></style>
