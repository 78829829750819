<template>
  <div>
    <div
      style="background-color: #5d90f7; margin-top: 10px"
      v-if="user.isFreeTrial == true"
    >
      <span style="color: #fff"
        ><b
          >{{ $t('You are currently using a free trial version') }}.
          {{ new Date(user.isFreeTrialDate).toLocaleDateString() }} {{$t('your free trial will expire and you will start paying')}}</b
        ></span
      >
    </div>
  </div>
</template>

<script>
export default {
    props:["user"]
};
</script>

<style lang="scss" scoped></style>
