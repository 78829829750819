<template>
  <div>
    <div class="" style="margin-bottom: 50px">
      <div class="info">
        <h3>{{ $t("Info") }}</h3>
        <div class="name" style="margin-bottom: 10px">
          <span>{{ $t("NO") }}: <b>{{ data.transfer_id }}</b></span>
        </div>
        <div class="from_branch" style="margin-bottom: 10px">
          <span>{{ $t("From Branch") }}: <b>{{ data.from_branch_id.name }}</b></span>
        </div>
        <div class="to_branch" style="margin-bottom: 10px">
          <span>{{ $t("To Branch") }}: <b>{{ data.to_branch_id.name }}</b></span>
        </div>
        <div class="branch" style="margin-bottom: 10px">
          <span>{{ $t("Date") }}: <b>{{ data.date.substr(0, 10) }}</b></span>
        </div>
      </div>
      <a-divider v-if="data.desc" />
      <div class="desc" v-if="data.desc">
        <h3>{{ $t("Description") }}</h3>
        <div class="description" style="margin-bottom: 10px">
          <span>{{ data.desc }}</span>
        </div>
      </div>
      <a-divider />
      <div class="products">
        <h3>{{ $t("Products") }}</h3>
        <div class="product" style="margin-bottom: 10px">
          <span>{{ $t("Product Type") }}:
            <b>{{ `${data.products.length} ${$t("qty")}` }}</b></span>
        </div>
        <div class="product" style="margin-bottom: 10px">
          <span>{{ $t("Total Quantity") }}:
            <b>{{
              data.products.reduce((accumulator, object) => {
                return accumulator + object.quantity;
              }, 0)
            }}
              {{ $t("qty") }}</b></span>
        </div>
        <div class="colors">
          <a-table :scroll="{ x: 300 }" :columns="columns" :data-source="columnsData" @change="onChange">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key == 'sku'">
                <span>{{ record.sku }}</span>
              </template>
              <template v-if="column.key == 'quantity'">
                <span>{{ record.quantity }}</span>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div :style="{
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'right',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px',
    }">
      <a-button :loading="printLoad" type="primary" @click="pdf(data._id)">
        <PrinterOutlined />{{ $t("Print") }}
      </a-button>
      <!-- <a-popover trigger="hover">
        <template #content>
          <span>{{ $t("if you want to print this data first") }} </span>
          <span style="margin: 0 10px"
            ><a href="https://qz.io/download/" target="_blank">QZ Tray</a></span
          >
          <span>{{
            $t("You need to download and run the program on your computer")
          }}</span>
        </template>
        <QuestionOutlined style="margin: 0 15px" />
      </a-popover>
      <a-button @click="visibleChangePrint" style="margin-right: 15px">
        <SwapOutlined />{{ $t("Change Printer") }}</a-button
      >
      <a-button :loading="printLoad" type="primary" @click="selectPrinter(data._id)">
        <PrinterOutlined />{{ $t("Print") }}</a-button
      > -->
      <!-- <a-modal
        v-model:visible="IsvisibleChangePrint"
        @cancel="handleCancelChangePrinter"
        :maskClosable="false"
        :title="$t('Change Printer')"
      >
        <a-form layout="vertical">
          <a-form-item :label="$t('Select Printer')">
            <a-select v-model:value="selectedPrinter" :placeholder="$t('Select Printer')">
              <a-select-option
                v-for="(item, index) in printers"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancelChangePrinter">{{
            $t("Cancel")
          }}</a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="selectedPrinter == ''"
            :loading="printLoad"
            @click="changePrinter()"
            >{{ $t("Change") }}</a-button
          >
        </template>
      </a-modal>
      <a-modal v-model:visible="visible" :title="$t('Print')">
        <a-form layout="vertical">
          <a-form-item :label="$t('Select Printer')">
            <a-select v-model:value="selectedPrinter" :placeholder="$t('Select Printer')">
              <a-select-option
                v-for="(item, index) in printers"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="visible = false">{{ $t("Cancel") }}</a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="selectedPrinter == ''"
            :loading="printLoad"
            @click="print(printedData)"
            >{{ $t("Print") }}</a-button
          >
        </template>
      </a-modal> -->
    </div>
  </div>
</template>

<script>
import { PrinterOutlined, QuestionOutlined, SwapOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import qz from "qz-tray";

export default {
  props: ["data"],
  data() {
    return {
      columns: [
        {
          title: this.$t("SKU"),
          dataIndex: "products",
          key: "sku",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "quantity",
        },
      ],
      columnsData: this.data.products,
      printLoad: false,
      printers: [],
      visible: false,
      selectedPrinter: "",
      printedData: "",
      IsvisibleChangePrint: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    pdf(id) {
      this.printLoad = true
      axios.get(`/api/transfer/generate-pdf/${id}`).then((result) => {
        const pdfUrl = result.data.path; // PDF URL'sini buraya yerleştirin

        // PDF'yi indirmek için bir a etiketi oluşturun
        var link = document.createElement('a')
        link.href = pdfUrl
        link.target = '_blank'
        link.click()
        this.printLoad = false
      }).catch((err) => {
        this.printLoad = false
        console.log(err);
      });
    },
    async print(data) {
      this.printLoad = true;
      this.selectedPrinter = this.selectedPrinter
        ? this.selectedPrinter
        : localStorage.getItem("printer");
      var config = qz.configs.create(this.selectedPrinter);
      var invoice =
        '<div style="padding:10px;"><div><span style="font-size:24px;font-weight:600">' +
        this.user.firm.name +
        '</span></br><p style="font-size:18px;font-weight:600">' +
        `1 - ${this.data.from_branch_id.name}` +
        '</p><p style="font-size:18px;font-weight:600">' +
        `2 - ${this.data.to_branch_id.name}` +
        '</p></br><span>No: <span style="font-size:18px;font-weight:600">' +
        this.data.transfer_id +
        '</span></span></br><span>İşlem: <span style="font-size:18px;font-weight:600">' +
        "Transfer" +
        '</span></span></br><span>Tarih: <span style="font-size:18px;font-weight:600">' +
        this.data.date.substr(0, 10) +
        '</span></span><div style="margin-top:15px"><span>-----------------------------</span>';

      invoice +=
        '</div></div><div style="text-align:center;margin-top:15px;display:flex;justify-content:center;"><table style="width:100%;padding:0 15x"><thead><tr><th>Ürün</th><th>Adet</th></tr></thead><tbody>' +
        this.data.products.map(
          (e) => `<tr><td>${e.sku}</td><td>${e.quantity} adet</td></tr>`
        ) +
        '</tbody></table></div><div style="margin-top:15px">';

      invoice +=
        '<span>-----------------------------</span></div><div style="margin-top:15px"><span> Toplam: <span style="font-size:18px;font-weight:600">' +
        `${this.data.products.reduce((accumulator, object) => {
          return accumulator + object.quantity;
        }, 0)}` +
        " adet" +
        "</span></span>";

      qz.print(config, [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data: invoice,
        },
      ])
        .then((result) => {
          this.printLoad = false;
          this.visible = false;
          qz.websocket.disconnect();
        })
        .catch((err) => {
          this.visible = false;
          this.printLoad = false;
          qz.websocket.disconnect();
        });
    },
    selectPrinter(id) {
      this.printers = [];
      this.printedData = "";
      qz.websocket
        .connect()
        .then(() => {
          return qz.printers.find();
        })
        .then(async (printers) => {
          var localPrinter = localStorage.getItem("printer");
          if (!localPrinter) {
            this.printers = printers;
            this.printedData = id;
            this.visible = true;
          } else {
            await this.print(id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    visibleChangePrint() {
      this.printers = [];
      this.printedData = "";
      qz.websocket
        .connect()
        .then(() => {
          return qz.printers.find();
        })
        .then(async (printers) => {
          this.printers = printers;
          this.IsvisibleChangePrint = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async changePrinter() {
      await localStorage.setItem("printer", this.selectedPrinter);
      await qz.websocket.disconnect();
      this.IsvisibleChangePrint = await false;
    },
    async handleCancelChangePrinter() {
      (this.IsvisibleChangePrint = await false), await qz.websocket.disconnect();
    },
  },
  components: { PrinterOutlined, SwapOutlined, QuestionOutlined },
};
</script>

<style lang="scss" scoped></style>
