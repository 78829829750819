<template>
  <div>
    <div class="">
      <div
        class=""
        style="display: flex; justify-content: end; align-items: center; gap: 15px"
      >
        <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
        <a-select
          v-model:value="user.firm.currency"
          @change="getData()"
          placeholder="Currency"
        >
          <a-select-option :value="'$'">$ Dollar</a-select-option>
          <a-select-option :value="'€'">€ Euro</a-select-option>
          <a-select-option :value="'₺'">₺ Lira</a-select-option>
          <a-select-option :value="'£'">£ Pound</a-select-option>
          <a-select-option :value="'₼'">₼ Manat</a-select-option>
          <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
          <a-select-option :value="'₽'">₽ Ruble</a-select-option>
          <a-select-option :value="'₹'">₹ Rupi</a-select-option>
          <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
          <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
          <a-select-option :value="'SR'">SR Riyal</a-select-option>
        </a-select>
      </div>
      <LinePlot v-if="loading" :config="config" :chartRef="(ref) => (LineRef = ref)" />
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LinePlot } from "ant-design-charts-vue";
import Loader from "@/components/shared/Loader.vue";
export default {
  components: { LinePlot, Loader },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      form: {},
      filterText: "",
      loading: false,
      config: {
        data: [],
        color: ["#5D90F7", "#cf1322"],
        height: 350,
        xField: "date",
        yField: "count",
        seriesField: "type",
        smooth: true,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      if (this.user.firm.currency) {
        this.filterText = this.filterText + `currency=${this.user.firm.currency}&`;
      }
      axios
        .get(`/api/sale-report/daily-sales-quantity?${this.filterText}`)
        .then((result) => {
          console.log(result.data);
          this.config.data = result.data;
          this.loading = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
