<template>
  <div>
    <div
      class=""
      style="display: flex; justify-content: end; align-items: center; gap: 15px"
    >
      <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
    </div>
    <a-list
      v-if="loading"
      size="small"
      bordered
      :data-source="data"
      style="margin-top: 15px; width: 100%"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <template #actions>
            <span>{{ `${item.totalReturn}  ${$t('qty')}` }}</span>
          </template>
          <a-list-item-meta>
            <template #title>
              <h4>{{ item.sku }}</h4>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <Loader v-else />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  data() {
    return {
      data: [],
      filterText: "",
      form: {},
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      axios
        .get(`/api/product-report/worst-products?${this.filterText}`)
        .then((result) => {
          this.data = result.data;
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: { Loader },
};
</script>

<style lang="scss" scoped></style>
