<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Patterns')" />
      <router-link to="/patterns"><a-button>{{$t('Go to Back')}}</a-button></router-link>
    </div>
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-form v-if="load" style="width: 50%" layout="vertical">
        <a-form-item :label="$t('Pattern Name')">
          <a-input v-model:value="form.name" :placeholder="$t('Pattern Name')" />
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end">
            <a-button type="primary" :loading="loadBtn" @click="update()"
              >{{$t('Update')}}</a-button
            >
          </div>
        </a-form-item>
      </a-form>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  components: { PageTitle, PageTitle, Loader },
  data() {
    return {
      form: {},
      loadBtn: false,
      load: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async update() {
      this.loadBtn = true;
      await axios
        .patch(`/api/pattern/${this.$route.params.id}`, this.form)
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    getData() {
      axios
        .get(`/api/pattern/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data;
          this.load = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
