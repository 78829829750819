<template>
  <div>
    <div class="" style="
                                                      display: flex;
                                                      justify-content: space-between;
                                                      align-items: center;
                                                      padding: 0px 15px;
                                                    ">
      <PageTitle :title="$t('Fast Transfer')" />
    </div>
    <div class="">
      <a-row style="width: 100%">
        <a-col :xl="16" style="margin-top: 15px;">
          <a-layout-content :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
          }">
            <a-row style="gap: 15px">
              <a-col>
                <a-select v-model:value="form.from_branch_id" @change="from_branch_id = form.from_branch_id"
                  :placeholder="$t('Select From Branch')">
                  <a-select-option v-for="(item, index) in branches" :key="index" :value="item._id">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select v-model:value="form.to_branch_id" @change="to_branch_id = form.to_branch_id"
                  :placeholder="$t('Select To Branch')">
                  <a-select-option v-for="(item, index) in branches" :key="index" :value="item._id">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row style="margin-top: 15px" v-if="form.from_branch_id && form.to_branch_id">
              <a-col>
                <div class="" style="display: flex;justify-content: start;align-items: center;gap: 15px;">
                  <a-input @input="getPosProduct()" v-model:value="filterSku" :placeholder="$t('Product Sku')" />
                  <a-button v-if="!isScaningBarcode" @click="scan">{{ $t('Scan Barcode') }}</a-button>
                  <a-button v-else type="danger" @click="() => {
                    isScaningBarcode = false
                  }">{{ $t('Stop Scanning Barcode') }}</a-button>
                  <a-input style="opacity: 0;" id="barcode-scanner" v-model:value="scannedBarcode"
                    :placeholder="$t('SKU')" @keyup.lazy.enter="ScannerRead()" />
                </div>
              </a-col>
            </a-row>
            <a-divider />
            <a-row v-if="form.from_branch_id && form.to_branch_id" style="
                                                              display: flex;
                                                              gap: 25px;
                                                              max-height: 375px;
                                                              overflow-y: scroll;
                                                              padding: 10px;
                                                            ">
              <a-col v-for="(item, index) in defaultProducts" :key="index" :lg="5">
                <div class="product">
                  <a-card @click="addCart(item)" style="
                                                                    cursor: pointer;
                                                                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                                                                  ">
                    <div class="sku" style="display: flex; justify-content: start">
                      <b>{{ item.sku }}</b>
                    </div>
                    <div class="price" style="display: flex; justify-content: start">
                      <b style="color: rgba(0, 0, 0, 0.5)">{{
                        item.name.length > 20 ? `${item.name.substr(0, 20)}...` : item.name
                      }}</b>
                    </div>
                  </a-card>
                </div>
              </a-col>
            </a-row>
            <a-row v-else>
              <h3>
                <b>{{ $t("Please Select Branch") }}</b>
              </h3>
            </a-row>
          </a-layout-content>
        </a-col>
        <a-col :xl="8" style="margin-top: 15px;">
          <a-layout-content v-if="cartProducts.length > 0" :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
            maxHeight: '400px',
            overflowY: 'scroll',
          }">
            <a-row v-for="(item, index) in cartProducts" :key="index" style="
                                                              display: flex;
                                                              justify-content: space-between;
                                                              align-items: end;

                                                            ">
              <a-col :xs="24" :md="8" style="padding: 0 10px;">
                <div class="sku" style="width: 100%;overflow-x: scroll;">
                  <div class="">
                    <span style="width: 50%"> {{ item.sku }}</span>
                  </div>
                </div>
              </a-col>
              <a-col :xs="24" :md="8">
                <div class="quantity" style="width: 70%">
                  <label for="" style="font-size: 12px; color: #959595">{{
                    $t("Quantity")
                  }}</label>
                  <a-input v-model:value="item.quantity" type="number" :placeholer="$t('Quantity')" style="width: 100%" />
                </div>
              </a-col>
              <a-col :xs="24" :md="8">
                <div class="btn" style="width: 50%">
                  <a-button @click="remove(item.id)">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
            </a-row>
          </a-layout-content>
          <a-layout-content v-else :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
          }">
            <h3>
              <b>{{ $t("There are no items in the cart") }}</b>
            </h3>
          </a-layout-content>

          <a-layout-content v-if="cartProducts.length > 0" :style="{
            margin: '10px 16px 0 16px',
            padding: '24px',
            background: '#fff',
          }">
            <a-row>
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("Total") }}:
                  <span>
                    <b style="margin-right: 5px;">{{
                      cartProducts.reduce((accumulator, object) => {
                        return Number(accumulator) + Number(object.quantity);
                      }, 0)
                    }}</b>{{ $t('qty') }}
                  </span>
                </h3>
              </a-col>
            </a-row>
            <a-row>
              <div class="" style="display: flex; justify-content: space-between; width: 100%">
                <a-button type="primary" @click="payNowModal()">{{
                  $t("Create")
                }}</a-button>
                <a-button type="danger" @click="resetModal()">{{ $t("Reset") }}</a-button>
              </div>
            </a-row>
          </a-layout-content>
        </a-col>
      </a-row>
    </div>
    <a-modal v-model:visible="payNowVisible" :title="$t('Are you sure you want to make the tranfer?')" :footer="null">
      <p>
        {{
          $t("If you encounter any problems, you can edit them in the Tranfers section")
        }}
      </p>
      <div class="" style="display: flex; justify-content: end; align-items: center">
        <a-button @click="() => (payNowVisible = false)">{{ $t("Cancel") }}</a-button>
        <a-button :loading="loading" style="margin-left: 5px" type="primary" @click="pay()">{{ $t("Create") }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model:visible="resetVisible" :title="$t('Are you sure?')" :footer="null">
      <p>{{ $t("The products you add to the cart will be deleted") }}</p>
      <div class="" style="display: flex; justify-content: end; align-items: center">
        <a-button @click="() => (resetVisible = false)">{{ $t("Cancel") }}</a-button>
        <a-button :loading="loading" style="margin-left: 5px" type="danger" @click="resetCartProduct()">{{ $t("Reset")
        }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Alert from "@/assets/voices/beep.mp3";
import Wrong from "@/assets/voices/wrong.mp3";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: { PageTitle, DeleteOutlined, StreamBarcodeReader },
  data() {
    return {
      activeKey: "1",
      payNowVisible: false,
      resetVisible: false,
      form: {},
      to_branch_id: '',
      from_branch_id: '',
      user: JSON.parse(localStorage.getItem("user")),
      data: [],
      defaultProducts: [],
      cartProducts: [],
      customers: [],
      branches: [],
      loading: false,
      filterSku: "",
      scanningSku: '',
      scanBarcodeVisible: false,
      scannedBarcode: '',
      isScaningBarcode: false
    };

  },
  mounted() {
    this.getPosProduct();
    this.getBranch();
  },
  methods: {
    ScannerRead() {
      this.onDecode(this.scannedBarcode)
      this.scannedBarcode = ''
    },
    onDecode(sku) {

      if (sku == null) {
        console.log("okutulmuyor");
      } else {
        this.scanningSku = sku
        axios
          .get(`/api/product/sku/${this.scanningSku}`)
          .then((result) => {
            var data = result.data
            data.price = result.data.sale_price
            data.purchase = Number(result.data.purchase_price)
            this.addCart(data)
            this.scanningSku = ''
          }).catch(async () => {
            await notification.error({
              message: this.$t("Error"),
              description: this.$t("Barcode not found"),
              duration: 1.5,
            });
            this.playSound(Wrong);
          })
      }
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`)
    },
    playSound(url) {
      const audio = new Audio(url);
      audio.play();
    },
    resetCartProduct() {
      this.cartProducts = [];
      this.resetVisible = false;
    },
    getBranch() {
      axios
        .get("/api/branch/all")
        .then((result) => {
          this.branches = result.data;
        })
        .catch((err) => { });
    },
    async pay() {
      this.loading = true;
      var data = {
        from_branch_id: this.form.from_branch_id,
        to_branch_id: this.form.to_branch_id,
        products: this.cartProducts,
        type: this.form.type,
        date: new Date(),
      };
      await axios
        .post("/api/transfer/add", data)
        .then(async (result) => {
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.payNowVisible = false;
          this.form = {

          };
          this.getPosProduct();
          this.cartProducts = [];
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    payNowModal() {
      this.payNowVisible = true;
    },
    resetModal() {
      this.resetVisible = true;
    },
    getPosProduct() {
      if (this.filterSku.length > 0) {
        axios
          .get(`/api/product/pos-sell?sku=${this.filterSku}`)
          .then((result) => {
            this.defaultProducts = result.data;
          })
          .catch((err) => { });
      } else {
        axios
          .get("/api/product/pos-sell")
          .then((result) => {
            this.defaultProducts = result.data;
          })
          .catch((err) => { });
      }
    },
    addCart(data) {
      const { sku,purchase } = data;
      const check = this.cartProducts.find((e) => e.sku == sku);
      if (check) {
        check.quantity = check.quantity + 1;
      } else {
        this.cartProducts.push({
          id: Math.ceil(Math.random() * 874589),
          sku: sku,
          quantity: Number(1),
          purchase: Number(purchase),
        });
      }
      this.playSound(Alert);
      console.log(this.cartProducts);
    },
    remove(id) {
      const check = this.cartProducts.find((e) => e.id == id);
      const index = this.cartProducts.indexOf(check);
      this.cartProducts.splice(index, 1);
      this.playSound(Alert);
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    async showScanBarcodeModal() {
      this.scanBarcodeVisible = await true
    },
    scan() {
      this.isScaningBarcode = true
      const inp = document.getElementById('barcode-scanner')
      inp.focus()
    }
  },
};
</script>

<style lang="scss" scoped></style>

