<template>
  <div class="">
    <a-card>
      <div
        class=""
        style="
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 25px;
        "
      >
        <div class="img" style="width: 80px; height: 80px">
          <img
            :src="require(`@/assets/images/logo/gib.png`)"
            style="width: 100%; height: 100%"
            alt=""
            srcset=""
          />
        </div>
        <div class="title">
          <h3>{{ $t("E-Arşiv") }} (İnteraktif)</h3>
        </div>
      </div>
      <a-divider />
      <div class="subtitle" style="min-height: 50px; max-height: 55px">
        <span>{{
          $t(
            "Converting the invoices you created through the panel into e-archive with one click"
          )
        }}</span>
      </div>
      <div
        class="btn"
        style="
          margin-top: 20px;
          display: flex;
          justify-content: end;
          align-items: center;
        "
      >
        <a-button type="primary" disabled>{{ $t("Soon") }}</a-button>
      </div>
      <!-- <div
        style="
          margin-top: 20px;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 10px;
        "
      >
        <div class="btn">
          <a-button v-if="!user.earsiv" @click="showModal" type="primary">{{
            $t("Integrate")
          }}</a-button>
          <a-modal
            :mask-closable="false"
            v-model:visible="visibleCreate"
            title="E-Arşiv"
            :footer="null"
          >
            <a-form layout="vertical">
              <a-form-item :label="'GİB ' + $t('Username')">
                <a-input
                  v-model:value="createForm.username"
                  :placeholder="'GİB ' + $t('Username')"
                />
              </a-form-item>
              <a-form-item :label="'GİB ' + $t('Password')">
                <a-input
                  type="password"
                  v-model:value="createForm.password"
                  :placeholder="'GİB ' + $t('Password')"
                />
              </a-form-item>
            </a-form>
            <a-form-item>
              <div
                class=""
                style="display: flex; justify-content: end; align-items: center"
              >
                <a-button @click="() => (visibleCreate = false)">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  :loading="loading"
                  style="margin-left: 5px"
                  type="primary"
                  @click="handleOk()"
                  >{{ $t("Create") }}</a-button
                >
              </div>
            </a-form-item>
          </a-modal>
        </div>
        <div class="btn">
          <a-button v-if="user.earsiv" @click="showModalUpdate">{{
            $t("Update")
          }}</a-button>
          <a-modal
            :mask-closable="false"
            v-model:visible="visibleUpdate"
            title="E-Arşiv"
            :footer="null"
          >
            <a-form layout="vertical">
              <a-form-item :label="'GİB ' + $t('Username')">
                <a-input
                  v-model:value="updateForm.username"
                  :placeholder="'GİB ' + $t('Username')"
                />
              </a-form-item>
              <a-form-item :label="'GİB ' + $t('Password')">
                <a-input
                  type="password"
                  v-model:value="updateForm.password"
                  :placeholder="'GİB ' + $t('Password')"
                />
              </a-form-item>
            </a-form>
            <a-form-item>
              <div
                class=""
                style="display: flex; justify-content: end; align-items: center"
              >
                <a-button @click="() => (visibleUpdate = false)">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  :loading="loading"
                  style="margin-left: 5px"
                  type="primary"
                  @click="handleUpdate()"
                  >{{ $t("Update") }}</a-button
                >
              </div>
            </a-form-item>
          </a-modal>
        </div>
        <div class="btn">
          <a-button
            v-if="user.earsiv"
            @click="showModalCredits"
            type="primary"
            >{{ $t("Buy Credits") }}</a-button
          >
          <a-modal
            :mask-closable="false"
            v-model:visible="visibleCredits"
            title="E-Arşiv"
            :footer="null"
          >
            <h3>
              {{ $t("Available Credits") }}: {{ user.firm.credit }}
              {{ $t("qty") }}
            </h3>
            <a-divider />
            <a-card style="">
              <span style="text-align: center">
                <h3>500 {{ $t("Credits") }}</h3>
              </span>
              <a-divider />
              <span style="text-align: center">
                <p>{{ $t("Price") }}: <b>250₺</b></p>
              </span>
              <span style="text-align: center">
                <p>(0.50₺ Adet)</p>
              </span>
            </a-card>
            <a-divider />
            <p style="text-align: center">
              0546 571 1445 Bu numarayı arayarak bilgi alabilirsiniz.
            </p>
            <a-form layout="horizontal">
              <a-form-item>
                <a-input
                  v-model:value="form.cardName"
                  type="text"
                  :placeholder="$t('Name')"
                />
              </a-form-item>
              <a-form-item>
                <a-input
                  v-cardformat:formatCardNumber
                  v-model:value="form.number"
                  type="text"
                  :placeholder="$t('Card Number')"
                />
              </a-form-item>
              <div class="" style="display: flex; gap: 15px">
                <a-form-item style="width: 100%">
                  <a-select
                    v-model:value="form.month"
                    :placeholder="$t('Month')"
                  >
                    <a-select-option value="1">1</a-select-option>
                    <a-select-option value="2">2</a-select-option>
                    <a-select-option value="3">3</a-select-option>
                    <a-select-option value="4">4</a-select-option>
                    <a-select-option value="5">5</a-select-option>
                    <a-select-option value="6">6</a-select-option>
                    <a-select-option value="7">7</a-select-option>
                    <a-select-option value="8">8</a-select-option>
                    <a-select-option value="9">9</a-select-option>
                    <a-select-option value="10">10</a-select-option>
                    <a-select-option value="11">11</a-select-option>
                    <a-select-option value="12">12</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item style="width: 100%">
                  <a-select v-model:value="form.year" :placeholder="$t('Year')">
                    <a-select-option value="2023">2023</a-select-option>
                    <a-select-option value="2024">2024</a-select-option>
                    <a-select-option value="2025">2025</a-select-option>
                    <a-select-option value="2026">2026</a-select-option>
                    <a-select-option value="2027">2027</a-select-option>
                    <a-select-option value="2028">2028</a-select-option>
                    <a-select-option value="2029">2029</a-select-option>
                    <a-select-option value="2030">2030</a-select-option>
                    <a-select-option value="2031">2031</a-select-option>
                    <a-select-option value="2032">2032</a-select-option>
                    <a-select-option value="2033">2033</a-select-option>
                    <a-select-option value="2034">2034</a-select-option>
                    <a-select-option value="2035">2035</a-select-option>
                    <a-select-option value="2036">2036</a-select-option>
                    <a-select-option value="2037">2037</a-select-option>
                    <a-select-option value="2038">2038</a-select-option>
                    <a-select-option value="2039">2039</a-select-option>
                    <a-select-option value="2040">2040</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-input
                    v-model:value="form.cvc"
                    v-cardformat:formatCardCVC
                    type="text"
                    maxlength="3"
                    :placeholder="$t('CVC')"
                  />
                </a-form-item>
              </div>
            </a-form>
            <a-form-item>
              <div
                class=""
                style="display: flex; justify-content: end; align-items: center"
              >
                <a-button @click="() => (visibleCredits = false)">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  :loading="loading"
                  style="margin-left: 5px"
                  type="primary"
                  @click="pay()"
                  ><b>{{ $t("Pay") }}</b
                  ><span style="font-size: 12px"> /(250₺)</span></a-button
                >
              </div>
            </a-form-item>
            <div
              class=""
              style="
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: center;
                margin: auto;
              "
            >
              <img
                style="width: 100%; height: 100%; text-align: center"
                src="@/assets/images/iyzico/iyzico.png"
                alt=""
              />
            </div>
          </a-modal>
        </div>
        <div class="btn">
          <a-popconfirm
            v-if="user.earsiv"
            :title="$t('Do you want logout?')"
            :ok-text="$t('Yes')"
            :cancel-text="$t('No')"
            @confirm="logout()"
            @cancel="cancel"
          >
            <a-button type="danger">{{ $t("Secure Logout") }}</a-button>
          </a-popconfirm>
        </div>
        <div class="btn">
          <a-button v-if="user.earsiv" @click="remove()" type="danger">{{
            $t("Delete")
          }}</a-button>
        </div>
      </div> -->
    </a-card>
  </div>
</template>

<script>
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      visibleCreate: false,
      visibleUpdate: false,
      visibleCredits: false,
      loading: false,
      createForm: {
        username: "",
        password: "",
      },
      updateForm: {
        username: "",
        password: "",
      },
      form: {},
    };
  },
  methods: {
    logout() {
      this.loading = true;
      axios
        .post("/api/earsiv/secure-logout")
        .then(async (result) => {
          await axios
            .get("/api/auth/user")
            .then(async (user) => {
              await localStorage.setItem("user", JSON.stringify(user.data));
              this.user = user.data;
              this.visibleCreate = false;
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.form = {};
              this.visibleCredits = false;
              this.loading = false;
            })
            .catch(async (err) => {
              this.loading = false;
              await notification.error({
                message: this.$t("Error"),
                description: this.$t("Error Occurred During Transaction"),
                duration: 1.5,
              });
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t(err.response.data.data),
            duration: 1.5,
          });
        });
    },
    showModal() {
      this.visibleCreate = true;
    },
    showModalUpdate() {
      this.visibleUpdate = true;
    },
    showModalCredits() {
      this.visibleCredits = true;
    },
    pay() {
      this.loading = true;
      var data = {
        plan_id: this.$route.params.id,
        cardName: this.form.cardName,
        number: this.form.number.replaceAll(/\s/g, ""),
        exp_month: this.form.month,
        exp_year: this.form.year,
        cvc: this.form.cvc,
      };
      axios
        .post("/api/earsiv/buy-credit", data)
        .then(async (result) => {
          await axios
            .get("/api/auth/user")
            .then(async (user) => {
              await localStorage.setItem("user", JSON.stringify(user.data));
              this.user = user.data;
              this.visibleCreate = false;
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.form = {};
              this.visibleCredits = false;
              this.loading = false;
            })
            .catch(async (err) => {
              this.loading = false;
              await notification.error({
                message: this.$t("Error"),
                description: this.$t("Error Occurred During Transaction"),
                duration: 1.5,
              });
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t(err.response.data.data),
            duration: 1.5,
          });
        });
    },
    handleOk() {
      this.loading = true;
      axios
        .post("/api/earsiv/signin", this.createForm)
        .then(async (result) => {
          await axios
            .get("/api/auth/user")
            .then(async (user) => {
              await localStorage.setItem("user", JSON.stringify(user.data));
              this.user.earsiv = result.data;
              this.visibleCreate = false;
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.loading = false;
            })
            .catch(async (err) => {
              this.loading = false;
              await notification.error({
                message: this.$t("Error"),
                description: this.$t("Error Occurred During Transaction"),
                duration: 1.5,
              });
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t(err.response.data.data),
            duration: 1.5,
          });
        });
    },
    handleUpdate() {
      this.loading = true;
      axios
        .patch("/api/earsiv/update", this.updateForm)
        .then(async (result) => {
          await axios
            .get("/api/auth/user")
            .then(async (user) => {
              await localStorage.setItem("user", JSON.stringify(user.data));
              this.user.earsiv = result.data;
              this.visibleUpdate = false;
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.loading = false;
            })
            .catch(async (err) => {
              this.loading = false;
              await notification.error({
                message: this.$t("Error"),
                description: this.$t("Error Occurred During Transaction"),
                duration: 1.5,
              });
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t(err.response.data.data),
            duration: 1.5,
          });
        });
    },
    remove() {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content: this.$t(
          "If you delete this data, all relevant data will be deleted and your credits will be reset. Are you sure?"
        ),
        onOk: async () => {
          await axios
            .delete(`/api/earsiv/delete`)
            .then(async (result) => {
              await axios
                .get("/api/auth/user")
                .then(async (user) => {
                  await localStorage.setItem("user", JSON.stringify(user.data));
                  this.user.earsiv = null;
                  await notification.success({
                    message: this.$t("Success"),
                    description: this.$t("Transaction Successfully Performed"),
                    duration: 1.5,
                  });
                })
                .catch(async (err) => {
                  await notification.error({
                    message: this.$t("Error"),
                    description: this.$t("Error Occurred During Transaction"),
                    duration: 1.5,
                  });
                });
            })
            .catch(async (err) => {
              await notification.error({
                message: this.$t("Error"),
                description: this.$t("Error Occurred During Transaction"),
                duration: 1.5,
              });
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
