<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Roles')" />
      <Button :getData="getData" />
    </div>
    <RoleFilter @itemFilter="data = $event" :loader="loader" @newLoad="loader = $event" />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-table
        :scroll="{ x: 1000 }"
        v-if="loader"
        :columns="columns"
        :data-source="data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
              "
            >
              <a-button v-if="record.type != 'system'" @click="showDrawer(text)"
                ><EditOutlined
              /></a-button>
              <a-button v-if="record.type != 'system'" @click="remove(text)"
                ><DeleteOutlined
              /></a-button>
            </div>
          </template>
        </template>
      </a-table>
      <Loader v-else />
      <a-drawer
        v-if="visibleDetailLoad"
        :title="`${roleDetail.name}`"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <RoleUpdate :data="roleDetail" />
      </a-drawer>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/roles/Button.vue";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import RoleFilter from "@/components/roles/RoleFilter.vue";
import RoleUpdate from "@/components/roles/RoleUpdate.vue";

export default {
  data() {
    return {
      loader: false,
      visibleDetail: true,
      visibleDetailLoad: false,
      roleDetail: {},
      columns: [
        {
          title: this.$t("Role Name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showDrawer(id) {
      this.roleDetail = {};
      this.visibleDetail = true;
      axios
        .get(`/api/role/${id}`)
        .then((result) => {
          this.roleDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.roleDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    async getData() {
      await axios
        .get("/api/role/all")
        .then((result) => {
          this.data = result.data;
          this.loader = true;
        })
        .catch((err) => {});
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content:
          this.$t("If you delete this data, all related data will be deleted. Are you sure?"),
        onOk: async () => {
          await axios
            .delete(`/api/role/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
  },
  components: {
    Loader,
    PageTitle,
    Button,
    EditOutlined,
    DeleteOutlined,
    RoleFilter,
    RoleUpdate,
  },
};
</script>

<style lang="scss" scoped></style>
