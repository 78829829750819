<template>
  <div>
    <div class="lang">
      <a-dropdown :trigger="['click']">
        <global-outlined style="cursor: pointer" />
        <template #overlay>
          <a-menu>
            <a-menu-item key="0" id="ingilizce" @click="switchLang('en')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/en.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">English</span>
              </div>
            </a-menu-item>
            <a-menu-item key="1" id="ingilizce" @click="switchLang('tr')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/tr.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Türkçe</span>
              </div>
            </a-menu-item>
            <a-menu-item key="2" id="ingilizce" @click="switchLang('de')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/de.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Deutsch</span>
              </div>
            </a-menu-item>
            <a-menu-item key="3" id="ingilizce" @click="switchLang('az')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/az.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Azərbaycan</span>
              </div>
            </a-menu-item>
            <a-menu-item key="4" id="ingilizce" @click="switchLang('ar')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/ar.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">عربي</span>
              </div>
            </a-menu-item>
            <!-- <a-menu-item key="5" id="ingilizce" @click="switchLang('zh')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/zh.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">中国人</span>
              </div>
            </a-menu-item>-->
            <a-menu-item key="6" id="ingilizce" @click="switchLang('fr')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/fr.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Français</span>
              </div>
            </a-menu-item>
            <a-menu-item key="7" id="ingilizce" @click="switchLang('hi')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/hi.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">हिन्दी</span>
              </div>
            </a-menu-item>
            <a-menu-item key="8" id="ingilizce" @click="switchLang('es')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/es.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Español</span>
              </div>
            </a-menu-item>
            <a-menu-item key="0" id="ingilizce" @click="switchLang('ja')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/jap.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">日本</span>
              </div>
            </a-menu-item>
            <!--<a-menu-item key="9" id="ingilizce" @click="switchLang('it')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/it.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Italiano</span>
              </div>
            </a-menu-item>
            <a-menu-item key="0" id="ingilizce" @click="switchLang('pl')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/le.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Polskie</span>
              </div>
            </a-menu-item>
            <a-menu-item key="0" id="ingilizce" @click="switchLang('pt')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/pt.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Português</span>
              </div>
            </a-menu-item> -->
            <a-menu-item key="10" id="ingilizce" @click="switchLang('ru')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/ru.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Русский</span>
              </div>
            </a-menu-item>
            <!-- <a-menu-item key="11" id="ingilizce" @click="switchLang('ua')">
              <div
                class=""
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div style="width: 40px; height: 25px" class="img">
                  <img
                    style="width: 100%; height: 100%"
                    src="@/assets/images/langs/ua.png"
                    alt=""
                  />
                </div>
                <span style="margin-left: 10px">Українська</span>
              </div>
            </a-menu-item> -->
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { GlobalOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    GlobalOutlined,
  },
  methods: {
    switchLang(param) {
      localStorage.setItem("lang", param);
      this.$i18n.locale = param;
    },
  },
};
</script>

<style lang="scss" scoped></style>
