<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row>
          <a-col :xs="24" :xl="12" :md="12">
            <a-form-item :label="$t('Name')" style="margin: 10px">
              <a-input v-model:value="filter.name" :placeholder="$t('Name')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="12" :md="12">
            <a-form-item :label="$t('Role')" style="margin: 10px">
              <a-select v-model:value="filter.role_id" :placeholder="$t('Role')">
                <a-select-option
                  v-for="(item, index) in roles"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-form-item v-if="filter.name || filter.role_id" style="margin: 10px">
            <a-button type="primary" @click="setFilter()">{{$t('Filter')}}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{$t('Clear')}}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filter: {},
      roles: [],
      filterText: "",
      loader: false,
    };
  },
  created() {
    this.getRoles();
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);
      if (this.filter.name) {
        this.filterText = this.filterText + `name=${this.filter.name}&`;
      }
      if (this.filter.role_id) {
        this.filterText = this.filterText + `role_id=${this.filter.role_id}&`;
      }

      await axios
        .get(`/api/worker/all?${this.filterText}`)
        .then((result) => {
          this.loader = true;
          this.$emit("itemFilter", result.data);
          this.$emit("newLoad", this.loader);
        })
        .catch((err) => {});
    },
    getRoles() {
      axios
        .get("/api/role/all")
        .then((result) => {
          result.data.forEach((element) => {
            if (element.type != "system") {
              this.roles.push(element);
            }
          });
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
