<template>
  <div>
    <a-button type="primary" @click="showModal">
      <PlusOutlined /><span>{{ $t("Create") }}</span>
    </a-button>
    <a-modal :mask-closable="false" v-model:visible="visible" :title="$t('New Income & Exprenses Create')" :footer="null">
      <a-form layout="vertical">
        <a-form-item label="Type">
          <a-radio-group v-model:value="form.type">
            <a-radio value="income">{{ $t("Income") }}</a-radio>
            <a-radio value="exprens">{{ $t("Exprens") }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('Title')">
          <a-input v-model:value="form.title" :placeholder="$t('Title')" />
        </a-form-item>
        <a-form-item :label="$t('Description')">
          <a-textarea v-model:value="form.desc" :placeholder="$t('Description')" />
        </a-form-item>
        <a-form-item :label="$t('Price')">
          <div class="" style="display: flex; justify-content: start">
            <a-input type="number" v-model:value="form.price" :placeholder="$t('Price')" />
            <a-select style="width: 20%" v-model:value="user.firm.currency" :placeholder="$t('Currency')">
              <a-select-option :value="'$'">$ Dollar</a-select-option>
              <a-select-option :value="'€'">€ Euro</a-select-option>
              <a-select-option :value="'₺'">₺ Lira</a-select-option>
              <a-select-option :value="'£'">£ Pound</a-select-option>
              <a-select-option :value="'₼'">₼ Manat</a-select-option>
              <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
              <a-select-option :value="'₽'">₽ Ruble</a-select-option>
              <a-select-option :value="'₹'">₹ Rupi</a-select-option>
              <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
              <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
              <a-select-option :value="'SR'">SR Riyal</a-select-option>
            </a-select>
          </div>
        </a-form-item>
        <a-form-item :label="$t('Date')">
          <a-date-picker v-model:value="form.date" />
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end; align-items: center">
            <a-button @click="() => (visible = false)">{{ $t("Cancel") }}</a-button>
            <a-popconfirm :title="$t('Are you sure?')" :ok-text="$t('Yes')"
                  :cancel-text="$t('No')" @confirm="handleOk()" @cancel="cancel">
            <a-button :loading="loading" style="margin-left: 5px" type="primary" >{{ $t("Create")
            }}</a-button>
                </a-popconfirm>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import dayjs from 'dayjs'

export default {
  props: ["getData"],
  components: { PlusOutlined },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      visible: false,
      form: {},
      loading: false,
    };
  },
  created() {
    this.form.date = dayjs()
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async handleOk() {
      this.loading = true;
      this.form.currency = this.user.firm.currency;
      await axios
        .post("/api/income/add", this.form)
        .then(async (result) => {
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.loading = false;
          this.visible = false;
          this.form = {
            date: dayjs()
          };
          this.getData();
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
