import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
// import './index.css'; // tailwind.css
// import i18n from './i18n'
import Antd from 'ant-design-vue';
import axios from './axios'

import de from './lang/de.json'
import en from './lang/en.json'
import es from './lang/es.json'
import fr from './lang/fr.json'
import ja from './lang/ja.json'
import ua from './lang/ua.json'
import ru from './lang/ru.json'
import tr from './lang/tr.json'
import ar from './lang/ar.json'
import zh from './lang/zh.json'
import hi from './lang/hi.json'
import it from './lang/it.json'
import pl from './lang/pl.json'
import pt from './lang/pt.json'
import az from './lang/az.json'
import { userI18n, createI18n } from 'vue-i18n'
import VueCardFormat from 'vue-credit-card-validation';

const i18n = createI18n({
    locale: localStorage.getItem("lang") || 'tr',
    fallbackLocale: 'tr',
    messages: {
        de,
        en,
        ar,
        hi,
        pl,
        pt,
        ja,
        it,
        zh,
        es,
        az,
        fr,
        ja,
        ua,
        ru,
        tr
    }
})

createApp(App, {
        setup() {
            const { t } = userI18n()
            return { t }
        }
    })
    .use(store)
    .use(i18n)
    .use(router)
    .use(axios)
    // .use(VueCookies)
    .use(VueCardFormat)
    .use(Antd)
    .mount('#app')