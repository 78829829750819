<template>
  <div class="">

    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle title="Orders" />
    </div>

    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-table :scroll="{ x: 1000 }" v-if="loader" :columns="columns" :data-source="data" @change="onChange">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <router-link :to="'/orders/' + text"
              ><a-button><i class="fa fa-eye" aria-hidden="true"></i></a-button
            ></router-link>
          </template>
          <template v-if="column.key === 'amount'">
            <a-span>{{ text ? `$${text}` : "-" }}</a-span>
          </template>
          <template v-if="column.key === 'status'">
            <a-tag v-if="text == 'pending'" color="green">{{ text }}</a-tag>
            <a-tag v-if="text == 'shipping'" color="blue">{{ text }}</a-tag>
            <a-tag v-if="text == 'return'" color="red">{{ text }}</a-tag>
            <a-tag v-if="text == 'delivered'" color="green">{{ text }}</a-tag>
            <a-tag v-else color="orange">{{ text }}</a-tag>
          </template>
          <template v-if="column.key === 'products'">
            <a-span>{{
              text.reduce((accumulator, object) => {
                return accumulator + object.quantity;
              }, 0)
            }}</a-span>
          </template>
          <template v-if="column.key === 'platform'">
            <a-span v-if="text == 'telegram'">
              <div class="" style="width: 30px; height: 30px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/images/platforms/telegram.png"
                  alt=""
                  srcset=""
                />
              </div>
            </a-span>
          </template>
        </template>
      </a-table>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/brand/Button.vue";
import Alert from "@/assets/voices/alert.mp3";
import { EditOutlined } from "@ant-design/icons-vue";

export default {
  data() {
    return {
      loader: false,
      columns: [
        {
          title: "Platform",
          dataIndex: "platform",
          key: "platform",
        },
        {
          title: "Order No",
          dataIndex: "order_id",
          key: "order_id",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Customer Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Quantity",
          dataIndex: "products",
          key: "products",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "Action",
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
    };
  },
  created() {
    this.getData();
    setInterval(() => {
      this.getData()
        .then((result) => {
          // this.playSound(Alert)
        })
        .catch((err) => {});
    }, 15000);
  },
  methods: {
    playSound(url) {
      const audio = new Audio(url);
      audio.play();
    },
    asd(data) {
      alert(data);
    },
    async getData() {
      await axios
        .get("/api/order/all")
        .then((result) => {
          this.data = result.data;
          this.loader = true;
        })
        .catch((err) => {});
    },
  },
  components: { Loader, PageTitle, Button, EditOutlined, alert },
};
</script>

<style lang="scss" scoped></style>
