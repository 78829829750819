<template>
  <div class="">
    <div class="" style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      ">
      <PageTitle :title="$t('Customers')" />
      <router-link to="/customers"><a-button>{{ $t('Go to Back') }}</a-button></router-link>
    </div>
    <a-layout-content :style="{
      margin: '0px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px',
    }">
      <a-form v-if="load" style="width: 50%" layout="vertical">
        <a-form-item :label="$t('TCKN')">
          <a-input v-model:value="form.tckn" :minlength="11" :maxlength="11" :placeholder="$t('TCKN')" />
        </a-form-item>
        <a-form-item :label="$t('Customer Name')">
          <a-input v-model:value="form.name" :placeholder="$t('Customer Name')" />
        </a-form-item>
        <a-form-item :label="$t('Phone')">
          <a-input v-model:value="form.phone" :placeholder="$t('Phone')" />
        </a-form-item>
        <a-form-item :label="$t('City')">
          <a-input v-model:value="form.city" :placeholder="$t('City')" />
        </a-form-item>
        <a-form-item :label="$t('Country')">
          <a-select v-model:value="form.country" :placeholder="$t('Country')">
            <a-select-option :value="'Türkiye'">Türkiye</a-select-option>
            <a-select-option :value="'Ukrayna'">Ukrayna</a-select-option>
            <a-select-option :value="'Rusya'">Rusya</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('Address')">
          <a-input v-model:value="form.address" :placeholder="$t('Address')" />
        </a-form-item>
        <a-form-item :label="$t('E-mail')">
          <a-input v-model:value="form.email" :placeholder="$t('E-mail')" />
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end">
            <a-button type="primary" :loading="loadBtn" @click="update()">{{ $t('Update') }}</a-button>
          </div>
        </a-form-item>
      </a-form>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  components: { PageTitle, PageTitle, Loader },
  data() {
    return {
      form: {},
      loadBtn: false,
      load: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async update() {
      if (this.form.tckn && this.form.tckn.length != 11) {
        this.loading = false;
        await notification.error({
          message: this.$t("Error"),
          description: this.$t("Error TCKN"),
          duration: 1.5,
        });
      } else {
        this.loadBtn = true;
        await axios
          .patch(`/api/customer/${this.$route.params.id}`, this.form)
          .then(async (result) => {
            this.loadBtn = false;
            await notification.success({
              message: this.$t("Success"),
              description: this.$t("Transaction Successfully Performed"),
              duration: 1.5,
            });
          })
          .catch(async (err) => {
            this.loadBtn = false;
            await notification.error({
              message: this.$t("Error"),
              description: this.$t("Error Occurred During Transaction"),
              duration: 1.5,
            });
          });
      }
    },
    getData() {
      axios
        .get(`/api/customer/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data;
          this.load = true;
        })
        .catch((err) => { });
    },
  },
};
</script>

<style lang="scss" scoped></style>
