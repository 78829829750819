<template>
  <div class="">
    <div class="" style="
        display: flex;

        justify-content: space-between;

        align-items: center;

        padding: 0px 15px;
      ">
      <PageTitle :title="$t('Products')" />

      <router-link to="/products">
        <a-button>{{ $t("Go to Back") }}</a-button>
      </router-link>
    </div>

    <a-layout-content :style="{
      margin: '0px 16px',

      padding: '24px',

      background: '#fff',

      minHeight: '280px',
    }">
      <a-tag color="yellow" style="width: 100%; padding: 10px; margin: 10px 0 20px 0">{{
        $t("SKU codes cannot be changed because the product was created")
      }}</a-tag>
      <div v-if="form.img" style="
          height: 200px;
          display: flex;
          justify-content: start;
          margin: 10px;
          width: 100%;
        " class="img">
        <a-image style="width: 100%; height: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"
          :src="'https://storage.googleapis.com/texco1/' + form.img" alt="" srcset="" />
      </div>
      <div class="" v-if="load">
        <a-row class="" style="display: flex; justify-content: space-between; gap: 15px">
          <a-col :lg="11">
            <a-form v-if="load" layout="vertical">
              <a-form-item :label="$t('Product Image')">
                <a-input type="file" @change="handleFileUpload($event)" />
              </a-form-item>

              <a-form-item :label="$t('Product Name')">
                <a-input v-model:value="form.name" :placeholder="$t('Product Name')" />
              </a-form-item>
              <a-form-item :label="$t('SKU')" v-if="form.variants && form.variants.length == 0">
                <a-input v-model:value="form.sku" :placeholder="$t('SKU')" disabled />
              </a-form-item>

              <a-form-item :label="$t('Description')">
                <a-textarea v-model:value="form.description" :placeholder="$t('Description')" />
              </a-form-item>
              <a-form-item :label="$t('Tax')">
                <a-select v-model:value="form.tax" :placeholder="$t('Tax')">
                  <a-select-option :value="0">%0</a-select-option>
                  <a-select-option :value="8">%8</a-select-option>
                  <a-select-option :value="10">%10</a-select-option>
                  <a-select-option :value="18">%18</a-select-option>
                  <a-select-option :value="20">%20</a-select-option>
                  <a-select-option :value="24">%24</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('Purchase')" style="width: 100%">
                <a-input type="number" v-model:value="form.purchase_cost" :placeholder="$t('Purchase')" />
              </a-form-item>

              <a-form-item :label="`${$t('Sale Price')} (${$t('tax included')})`" style="width: 100%">
                <a-input v-model:value="form.sale_price" :placeholder="$t('Sale Price')" />
              </a-form-item>


            </a-form>
          </a-col>
          <a-col :lg="11">
            <a-form layout="vertical">
              <a-form-item :label="$t('Category')">
                <a-select v-model:value="form.category_id._id" :placeholder="$t('Category')">
                  <a-select-option v-for="(item, index) in categories" :key="index" :value="item._id">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-divider />
              <a-row>
                <a-col>
                  <div>
                    {{ $t("Tax") }} : <b>{{ `%${form.tax}` }}</b>
                  </div>
                  <div>
                    {{ $t("Sale Price") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.sale_price).substring(1)}`
                    }}</b>
                  </div>
                  <div>
                    {{ $t("No Tax Sale Price") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.sale_price / (1 +
                      form.tax / 100)).substring(1)}` }}</b>
                  </div>
                  <div>
                    {{ $t("Purchase") }} : <b>{{ totalPurcase("total") }}</b>
                  </div>
                  <div>
                    {{ $t("No Tax Purchase") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.purchase_cost / (1 +
                      form.tax / 100)).substring(1)}` }}</b>
                  </div>
                  <div>
                    {{ $t("Dollar Rate") }} : <b>{{ moneyFormat(form.dollar_rate) }}</b>
                  </div>
                  <div>
                    {{ $t("Dollar Purchase") }} :
                    <b>{{ moneyFormat(totalPurcase("current") / form.dollar_rate) }}</b>
                  </div>
                </a-col>
              </a-row>
              <!-- <a-form-item :label="$t('Brand')">
                <a-select v-model:value="form.brand_id._id" :placeholder="$t('Brand')">
                  <a-select-option
                    v-for="(item, index) in brands"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('Pattern')">
                <a-select v-model:value="form.pattern_id._id" :placeholder="$t('Pattern')">
                  <a-select-option
                    v-for="(item, index) in patterns"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('Material')">
                <a-select
                  v-model:value="form.material_id._id"
                  :placeholder="$t('Material')"
                >
                  <a-select-option
                    v-for="(item, index) in materials"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('Season')">
                <a-select v-model:value="form.season_id._id" :placeholder="$t('Season')">
                  <a-select-option
                    v-for="(item, index) in seasons"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item> -->
            </a-form>
          </a-col>

        </a-row>
        <!-- <div class="">
          <div class="">
            <h3>
              <b>{{ $t("Purchase") }}</b>
            </h3>
          </div>
          <div class="">
            <a-form layout="vertical">
              <a-row style="display: flex;gap:10px">
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Purchase Cost')">
                    <a-input type="number" v-model:value="form.purchase_cost" :placeholder="$t('Purchase Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Material Cost')">
                    <a-input type="number" v-model:value="form.material_cost" :placeholder="$t('Material Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Cutting Cost')">
                    <a-input type="number" v-model:value="form.cut_cost" :placeholder="$t('Cutting Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Planting Cost')">
                    <a-input type="number" v-model:value="form.planting_cost" :placeholder="$t('Planting Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Printing Cost')">
                    <a-input type="number" v-model:value="form.print_cost" :placeholder="$t('Printing Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Stone Cost')">
                    <a-input type="number" v-model:value="form.stone_cost" :placeholder="$t('Stone Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Embroidery Cost')">
                    <a-input type="number" v-model:value="form.embroidery_cost" :placeholder="$t('Embroidery Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Washing Cost')">
                    <a-input type="number" v-model:value="form.washing_cost" :placeholder="$t('Washing Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Accessory Cost')">
                    <a-input type="number" v-model:value="form.accessory_cost" :placeholder="$t('Accessory Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Ironing Cost')">
                    <a-input type="number" v-model:value="form.ironing_cost" :placeholder="$t('Ironing Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Packaging Cost')">
                    <a-input type="number" v-model:value="form.packaging_cost" :placeholder="$t('Packaging Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Other Cost')">
                    <a-input type="number" v-model:value="form.other_cost" :placeholder="$t('Other Cost')" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12" :xl="11">
                  <a-form-item :label="$t('Dollar Rate')">
                    <a-input type="number" v-model:value="form.dollar_rate" :placeholder="$t('Dollar Rate')" />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div>
                    {{ $t("Total Purcase") }} : <b>{{ totalPurcase("total") }}</b>
                  </div>
                  <div>
                    {{ $t("Dollar Rate") }} : <b>{{ moneyFormat(form.dollar_rate) }}</b>
                  </div>
                  <div>
                    {{ $t("Dollar Total Purcase") }} :
                    <b>{{ moneyFormat(totalPurcase("current") / form.dollar_rate) }}</b>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div> -->
        <a-divider />
        <div class="" style="display: flex; justify-content: end; gap: 15px"
          v-if="form.variants && form.variants.length == 0">
          <a-button type="primary" :loading="loadBtn" @click="update()">{{
            $t("Update")
          }}</a-button>
        </div>
      </div>
      <Loader v-else />
    </a-layout-content>

    <a-layout-content :style="{
      margin: '15px 16px',

      padding: '24px',

      background: '#fff',

      minHeight: '280px',
    }" v-if="form.variants && form.variants.length != 0 && load">
      <div class="" style="display: flex; justify-content: start">
        <a-form v-if="load" layout="vertical" style="width: 100%">
          <a-row class="" style="display: flex; justify-content: space-between; margin-top: 20px">
            <a-col>
              <div class="" style="display: flex; justify-content: start; width: 100%">
                <div class="">
                  <h3><b>Sizes</b></h3>

                  <a-row>
                    <a-col :span="3">
                      <a-checkbox value="XL" name="type" :checked="isHasVarintValue('size', 'XS')"
                        disabled>XS</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="S" name="type" :checked="isHasVarintValue('size', 'S')" disabled>S</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="M" name="type" :checked="isHasVarintValue('size', 'M')" disabled>M</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="L" name="type" :checked="isHasVarintValue('size', 'L')" disabled>L</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="XL" name="type" :checked="isHasVarintValue('size', 'XL')"
                        disabled>XL</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="2XL" name="type" :checked="isHasVarintValue('size', '2XL')"
                        disabled>2XL</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="3XL" name="type" :checked="isHasVarintValue('size', '3XL')"
                        disabled>3XL</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="4XL" name="type" :checked="isHasVarintValue('size', '4XL')"
                        disabled>4XL</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="5XL" name="type" :checked="isHasVarintValue('size', '5XL')"
                        disabled>5XL</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="6XL" name="type" :checked="isHasVarintValue('size', '6XL')"
                        disabled>6XL</a-checkbox>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-col>
            <a-col>
              <div class="" style="display: flex; justify-content: start; width: 100%">
                <div class="">
                  <h3><b>Colors</b></h3>

                  <a-row style="display: flex; gap: 10px">
                    <a-col :span="3">
                      <a-checkbox value="black" name="type" :checked="isHasVarintValue('color', 'Black')"
                        disabled>Black</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="white" name="type" :checked="isHasVarintValue('color', 'White')"
                        disabled>White</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="red" name="type" :checked="isHasVarintValue('color', 'Red')"
                        disabled>Red</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="blue" name="type" :checked="isHasVarintValue('color', 'Blue')"
                        disabled>Blue</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="yellow" name="type" :checked="isHasVarintValue('color', 'Yellow')"
                        disabled>Yellow</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="green" name="type" :checked="isHasVarintValue('color', 'Green')"
                        disabled>Green</a-checkbox>
                    </a-col>

                    <a-col :span="3">
                      <a-checkbox value="orange" name="type" :checked="isHasVarintValue('color', 'Orange')"
                        disabled>Orange</a-checkbox>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-col>
          </a-row>

          <a-divider />

          <div class="">
            <div class="" style="margin-top: 15px">
              <a-form-item :label="`${item.size ? item.size : ''} ${item.color ? $t(`${item.color}`) : ''
                } SKU`" v-for="(item, index) in form.variants" :key="index">
                <a-input v-model:value="item.sku" @change="onC()" placeholder="$t('SKU')" disabled />
              </a-form-item>
            </div>
          </div>

          <div class="" style="display: flex; justify-content: end; width: 100%">
            <a-form-item>
              <a-button type="primary" :loading="loadBtn" @click="update()">{{
                $t("Update")
              }}</a-button>
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  components: { PageTitle, PageTitle, Loader },
  data() {
    return {
      form: {},
      loadBtn: false,
      load: false,
      categories: [],
      patterns: [],
      materials: [],
      brands: [],
      seasons: [],
      variantValues: [],
      sizes: [],
      colors: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.getData();
    this.getCategory();
    // this.getPattern();
    // this.getBrand();
    // this.getMaterial();
    // this.getSeason();
  },
  methods: {
    onC() {
      console.log(this.form.variants);
    },
    changeVariantValue(type, value) {
      if (type == "size") {
        const checkSize = this.sizes.filter((e) => e == value);
        if (checkSize.length == 0) {
          this.sizes.push(value);
          console.log(this.sizes);
          this.isHasVarintValue(type, value);
        } else {
          const item = this.sizes.find((e) => e == value);
          const index = this.sizes.indexOf(item);
          this.sizes.splice(index, 1);
          console.log(this.sizes);
          this.isHasVarintValue(type, value);
        }
      } else {
        // beden seçimi
        const checkColor = this.colors.filter((e) => e == value);
        if (checkColor.length == 0) {
          this.colors.push(value);
          console.log(this.colors);
          this.isHasVarintValue(type, value);
        } else {
          const item = this.colors.find((e) => e == value);
          const index = this.colors.indexOf(item);
          this.colors.splice(index, 1);
          console.log(this.colors);
          this.isHasVarintValue(type, value);
        }
      }
    },
    isHasVarintValue(type, value) {
      if (type == "size") {
        if (this.form.variants.find((e) => e.size == value)) {
          return true;
        } else {
          false;
        }
      } else if (type == "color") {
        if (this.form.variants.find((e) => e.color == value)) {
          return true;
        } else {
          false;
        }
      }
    },
    async handleFileUpload(event) {
      this.form.img = event.target.files[0];
    },
    totalPurcase(params) {
      var data =
        Number(this.form.purchase_cost) +
        Number(this.form.material_cost) +
        Number(this.form.cut_cost) +
        Number(this.form.planting_cost) +
        Number(this.form.print_cost) +
        Number(this.form.stone_cost) +
        Number(this.form.embroidery_cost) +
        Number(this.form.washing_cost) +
        Number(this.form.accessory_cost) +
        Number(this.form.ironing_cost) +
        Number(this.form.packaging_cost) +
        Number(this.form.other_cost);
      if (params == "total") {
        return `${this.user.firm.currency}${this.moneyFormat(data).substr(1)}`;
      } else {
        return data;
      }
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    async update() {
      this.loadBtn = true;
      let formData = new FormData();
      if (this.form.img) {
        await formData.append("img", this.form.img);
      }
      await formData.append("name", this.form.name);
      await formData.append("category_id", this.form.category_id._id);
      // await formData.append("pattern_id", this.form.pattern_id._id);
      // await formData.append("brand_id", this.form.brand_id._id);
      // await formData.append("material_id", this.form.material_id._id);
      // await formData.append("season_id", this.form.season_id._id);
      await formData.append("sale_price", this.form.sale_price);
      if (this.form.description) {
        await formData.append("description", this.form.description);
      }
      await formData.append("material_cost", this.form.material_cost);
      await formData.append("purchase_cost", this.form.purchase_cost);
      await formData.append("cut_cost", this.form.cut_cost);
      await formData.append("planting_cost", this.form.planting_cost);
      await formData.append("print_cost", this.form.print_cost);
      await formData.append("stone_cost", this.form.stone_cost);
      await formData.append("embroidery_cost", this.form.embroidery_cost);
      await formData.append("washing_cost", this.form.washing_cost);
      await formData.append("accessory_cost", this.form.accessory_cost);
      await formData.append("ironing_cost", this.form.ironing_cost);
      await formData.append("packaging_cost", this.form.packaging_cost);
      await formData.append("dollar_rate", this.form.dollar_rate);
      await formData.append("other_cost", this.form.other_cost);
      await formData.append("tax", this.form.tax);
      // var data = {
      //   name: this.form.name,
      //   description: this.form.description,
      //   category_id: this.form.category_id._id,
      //   brand_id: this.form.brand_id._id,
      //   pattern_id: this.form.pattern_id._id,
      //   material_id: this.form.material_id._id,
      //   season_id: this.form.season_id._id,
      //   material_cost: this.form.material_cost,
      //   cut_cost: this.form.cut_cost,
      //   planting_cost: this.form.planting_cost,
      //   print_cost: this.form.print_cost,
      //   stone_cost: this.form.stone_cost,
      //   embroidery_cost: this.form.embroidery_cost,
      //   washing_cost: this.form.washing_cost,
      //   accessory_cost: this.form.accessory_cost,
      //   ironing_cost: this.form.ironing_cost,
      //   packaging_cost: this.form.packaging_cost,
      //   dollar_rate: this.form.dollar_rate,
      //   other_cost: this.form.other_cost,
      // };
      await axios
        .patch(`/api/product/${this.$route.params.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    getData() {
      axios
        .get(`/api/product/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data.data;
          this.load = true;
        })
        .catch((err) => { });
    },
    async getSeason() {
      await axios
        .get("/api/season/all")
        .then(async (result) => {
          this.seasons = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getMaterial() {
      await axios
        .get("/api/material/all")
        .then(async (result) => {
          this.materials = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getBrand() {
      await axios
        .get("/api/brand/all")
        .then(async (result) => {
          this.brands = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getPattern() {
      await axios
        .get("/api/pattern/all")
        .then(async (result) => {
          this.patterns = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getCategory() {
      await axios
        .get("/api/category/all")
        .then(async (result) => {
          console.log('====================================');
          console.log(result.data);
          console.log('====================================');
          this.categories = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
