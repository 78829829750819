<template>
  <div>
    <a-col>
      <a-card :loading="load" :bordered="false" style="width: 285px; margin-top: 10px">
        <div class="box" style="display: flex;
                      justitfy-content: start,
                      alignItems: center">
          <div class="" style="color: #959595">{{ title }}</div>
        </div>
        <div class="">
          <div style="display: flex;
                      justitfy-content: start,
                      alignItems: center">
            <span style="font-size: 36px">{{ value < 0 ? `-${user.firm.currency}` + moneyFormat(value * -1).substr(1) :
              `${user.firm.currency}` + moneyFormat(value).substr(1) }}</span>
                <!-- <span style="font-size: 36px"
              >{{ user.firm.currency }}{{ moneyFormat(value).substr(1) }}</span
            > -->
          </div>
          <hr />
          <div style="display: flex;
                      justitfy-content: start,
                      alignItems: center">
            <div class="">
              <a-statistic :value="current" :precision="2" suffix="%" class="demo-class" :value-style="{
                color: current > 0 ? '#3f8600' : current == 0.0 ? '#959595' : '#cf1322',
                fontSize: '16px',
              }">
                <template #prefix>
                  <arrow-up-outlined v-if="current > 0" />
                  <minus-outlined v-if="current == 0" />
                  <arrow-down-outlined v-if="current < 0" />
                </template>
              </a-statistic>
            </div>
          </div>
        </div>
      </a-card>
    </a-col>
  </div>
</template>

<script>
import Loader from "../shared/Loader.vue";
import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from "@ant-design/icons-vue";
export default {
  props: ["title", "value", "loading", "current"],
  components: { Loader, ArrowDownOutlined, ArrowUpOutlined, MinusOutlined },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      load: true,
    };
  },
  watch: {
    // whenever question changes, this function will run
    loading(newData, oldData) {
      this.load = false;
    },
  },
  methods: {
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
