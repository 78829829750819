<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('NO')" style="margin: 10px">
              <a-input v-model:value="filter.invoice_no" :placeholder="$t('NO')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Branch')" style="margin: 10px">
              <a-select v-model:value="filter.branch_id" :placeholder="$t('Branch')">
                <a-select-option
                  v-for="(item, index) in branchs"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Customer')" style="margin: 10px">
              <a-select v-model:value="filter.customer_id" :placeholder="$t('Customer')">
                <a-select-option
                  v-for="(item, index) in customer"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Amount')" style="margin: 10px">
              <a-select v-model:value="filter.amount" :placeholder="$t('Amount')">
                <a-select-option :value="1">$0-$100</a-select-option>
                <a-select-option :value="2">$100-$500</a-select-option>
                <a-select-option :value="3">$500-$1.000</a-select-option>
                <a-select-option :value="4">$1.000-$5.000</a-select-option>
                <a-select-option :value="5">$5.000-$10.000</a-select-option>
                <a-select-option :value="6">$10.000-$50.000</a-select-option>
                <a-select-option :value="7">$50.000-$100.000</a-select-option>
                <a-select-option :value="8">+$100.000</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Type')" style="margin: 10px">
              <a-select v-model:value="filter.type" :placeholder="$t('Type')">
                <a-select-option :value="1">{{ $t('Sell') }}</a-select-option>
                <a-select-option :value="2">{{ $t('Return') }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Payment')" style="margin: 10px">
              <a-select v-model:value="filter.paid" :placeholder="$t('Payment')">
                <a-select-option :value="1">{{ $t('Paid') }}</a-select-option>
                <a-select-option :value="2">{{ $t('No Paid') }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="user.earsiv" :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('E-arşiv')" style="margin: 10px">
              <a-select v-model:value="filter.isReal" :placeholder="$t('E-arşiv')">
                <a-select-option value="earsiv">{{ $t('E-arşiv') }}</a-select-option>
                <a-select-option value="normal">{{ $t('Normal')}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Currency')" style="margin: 10px">
              <a-select v-model:value="filter.currency" :placeholder="$t('Currency')">
                <a-select-option :value="'$'">$ Dollar</a-select-option>
                <a-select-option :value="'€'">€ Euro</a-select-option>
                <a-select-option :value="'₺'">₺ Lira</a-select-option>
                <a-select-option :value="'£'">£ Pound</a-select-option>
                <a-select-option :value="'₼'">₼ Manat</a-select-option>
                <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                <a-select-option :value="'SR'">SR Riyal</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Date')" style="margin: 10px">
              <a-range-picker style="width: 100%;" v-model:value="filter.date" />
            </a-form-item>
          </a-col>
          <a-form-item
            v-if="
              filter.invoice_no ||
              filter.branch_id ||
              filter.customer_id ||
              filter.amount ||
              filter.currency ||
              filter.date ||
              filter.paid ||
              filter.type||
              filter.isReal
            "
            style="margin: 10px"
          >
            <a-button type="primary" @click="setFilter()">{{$t('Filter')}}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{$t('Clear')}}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      customer: [],
      branchs: [],
      filter: {},
      filterText: "",
      loader: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    this.getCustomer();
    this.getBranch();
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);
      if (this.filter.customer_id) {
        this.filterText = this.filterText + `customer_id=${this.filter.customer_id}&`;
      }
      if (this.filter.branch_id) {
        this.filterText = this.filterText + `branch_id=${this.filter.branch_id}&`;
      }
      if (this.filter.invoice_no) {
        this.filterText = this.filterText + `invoice_no=${this.filter.invoice_no}&`;
      }
      if (this.filter.amount) {
        this.filterText = this.filterText + `amount=${this.filter.amount}&`;
      }
      if (this.filter.type) {
        this.filterText = this.filterText + `type=${this.filter.type}&`;
      }
      if (this.filter.currency) {
        this.filterText = this.filterText + `currency=${this.filter.currency}&`;
      }
      if (this.filter.date) {
        this.filterText = this.filterText + `date=${this.filter.date}&`;
      }
      if (this.filter.paid) {
        this.filterText = this.filterText + `paid=${this.filter.paid}&`;
      }
      if (this.filter.isReal) {
        this.filterText = this.filterText + `isReal=${this.filter.isReal}&`;
      }

      await axios
        .get(`/api/invoice/all?${this.filterText}`)
        .then((result) => {
          this.loader = true;
          this.$emit("itemFilter", result.data);
          this.$emit("newLoad", this.loader);
        })
        .catch((err) => {});
    },
    async getCustomer() {
      await axios
        .get("/api/customer/all")
        .then((result) => {
          this.customer = result.data;
        })
        .catch((err) => {});
    },
    async getBranch() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
