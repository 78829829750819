<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle title="Orders" />
      <router-link to="/orders"><a-button>Go to Back</a-button></router-link>
    </div>

    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <div class="" v-if="loader">
        <div class="" style="display: flex; justify-content: start;gap:15px">
          <div class="">
            <h1>
              Order No: <b>{{ data.order_id }}</b>
            </h1>
          </div>
          <div class="">
            <a-tag v-if="data.status == 'pending'"  color="green">{{data.status}}</a-tag>
            <a-tag v-if="data.status == 'shipping'"  color="blue">{{data.status}}</a-tag>
            <a-tag v-if="data.status == 'return'"  color="red">{{data.status}}</a-tag>
            <a-tag v-if="data.status == 'delivered'"  color="green">{{data.status}}</a-tag>
            <a-tag v-else  color="orange">{{data.status}}</a-tag>
          </div>
        </div>
        <a-divider />
        <div class="">
          <div class="" style="display: flex; justify-content: start">
            <h2><b>Customer Info</b></h2>
          </div>
          <div class="" style="display: flex; justify-content: start">
            <p><b>Name:</b> {{ data.name }}</p>
          </div>
          <div class="" style="display: flex; justify-content: start">
            <p><b>Phone:</b> {{ data.phone }}</p>
          </div>
          <div class="" style="display: flex; justify-content: start">
            <p><b>Address:</b> {{ data.address }}</p>
          </div>
        </div>
        <a-divider />
        <div class="">
          <div class="" style="display: flex; justify-content: start">
            <h2><b>Products</b></h2>
          </div>
          <div class="">
            <a-table
            :scroll="{ x: 1000 }"
              v-if="loader"
              :columns="columns"
              :data-source="colData"
              @change="onChange"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.key === 'image'">
                  <div class="">
                    <img
                      v-if="text.img"
                      :src="'https://drive.google.com/file/d/'+text.img"
                      alt=""
                    />
                    <div v-else class="">-</div>
                  </div>
                </template>
                <template v-if="column.key === 'product'">
                  <a-span>{{ `${text.name} (${text.sku})` }}</a-span>
                </template>
              </template>
            </a-table>
            <div style="display: flex;justify-content: end;">
              <a-card title="Order Amount" style="width: 300px;border: 1px solid rgba(0, 0, 0, 0.2);;">
                <div class="" style="display: flex;justify-content: start;">
                    <p>Product Quantity: <b>{{colData.length}}</b></p>
                </div>
                <div class="" style="display: flex;justify-content: start;">
                    <p>Subtotal: <b>${{data.amount}}</b></p>
                </div>
                <div class="" style="display: flex;justify-content: start;">
                    <p>Other Price: <b>$0</b></p>
                </div>
                <div class="" style="display: flex;justify-content: start;">
                    <p>Total: <b>${{data.amount}}</b></p>
                </div>
              </a-card>
            </div>
          </div>
        </div>
      </div>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/brand/Button.vue";
import Alert from "@/assets/voices/alert.mp3";
import { EditOutlined } from "@ant-design/icons-vue";

export default {
  data() {
    return {
      loader: false,
      data: {},
      columns: [
        {
          title: "Image",
          dataIndex: "product",
          key: "image",
        },
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "Total",
          dataIndex: "subtotal",
          key: "subtotal",
        },
      ],
      colData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios
        .get(`/api/order/${this.$route.params.id}`)
        .then((result) => {
          this.data = result.data;
          this.loader = true;
          this.colData = result.data.products;
        })
        .catch((err) => {});
    },
  },
  components: { Loader, PageTitle, Button, EditOutlined, alert },
};
</script>

<style lang="scss" scoped></style>
