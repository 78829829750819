<template>
  <div class="">
    <div class="" style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      ">
      <PageTitle :title="$t('Invoices')" />
      <router-link to="/invoices"><a-button>{{ $t("Go to Back") }}</a-button></router-link>
    </div>
    <a-layout-content :style="{
      margin: '0px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px',
    }">
      <a-tag color="yellow" style="width: 100%; padding: 10px; margin: 10px 0 20px 0">{{
        $t("Only the description can be changed as the invoice has been issued")
      }}</a-tag>
      <a-form v-if="load" layout="vertical">
        <a-row class="" style="
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 15px;
          ">
          <a-col>
            <div class="">
              <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  gap: 15px;
                ">
                <span for="">{{ $t("Type") }}:</span>
                <a-tag v-if="form.type == 'sell'" message="Sell" color="green">{{
                  $t("Sell")
                }}</a-tag>
                <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
              </div>
              <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  gap: 15px;
                ">
                <span for="">{{ $t("Branch") }}:</span>
                <span><b>{{ form.branch_id.name }}</b></span>
              </div>
              <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  gap: 15px;
                ">
                <span for="">{{ $t("Customer") }}:</span>
                <span><b>{{ form.customer_id.name }}</b></span>
              </div>
              <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  gap: 15px;
                ">
                <span for="">{{ $t("Date") }}:</span>
                <span><b>{{
                  new Date(form.invoice_date).toLocaleString().substring(0, 10)
                }}</b></span>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="">
              <label for="">{{ $t("Description") }}</label>
              <a-textarea rows="5" cols="80" v-model:value="form.desc" :placeholder="$t('Description')" />
            </div>
          </a-col>
        </a-row>
        <a-divider />
        <a-row style="display: flex; justify-content: space-between">
          <a-col :lg="12">
            <div class="">
              <h3>
                <b>{{ $t("Products") }}</b>
              </h3>
              <a-divider />
              <div class="title" style="display: flex; justify-content: start; align-items: flex-start">
                <div class="">
                  <div class="" style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      gap: 15px;
                    " v-for="(item, index) in products" :key="index">
                    <a-form-item>
                      <label for="" style="font-size: 12px; color: #959595">{{
                        $t("SKU")
                      }}</label>
                      <a-input v-model:value="item.sku" placeholder="SKU" @change="cc()" disabled />
                    </a-form-item>
                    <a-form-item>
                      <label for="" style="font-size: 12px; color: #959595">{{
                        $t("Quantity")
                      }}</label>
                      <a-input v-model:value="item.quantity" placeholder="Quantity" @change="cc()" disabled />
                    </a-form-item>
                    <a-form-item style="width: 33%" :label="$t('No Tax Sale Price')">
                      <a-input :disabled="true" :id="'price-' + index"
                        v-model:value="item.no_tax_price" :placeholder="$t('No Tax Sale Price')" />
                    </a-form-item>
                    <a-form-item :label="$t('Tax')">
                      <a-select :disabled="true" v-model:value="item.tax"
                        :placeholder="$t('Tax')">
                        <a-select-option :value="0">%0</a-select-option>
                        <a-select-option :value="8">%8</a-select-option>
                        <a-select-option :value="10">%10</a-select-option>
                        <a-select-option :value="18">%18</a-select-option>
                        <a-select-option :value="20">%20</a-select-option>
                        <a-select-option :value="24">%24</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item>
                      <label for="" style="font-size: 12px; color: #959595">{{
                        $t("Price")
                      }}</label>
                      <a-input v-model:value="item.price" placeholder="Price" @change="cc()" disabled />
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :lg="8">
            <a-list class="demo-loadmore-list" item-layout="horizontal">
              <a-list-item>{{ $t("SubTotal") }}:
                <b>{{
                  `${form.currency}${moneyFormat(cartTotal()).substr(1)}`
                }}</b>
              </a-list-item>
              <a-list-item>{{ $t("Tax") }}:
                <b>{{
                  `${form.currency}${moneyFormat(taxTotal()).substr(1)}`
                }}</b>
              </a-list-item>
              <a-list-item v-if="form.discount">{{ $t("Discount") }}:
                <b>{{
                  form.discountType == "%"
                  ? `-%${form.discount}`
                  : `-${form.currency}${form.discount}`
                }}</b>
              </a-list-item>
              <a-list-item>{{ $t("Amount") }}:
                <b>{{
                  `${user.firm.currency}${moneyFormat(totalAmount()).substr(1)}`
                }}</b>
              </a-list-item>
            </a-list>
            <!-- <div class="price">
              <h3>{{ $t("Price") }}</h3>
              <a-list class="demo-loadmore-list" item-layout="horizontal">
                <a-list-item
                  >{{ $t("SubTotal") }}:
                  <b>{{
                    form.subTotal < 0
                      ? `-${form.currency}` + moneyFormat(form.subTotal * -1).substr(1)
                      : `${form.currency}` + moneyFormat(form.subTotal).substr(1)
                  }}</b>
                </a-list-item>
                <a-list-item
                  >{{ $t("Tax") }}: <b>%{{ form.tax }}</b></a-list-item
                >
                <a-list-item
                  >{{ $t("Sub Amount") }}:
                  <b>{{
                    form.subAmount < 0
                      ? `-${form.currency}` + moneyFormat(form.subAmount * -1).substr(1)
                      : `${form.currency}` + moneyFormat(form.subAmount).substr(1)
                  }}</b>
                </a-list-item>
                <a-list-item v-if="form.discount"
                  >{{ $t("Discount") }}:
                  <b>{{
                    form.discountType == "%"
                      ? `-%${form.discount}`
                      : `-${form.currency}${form.discount}`
                  }}</b></a-list-item
                >
                <a-list-item
                  >{{ $t("Amount") }}:
                  <b>{{
                    form.amount < 0
                      ? `-${form.currency}` + moneyFormat(form.amount * -1).substr(1)
                      : `${form.currency}` + moneyFormat(form.amount).substr(1)
                  }}</b>
                </a-list-item>
              </a-list>
            </div> -->
          </a-col>
        </a-row>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end">
            <a-button type="primary" :loading="loadBtn" @click="update()">{{
              $t("Update")
            }}</a-button>
          </div>
        </a-form-item>
      </a-form>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  components: { PageTitle, PageTitle, Loader, MinusOutlined, PlusOutlined },
  data() {
    return {
      form: {},
      loadBtn: false,
      load: false,
      products: [],
      user: JSON.parse(localStorage.getItem("user")),

    };
  },
  created() {
    this.getData();
    this.getBranchs();
    this.getCustomers();
  },
  methods: {
    totalAmount() {
      var amount = Number(this.cartTotal()) + Number(this.taxTotal())
      if (this.form.discount > 0) {
        if (this.form.discountType == "%") {
          amount = amount - (amount * this.form.discount / 100)
        } else {
          amount -= this.form.discount
        }
      }
      return amount.toFixed(2)
    },
    cartTotal() {
      var total = 0;
      this.products.forEach((e) => {
        total = total + e.no_tax_price * e.quantity;
      });
      return total;
    },
    taxTotal() {
      var total = 0;
      this.products.forEach((e) => {
        total = total + (e.no_tax_price * e.tax / 100) * e.quantity;
      });
      return total;
    },
    async update() {
      this.loadBtn = true;
      var data = {
        desc: this.form.desc,
      };
      await axios
        .patch(`/api/invoice/${this.$route.params.id}`, data)
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    async getBranchs() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
        })
        .catch((err) => { });
    },
    async getCustomers() {
      await axios
        .get("/api/customer/all")
        .then((result) => {
          this.customers = result.data;
        })
        .catch((err) => { });
    },
    getData() {
      axios
        .get(`/api/invoice/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data;
          this.load = true;
          this.products = result.data.products;
        })
        .catch((err) => { });
    },
    addProduct() {
      this.products.push({
        id: Math.ceil(Math.random() * 8498615),
        sku: "",
        quantity: null,
      });
    },
    cc() {
      console.log(this.products);
    },
    removeProduct(id) {
      const check = this.products.find((e) => e.id == id);
      const index = this.products.indexOf(check);
      this.products.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
