<template>
  <div class="">
    <PageTitle :title="$t('Account Settings')" />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <div class="" style="display: flex; justify-content: space-between">
        <a-row style="display: flex; justify-content: space-between; width: 100%">
          <a-col :xs="24" :xl="12" :md="12">
            <a-form layout="vertical" style="width: 90%">
              <a-form-item :label="$t('Name')">
                <a-input v-model:value="user.name" :placeholder="$t('Name')" />
              </a-form-item>
              <a-form-item :label="$t('E-mail')">
                <a-input v-model:value="user.email" :placeholder="$t('E-mail')" />
              </a-form-item>
              <a-form-item style="float: right">
                <a-button :loading="loading" type="primary" @click="update()">{{
                  $t("Update")
                }}</a-button>
              </a-form-item>
            </a-form>
          </a-col>
          <a-col :xs="24" :xl="12" :md="12">
            <a-form title="asda" layout="vertical" style="width: 90%">
              <h3>{{$t('Change Password')}}</h3>
              <a-form-item :label="$t('Old Password')">
                <a-input
                  type="password"
                  v-model:value="password.oldPassword"
                  :placeholder="$t('Old Password')"
                />
              </a-form-item>
              <a-form-item :label="$t('Old Password Confirm')">
                <a-input
                  type="password"
                  v-model:value="password.oldPasswordConfirm"
                  :placeholder="$t('Old Password Confirm')"
                />
              </a-form-item>
              <a-form-item :label="$t('New Password')">
                <a-input
                  type="password"
                  v-model:value="password.newPassword"
                  :placeholder="$t('New Password')"
                />
              </a-form-item>
              <a-form-item style="float: right">
                <a-button :loading="loadingP" type="primary" @click="changePassword()">{{
                  $t("Update")
                }}</a-button>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  components: { PageTitle },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      loadingP: false,
      password: {},
    };
  },
  methods: {
    changePassword() {
      this.loadingP = true;
      axios
        .patch("api/auth/change-password", this.password)
        .then(async (result) => {
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.password = {};
          this.loadingP = false;
        })
        .catch(async (err) => {
          this.loadingP = false;
          await notification.error({
            message: "Warning",
            description: err.response.data.msg,
            duration: 1.5,
          });
        });
    },
    update() {
      this.loading = true;
      axios
        .patch("/api/auth/update", { name: this.user.name, email: this.user.email })
        .then(async (result) => {
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.loading = false;
        })
        .catch(async(err) => {
          this.loading = false;
          await notification.success({
            message: this.$t("Error"),
            description: err.response.data.msg,
            duration: 1.5,
          });
        });
    }
  },
};
</script>

<style lang="scss" scoped></style>
