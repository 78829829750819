<template>
    <div>
        <div class="lang">
            <a-dropdown :trigger="['click']">
                <i class="fa fa-dollar " style="cursor: pointer;"></i>
                <template #overlay>
                    <a-menu>
                        <a-menu-item key="0" id="ingilizce" @click="switchLang('en')">
                            <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                ">
                                <div class="img">
                                    <i class="fa fa-dollar "></i>
                                </div>
                                <span style="margin-left: 10px">20.6₺</span>
                            </div>
                        </a-menu-item>
                        <a-menu-item key="1" id="ingilizce" @click="switchLang('en')">
                            <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                ">
                                <div class="img">
                                    <i class="fa fa-euro "></i>
                                </div>
                                <span style="margin-left: 10px">20.6₺</span>
                            </div>
                        </a-menu-item>
                        <a-menu-item key="2" id="ingilizce" @click="switchLang('en')">
                            <div class="" style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 5px;
                ">
                                <div class="img">
                                    <i class="fa fa-sterling-sign "></i>
                                </div>
                                <span style="margin-left: 10px">20.6₺</span>
                            </div>
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
import { GlobalOutlined } from "@ant-design/icons-vue";

export default {
    components: {
        GlobalOutlined,
    },
}
</script>

<style lang="scss" scoped></style>