<template>
  <a-layout style="height: 100%">
    <a-layout-sider v-model:collapsed="collapsed" breakpoint="lg" collapsed-width="0" @collapse="onCollapse"
      @breakpoint="onBreakpoint">
      <div class="logo">
        <img style="width: 100%; height: 100%" src="@/assets/images/logo/logo_white.png" alt="" />
      </div>

      <a-menu theme="dark" mode="inline">
        <a-menu-item key="1">
          <div @click="goToPage('home')">
            <home-outlined />
            <span>{{ $t("Home") }}</span>
          </div>
        </a-menu-item>

        <a-menu-item id="invoice-side" key="2" v-if="user.role.invoice">
          <div @click="goToPage('invoices')">
            <file-outlined />
            <span>{{ $t("Invoices") }}</span>
          </div>
        </a-menu-item>

        <a-menu-item id="stock-side" key="3" v-if="user.role.stock">
          <div @click="goToPage('stock')">
            <ApartmentOutlined />
            <span>{{ $t("Stocks") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="product-side" key="4" v-if="user.role.product">
          <div @click="goToPage('products')">
            <tags-outlined />
            <span>{{ $t("Products") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="category-side" key="5" v-if="user.role.product">
          <div @click="goToPage('categories')">
            <AppstoreOutlined />
            <span>{{ $t("Categories") }}</span>
          </div>
        </a-menu-item>

        <!-- <a-sub-menu id="variable-side" key="sub1" v-if="user.role.product">
          <template #icon>
            <home-outlined />
          </template>
          <template #title>
            {{ $t("Product Variables") }}
          </template>
          <a-menu-item key="5">
            <div @click="goToPage('categories')">{{ $t("Categories") }}</div>
          </a-menu-item>
          <a-menu-item key="6"
            ><div @click="goToPage('brands')">{{ $t("Brands") }}</div></a-menu-item
          >
          <a-menu-item key="7"
            ><div @click="goToPage('materials')">{{ $t("Materials") }}</div></a-menu-item
          >
          <a-menu-item key="8"
            ><div @click="goToPage('patterns')">{{ $t("Patterns") }}</div></a-menu-item
          >
          <a-menu-item key="9"
            ><div @click="goToPage('seasons')">{{ $t("Seasons") }}</div></a-menu-item
          >
        </a-sub-menu> -->
        <a-menu-item id="branch-side" key="10" v-if="user.role.branch">
          <div @click="goToPage('branches')">
            <ClusterOutlined />
            <span>{{ $t("Branches") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="customer-side" key="11" v-if="user.role.customer">
          <div @click="goToPage('customers')">
            <idcard-outlined />
            <span>{{ $t("Customers") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="internal-side" key="12" v-if="user.role.internal">
          <div @click="goToPage('internals')">
            <swap-outlined />
            <span>{{ $t("Internals") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="transfer-side" key="13" v-if="user.role.transfer">
          <div @click="goToPage('transfers')">
            <swap-outlined />
            <span>{{ $t("Transfers") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item key="14" v-if="user.role.income">
          <div @click="goToPage('income-expenses')">
            <retweet-outlined />
            <span>{{ $t("Income & Expenses") }}</span>
          </div>
        </a-menu-item>
        <a-menu-item id="report-side" key="15" v-if="user.role.reports">
          <div @click="goToPage('reports')">
            <bar-chart-outlined />
            <span>{{ $t("Reports") }}</span>
          </div>
        </a-menu-item>
        <a-sub-menu id="user-side" key="sub4" v-if="user.role.users">
          <template #icon>
            <UsergroupAddOutlined />
          </template>
          <template #title>
            {{ $t("Users") }}
          </template>
          <a-menu-item key="17">
            <div @click="goToPage('roles')">{{ $t("Roles") }}</div>
          </a-menu-item>
          <a-menu-item key="19">
            <div @click="goToPage('workers')">{{ $t("Workers") }}</div>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="20" v-if="user.role.invoice">
          <div @click="goToPage('integrations')">
            <ApiOutlined />
            <span>{{ $t("Integrations") }}</span>
          </div>
        </a-menu-item>
        <a-sub-menu key="sub5">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>
            {{ $t("Settings") }}
          </template>
          <a-menu-item key="21">
            <div @click="goToPage('account')">
              {{ $t("Account Settings") }}
            </div>
          </a-menu-item>
          <a-menu-item key="22" v-if="user.role.firmSetting">
            <div @click="goToPage('firm-settings')">
              {{ $t("Firm Settings") }}
            </div>
          </a-menu-item>
          <!-- <a-menu-item key="23" v-if="user.role.subSetting ">
            <div @click="goToPage('subscription-settings')">
              {{ $t("Subscribtion Settings") }}
            </div>
          </a-menu-item> -->
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="
                            background: #fff;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          ">
        <div class="">
          <div class="" v-if="!isResponsive">
            <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
            <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
          </div>
        </div>
        <div class="" style="display: flex; gap: 15px">
          <div id="fast-sell" class="fast-sell" v-if="user.role.pos">
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1">
                    <router-link to="/fast-sell">
                      <ShoppingCartOutlined style="font-size: 18px;" /> <span style="margin-left: 5px;">{{ $t("Fast Sell")
                      }}</span>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <router-link to="/fast-internal">
                      <ArrowRightOutlined style="font-size: 18px;" /> <span style="margin-left: 5px;">{{ $t("Fast Internal")
                      }}</span>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="3">
                    <router-link to="/fast-transfer">
                      <swap-outlined  style="font-size: 18px;" /> <span style="margin-left: 5px;">{{ $t("Fast Transfer")
                      }}</span>
                    </router-link>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                <PlusOutlined />
                {{ $t('Fast Actions') }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </div>
          <div class="lang">
            <Language />
          </div>
          <!-- <div class="exchange">
            <ExchangeRate />
          </div> -->
          <div class="user">
            <a-dropdown :trigger="['click']">
              <div v-if="user.firm.logo != null" class="img" style="width: 36px; height: 36px">
                <img style="width: 100%; height: 100%; border-radius: 100%; cursor: pointer"
                  :src="'https://storage.googleapis.com/texco1/' + user.firm.logo" alt="" srcset="" />
              </div>
              <a-avatar v-else :size="36" style="cursor: pointer">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <span>{{ $t("Hello") }}, {{ user.name }}</span>
                  </a-menu-item>
                  <a-menu-item key="2" @click="logout()">
                    <arrow-left-outlined style="color: red" />

                    <span style="margin-left: 5px; color: red">{{ $t("Logout") }}</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </a-layout-header>
      <div class="" style="min-height: 100vh">
        <router-view></router-view>
      </div>
      <a-row class="footer" style="margin-top: 15px; margin-bottom: 15px; padding: 0 15px">
        <a-col :xs="24" :xl="24" class="">
          <span>Copyright {{ new Date().getFullYear() }} Texcofa</span>
        </a-col>
        <a-col :xs="24" :xl="24" class="">
          <span>{{ $t("Support and Help") }}: (+90) 546 571 14 45</span>
        </a-col>
      </a-row>
    </a-layout>
  </a-layout>
</template>

<script>
import Sidebar from "@/components/shared/Sidebar.vue";
import Header from "@/components/shared/Header.vue";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  GlobalOutlined,
  HomeOutlined,
  EuroOutlined,
  ArrowLeftOutlined,
  BarChartOutlined,
  ApartmentOutlined,
  TagsOutlined,
  FileOutlined,
  IdcardOutlined,
  SwapOutlined,
  RetweetOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  ClusterOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  ApiOutlined,
  AppstoreOutlined,
PlusOutlined,
ArrowRightOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Language from "@/components/shared/Language.vue";
import ExchangeRate from "@/components/shared/ExchangeRate.vue";
export default {
  components: {
    Header,
    SwapOutlined,
    RetweetOutlined,
    TagsOutlined,
    IdcardOutlined,
    FileOutlined,
    UserOutlined,
    EuroOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BellOutlined,
    ApartmentOutlined,
    BarChartOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    ArrowLeftOutlined,
    GlobalOutlined,
    Sidebar,
    ShareAltOutlined,
    ShoppingCartOutlined,
    ClusterOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    Language,
    ApiOutlined,
    AppstoreOutlined,
    PlusOutlined,
    ArrowRightOutlined,
    ExchangeRate
},

  data() {
    return {
      collapsed: false,
      user: JSON.parse(localStorage.getItem("user")),
      isResponsive: false,
    };
  },
  methods: {
    goToPage(page) {
      if (window.innerWidth <= 990) {
        this.collapsed = !this.collapsed
        this.$router.push(`/${page}`);
      } else {
        this.$router.push(`/${page}`);
      }
    },
    onCollapse(collapsed, type) {
      if (collapsed == true) {
        this.isResponsive = true;
      }
      console.log("o", collapsed, type);
    },
    onBreakpoint(broken) {
      console.log("b", broken);
    },
    async logout() {
      await localStorage.removeItem("token");
      await localStorage.removeItem("user");
      await this.$router.push("/");
      await notification.success({
        message: this.$t("Success"),
        description: this.$t("logged out"),
        duration: 1.5,
      });
    },
    switchLang(param) {
      localStorage.setItem("lang", param);
      this.$i18n.locale = param;
    },
  },
};
</script>

<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-layout-sider-zero-width-trigger {
  top: 30px;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.logo {
  margin: 16px;
  height: 45px;
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>
