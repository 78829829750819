<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row>
          <a-col :xs="24" :xl="8" :md="12">
            <a-form-item :label="$t('NO')" style="margin: 10px">
              <a-input v-model:value="filter.internal_id" :placeholder="$t('NO')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="8" :md="12">
            <a-form-item :label="$t('Branch')" style="margin: 10px">
              <a-select v-model:value="filter.branch_id" :placeholder="$t('Branch')">
                <a-select-option
                  v-for="(item, index) in branchs"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="8" :md="12">
            <a-form-item :label="$t('Type')" style="margin: 10px">
              <a-select v-model:value="filter.type" :placeholder="$t('Type')">
                <a-select-option :value="1">{{$t('Input')}}</a-select-option>
                <a-select-option :value="2">{{$t('Output')}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="8" :md="12">
            <a-form-item :label="$t('Date')" style="margin: 10px">
              <a-range-picker v-model:value="filter.date" />
            </a-form-item>
          </a-col>
          <a-form-item
            v-if="filter.internal_id || filter.branch_id || filter.date || filter.type"
            style="margin: 10px"
          >
            <a-button type="primary" @click="setFilter()">{{$t('Filter')}}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{$t('Clear')}}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      customer: [],
      branchs: [],
      filter: {},
      filterText: "",
      loader: false,
    };
  },
  mounted() {
    this.getBranch();
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);

      if (this.filter.branch_id) {
        this.filterText = this.filterText + `branch_id=${this.filter.branch_id}&`;
      }
      if (this.filter.internal_id) {
        this.filterText = this.filterText + `internal_id=${this.filter.internal_id}&`;
      }

      if (this.filter.type) {
        this.filterText = this.filterText + `type=${this.filter.type}&`;
      }
      if (this.filter.date) {
        this.filterText = this.filterText + `date=${this.filter.date}&`;
      }
      await axios
        .get(`/api/internal/all?${this.filterText}`)
        .then((result) => {
          this.loader = true;
          this.$emit("itemFilter", result.data);
          this.$emit("newLoad", this.loader);
        })
        .catch((err) => {});
    },
    async getBranch() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
