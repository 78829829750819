<template>
  <div>
    <div class="">
      <div class="info">
        <h3>{{ $t('Info') }}</h3>
        <div v-if="data.tckn" class="tckn" style="margin-bottom: 10px">
          <span>{{ $t('TCKN') }}: <b>{{ data.tckn }}</b></span>
        </div>
        <div class="name" style="margin-bottom: 10px">
          <span>{{ $t('Name') }}: <b>{{ data.name }}</b></span>
        </div>
        <div v-if="data.phone" class="phone" style="margin-bottom: 10px">
          <span>{{ $t('Phone') }}: <b>{{ data.phone }}</b></span>
        </div>
        <div v-if="data.email" class="name" style="margin-bottom: 10px">
          <span>{{ $t('E-mail') }}: <b>{{ data.email }}</b></span>
        </div>
        <div v-if="data.city" class="city" style="margin-bottom: 10px">
          <span>{{ $t('City') }}: <b>{{ data.city }}</b></span>
        </div>
        <div v-if="data.country" class="country" style="margin-bottom: 10px">
          <span>{{ $t('Country') }}: <b>{{ data.country }}</b></span>
        </div>
        <div v-if="data.address" class="name" style="margin-bottom: 10px">
          <span>{{ $t('Address') }}: <b>{{ data.address }}</b></span>
        </div>
      </div>
      <a-divider />
      <div class="invoices">
        <div class="">
          <h3>{{ $t('Invoices') }}</h3>
          <div class="" v-if="!invoiceLoad">
            <div class="">
              <div class="" style="margin-bottom: 15px;display: flex;justify-content: end;align-items: center;">
                <div class="">
                  <a-select v-model:value="currency" @change="getInvoices()" placeholder="Currency">
                    <a-select-option :value="'$'">$ Dollar</a-select-option>
                    <a-select-option :value="'€'">€ Euro</a-select-option>
                    <a-select-option :value="'₺'">₺ Lira</a-select-option>
                    <a-select-option :value="'£'">£ Pound</a-select-option>
                    <a-select-option :value="'₼'">₼ Manat</a-select-option>
                    <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                    <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                    <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                    <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                    <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                    <a-select-option :value="'SR'">SR Riyal</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="">
                <a-list size="small" bordered :data-source="invoicesInfo">
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <a-list-item-meta>
                        <template #title>
                          <a>{{ item.title }}</a>
                        </template>
                      </a-list-item-meta>
                      <div>
                        {{ item.value }}
                      </div>
                    </a-list-item>
                  </template>
                </a-list>
              </div>
            </div>
            <a-divider />
            <div class="table">
              <a-table :scroll="{ x: 1000 }" :columns="columns" :data-source="invoiceData" @change="onChange">
                <template #bodyCell="{ column, text, record }">
                  <template v-if="column.key === 'btn'">
                    <div class="" style="
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            gap: 15px;
                                                          ">
                      <a-button @click="showDrawer(text)">
                        <EyeOutlined />
                      </a-button>
                    </div>
                  </template>
                  <template v-if="column.key === 'branch_id'">
                    <span>{{ record.branch_id != null ? record.branch_id.name : "-" }}</span>
                  </template>
                  <template v-if="column.key === 'customer_id'">
                    <span>{{ record.customer_id != null ? record.customer_id.name : "-" }}</span>
                  </template>
                  <template v-if="column.key === 'invoice_date'">
                    <span>{{ text ? text.substr(0, 10) : "-" }}</span>
                  </template>
                  <template v-if="column.key === 'amount'">
                    <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
                      moneyFormat(text).substr(1) }}</span>
                        <!-- <span>{{ record.currency }}{{ moneyFormat(text).substr(1) }}</span> -->
                  </template>
                  <template v-if="column.key === 'products'">
                    <span>{{
                      record.products
                      ? text.reduce((accumulator, object) => {
                        return accumulator + object.quantity;
                      }, 0)
                      : "0"
                    }}</span>
                  </template>
                  <template v-if="column.key === 'type'">
                    <div class="" style="display: flex;justify-content: start;align-items: center;">
                      <div class="">
                        <a-tag v-if="text == 'sell'" message="Sell" color="green">{{
                          $t("Sell")
                        }}</a-tag>
                        <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
                      </div>
                      <div class="">
                        <a-tag v-if="record.remainingAmount == 0" message="Paid" color="blue">{{
                          $t("Paid")
                        }}</a-tag>
                        <a-tag v-else message="Return" color="orange">{{ $t("No Paid") }}</a-tag>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <div class="" style="display: flex;justify-content: center;margin-top: 150px;" v-else>
            <Loader />
          </div>
        </div>
        <a-drawer v-if="visibleDetailLoad" :title="`${$t('Invoice')} ` + invoiceDetail.invoice_no" placement="right"
          :closable="false" v-model:visible="visibleDetail" :after-visible-change="afterVisibleChange">
          <InvoiceDetail @invoiceDetail="invoiceDetail = $event" :data="invoiceDetail" />
        </a-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from '../shared/Loader.vue';
import { EyeOutlined } from '@ant-design/icons-vue';
import InvoiceDetail from '../invoice/InvoiceDetail.vue';

export default {
  props: ["data"],
  data() {
    return {
      visibleDetail: false,
      invoiceDetail: {},
      visibleDetailLoad: true,
      currency: "",
      invoiceLoad: true,
      invoicesInfo: [
        {
          title: this.$t("Invoice Quantity"),
          value: "0"
        },
        {
          title: this.$t("Sell Quantity"),
          value: "0"
        },
        {
          title: this.$t("Sell Amount"),
          value: "0"
        },
        {
          title: this.$t("Return Quantity"),
          value: "0"
        },
        {
          title: this.$t("Return Amount"),
          value: "0"
        },
        {
          title: this.$t("Paid Invoice Quantity"),
          value: "0"
        },
        {
          title: this.$t("Paid Invoice Amount"),
          value: "0"
        },
        {
          title: this.$t("No Paid Invoice Quantity"),
          value: "0"
        },
        {
          title: this.$t("No Paid Invoice Amount"),
          value: "0"
        },
      ],
      columns: [
        {
          title: this.$t("Invoice No"),
          dataIndex: "invoice_no",
          key: "invoice_no",
        },
        {
          title: this.$t("Branch"),
          dataIndex: "branch_id",
          key: "branch_id",
        },
        {
          title: this.$t("Customer"),
          dataIndex: "customer_id.name",
          key: "customer_id",
        },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "products",
        },
        {
          title: this.$t("Amount"),
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: this.$t("Date"),
          dataIndex: "invoice_date",
          key: "invoice_date",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      invoiceData: [],
    };
  },
  async mounted() {
    this.currency = await JSON.parse(localStorage.getItem("user")).firm.currency;
    await this.getInvoices();
  },
  methods: {
    async showDrawer(id) {
      this.invoiceDetail = await {};
      await axios
        .get(`/api/invoice/${id}`)
        .then((result) => {
          this.visibleDetail = true;
          this.invoiceDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.invoiceDetail = {};
        this.visibleDetailLoad = false;
        this.getInvoices()
      }
    },
    async getInvoices() {
      this.invoiceLoad = await true;
      this.invoiceData = await [];
      await axios
        .get(`/api/customer/invoice/${this.data._id}/${this.currency}`)
        .then(async (result) => {
          this.invoicesInfo = await [
            {
              title: this.$t("Invoice Quantity"),
              value: result.data.invoicesQty
            },
            {
              title: this.$t("Sell Quantity"),
              value: result.data.totalSellQuantity
            },
            {
              title: this.$t("Sell Amount"),
              value: `${result.data.currency}${this.moneyFormat(result.data.totalSellAmount).substr(1)}`
            },
            {
              title: this.$t("Return Quantity"),
              value: result.data.totalReturnQuantity
            },
            {
              title: this.$t("Return Amount"),
              value: `${result.data.currency}${this.moneyFormat(result.data.totalReturnAmount).substr(1)}`
            },
            {
              title: this.$t("Paid Invoice Quantity"),
              value: result.data.totalPaidQuantity
            },
            {
              title: this.$t("Paid Invoice Amount"),
              value: `${result.data.currency}${this.moneyFormat(result.data.totalPaidAmount).substr(1)}`
            },
            {
              title: this.$t("No Paid Invoice Quantity"),
              value: result.data.totalNoPaidQuantity
            },
            {
              title: this.$t("No Paid Invoice Amount"),
              value: `${result.data.currency}${this.moneyFormat(result.data.totalNoPaidAmount).substr(1)}`
            },
          ];
          this.invoiceData = await result.data.invoices
          this.invoiceLoad = await false;
        })
        .catch((err) => {
          this.sellAndReturnLoad = false;
          console.log("err", err);
        });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: { Loader, EyeOutlined, InvoiceDetail }
};
</script>

<style lang="scss" scoped></style>
