<template>
  <a-layout-header
    style="
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
    "
  >
    <div class="">
      <menu-unfold-outlined
        v-if="collapsed"
        class="trigger"
        @click="() => (collapsed = !collapsed)"
      />
      <menu-fold-outlined
        v-else
        class="trigger"
        @click="() => (collapsed = !collapsed)"
      />
    </div>
    <div class="" style="display: flex; gap: 15px">
      <div class="money">
        <a-dropdown :trigger="['click']">
          <euro-outlined style="cursor: pointer" />
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <span>Dolar: 18.4₺</span>
              </a-menu-item>
              <a-menu-item key="1">
                <span>Euro: 19.4₺</span>
              </a-menu-item>
              <a-menu-item key="1">
                <span>Pound: 21.4₺</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="lang">
        <a-dropdown :trigger="['click']">
          <global-outlined style="cursor: pointer" />
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <a href="http://www.alipay.com/">1st menu item</a>
              </a-menu-item>
              <a-menu-item key="1">
                <a href="http://www.taobao.com/">2nd menu item</a>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">3rd menu item</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="alert">
        <a-dropdown :trigger="['click']">
          <a-badge dot>
            <bell-outlined style="cursor: pointer" />
          </a-badge>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <a href="http://www.alipay.com/">1st menu item</a>
              </a-menu-item>
              <a-menu-item key="1">
                <a href="http://www.taobao.com/">2nd menu item</a>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">3rd menu item</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="user">
        <a-dropdown :trigger="['click']">
          <a-avatar :size="36" style="cursor: pointer">
            <template #icon><UserOutlined /></template>
          </a-avatar>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <UserOutlined />
                <router-link to="/account" style="margin-left: 5px">Account</router-link>
              </a-menu-item>
              <a-menu-item key="1" @click="logout()">
                <arrow-left-outlined style="color: red" />
                <span style="margin-left: 5px; color: red">Logout</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  GlobalOutlined,
  HomeOutlined,
  EuroOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
export default {
  components: {
    UserOutlined,
    EuroOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BellOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    ArrowLeftOutlined,
    GlobalOutlined,
  },
  methods: {
    async logout() {
      await localStorage.removeItem("token");
      await localStorage.removeItem("user");
      await this.$router.push("/");
      await notification.success({
        message: this.$t("Success"),
        description: this.$t("logged out"),
        duration:1.5
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss" scoped></style>
