<template>
  <div class="">
    <PageTitle :title="$t('Stocks')" />
    <StockFilter @itemFilter="data = $event" :loader="loader" @newLoad="loader = $event" />
    <a-layout-content :style="{
      margin: '15px 16px',
      padding: '12px',
      background: '#fff',
    }">
      <div>
        <a-row v-if="!totalLoad">
          <a-col style="margin: 20px;">
            <span>{{ $t('Total Product Quantity') }}: <b>{{ total.totalQuantity }}</b> {{ $t('qty') }}</span>
          </a-col>
          <a-col v-for="(item, index) in total.branches" :key="index" style="margin: 20px;">
            <span>{{ item.branch }}: <b>{{ item.quantity }}</b> {{ $t('qty') }}</span>
          </a-col>
        </a-row>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div>
        <a-row v-if="!totalInvestLoad">
          <a-col style="margin: 20px;">
            <span>{{ $t('Total Investment') }}: <b>{{ totalInvest < 0 ? `-${user.firm.currency}` +
              moneyFormat(totalInvest * -1).substr(1) : `${user.firm.currency}` +
              moneyFormat(totalInvest).substr(1) }}</b></span>
          </a-col>
          <a-col v-for="(item, index) in investes" :key="index" style="margin: 20px;">
            <span>{{ item.name }}: <b>{{ item.totalInvestment < 0 ? `-${user.firm.currency}` +
              moneyFormat(item.totalInvestment * -1).substr(1) : `${user.firm.currency}` +
              moneyFormat(item.totalInvestment).substr(1) }}</b></span>
          </a-col>
        </a-row>
        <div v-else>
          <Loader />
        </div>
      </div>
    </a-layout-content>
    <a-layout-content :style="{
      margin: '0px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px',
    }">
      <a-table :scroll="{ x: 1000 }" v-if="loader" :columns="columns" :data-source="data" @change="onChange">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key == 'sku'">
            <a-tooltip>
              <template #title>{{ record.name }}</template>
              {{ record.sku }}
            </a-tooltip>
          </template>
          <template v-if="column.key == 'value'">
            <span v-if="text < 0"><b style="color: #CF1322">{{ text }}</b></span>
          </template>
        </template>
      </a-table>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import Loader from "@/components/shared/Loader.vue";
import StockFilter from "@/components/stock/StockFilter.vue";
import axios from "axios";

export default {
  components: { PageTitle, Loader, StockFilter },
  data() {
    return {
      loader: false,
      columns: [
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
        },
      ],
      data: [],
      branchs: [],
      total: {},
      totalLoad: true,
      investes: [],
      totalInvest: null,
      totalInvestLoad: true,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.getData();
    this.getTotal();
    this.getInvest();
  },
  methods: {
    async getData() {
      await axios
        .get("/api/stock/all")
        .then(async (result) => {
          result.data.branch.forEach((b) => {
            this.columns.push({
              title: b.name,
              dataIndex: b.name,
              key: "value",
            });
          });
          var dataObject = {};
          result.data.stock.forEach((s, key1) => {
            result.data.branch.forEach((b, key2) => {
              var branchName = b.name;
              var check = s.branchs.find((r) => r.name == branchName);

              if (check) {
                dataObject[branchName] = check.quantity; // branchName key olarak, check.quantity value olarak atanıyor
              } else {
                dataObject[branchName] = 0; // Eğer check undefined ise, branchName key olarak atanıyor ve value olarak 0 atanıyor
              }
            });
            this.data.push({
              sku: s.sku,
              name: s.name,
              ...dataObject,
            });
          });
          this.loader = true;
        })
        .catch((err) => { });
    },
    async getTotal() {
      axios.get('/api/stock/total').then((result) => {
        this.total = result.data
        this.totalLoad = false
      }).catch((err) => {

      });
    },
    async getInvest() {
      axios.get('/api/stock/total-purchase').then((result) => {
        this.investes = result.data
        result.data.forEach(e => {
          this.totalInvest += e.totalInvestment
        });
        this.totalInvestLoad = false
      }).catch((err) => {

      });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
