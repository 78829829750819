<template>
    <div>
      <div class="">
        <div
          class=""
          style="display: flex; justify-content: end; align-items: center; gap: 15px"
        >
          <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
        </div>
        <LinePlot v-if="loading" :config="config" :chartRef="(ref) => (LineRef = ref)" />
        <Loader v-else />
      </div>
    </div>
  </template>

  <script>
  import axios from "axios";
  import { LinePlot } from "ant-design-charts-vue";
  import Loader from "@/components/shared/Loader.vue";
  export default {
    components: { LinePlot, Loader },
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        form: {},
        filterText: "",
        loading: false,
        config: {
          data: [],
          color: ["#5D90F7", "#cf1322"],
          height: 350,
          xField: "date",
          yField: "price",
          seriesField: "type",
          smooth: true,
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.loading = false;
        this.filterText = "";
        if (this.form.date) {
          this.filterText =
            this.filterText +
            `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
        }

        axios
          .get(`/api/income-reports/current?${this.filterText}`)
          .then((result) => {
            console.log(result.data);
            this.config.data = result.data;
            this.loading = true;
          })
          .catch((err) => {});
      },
    },
  };
  </script>

  <style lang="scss" scoped></style>
