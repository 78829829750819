<template>
  <div>
    <a-card :loading="load" style="width: 100%">
      <a-card-meta style="margin-bottom: 15px" :title="$t('Monthly Report')">
        <template #description>{{$t('Last 6 months sales and returns graph')}}</template>
      </a-card-meta>
      <LinePlot
        v-if="last6MonthLoad"
        :config="Last6Month"
        :chartRef="(ref) => (LineRef = ref)"
      />
      <Loader v-else />
    </a-card>
  </div>
</template>

<script>
import { LinePlot } from "ant-design-charts-vue";
import Last7Day from "@/components/home/Last7Day.vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
export default {
  components: {
    LinePlot,
    Last7Day,
    Loader,
  },

  data() {
    return {
        last6MonthLoad: false,
      Last6Month: {
        data: [],
        height: 350,
        xField: "date",
        yField: "count",
        seriesField: "type",
        smooth: true,
        color: ["#5D90F7", "#cf1322"],
      },
      load:true
    };
  },
  watch: {
    // whenever question changes, this function will run
    last6MonthLoad(newData, oldData) {
      this.load = false;
    },
  },
  created() {
    this.getLast6Month();
  },
  methods: {
     async getLast6Month() {
      axios
        .get("/api/statistic/last-6-month")
        .then((result) => {
          console.log(result);
          this.Last6Month.data = result.data;
          this.last6MonthLoad = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
