<template>
  <div>
    <a-button type="primary" @click="showModal"><PlusOutlined /><span>{{$t('Create')}}</span></a-button>
    <a-modal :mask-closable="false" v-model:visible="visible" :title="$t('New Branch Create')" :footer="null">
      <a-form layout="vertical">
        <a-form-item :label="$t('Branch Name')">
          <a-input v-model:value="form.name" :placeholder="$t('Branch Name')" />
        </a-form-item>
        <a-form-item :label="$t('Phone')">
          <a-input v-model:value="form.phone" :placeholder="$t('Phone')" />
        </a-form-item>
        <a-form-item :label="$t('Address')">
          <a-input v-model:value="form.address" :placeholder="$t('Address')" />
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end; align-items: center">
            <a-button @click="() => (visible = false)">{{$t('Cancel')}}</a-button>
            <a-button :loading="loading"  style="margin-left: 5px" type="primary" @click="handleOk()"
              >{{$t('Create')}}</a-button
            >
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  props: ["getData"],
  components: { PlusOutlined },
  data() {
    return {
      visible: false,
      form: {},
      loading:false
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async handleOk() {
      this.loading=true
      await axios
      .post("/api/branch/add", this.form)
      .then(async (result) => {
        this.loading=false
        await notification.success({
          message: this.$t("Success"),
          description: this.$t("Transaction Successfully Performed"),
          duration: 1.5,
        });
        this.visible = false;
        this.form = {};
        this.getData();
      })
      .catch(async (err) => {
        console.log();
          this.loading=false
          await notification.error({
            message: this.$t("Error"),
            description:this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
