<template>
  <div>
    <div
      class=""
      style="
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 15px;
      "
    >
      <a-range-picker
        v-model:value="form.date"
        @change="getData()"
        format="DD/MM/YY"
      />
    </div>
    <a-list
      v-if="loading"
      size="small"
      bordered
      :data-source="data"
      style="margin-top: 15px; width: 100%"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <template #actions>
            <a-button
              :loading="detailLoadBtn"
              type="primary"
              @click="getProductDetail(item.sku)"
              ><span style="cursor: pointer">{{
                `${item.totalSell}  ${$t("qty")}`
              }}</span></a-button
            >
          </template>
          <a-list-item-meta>
            <template #title>
              <h4>{{ item.sku }}</h4>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
      <a-drawer
        v-if="visibleDetailLoad"
        :title="`${productDetail.name}`"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <ProductDetail
          :showDrawer="getProductDetail"
          :sellAndRetuns="sellAndRetuns"
          :detailStock="detailStock"
          :data="productDetail"
          :sizes="sizes"
          :colors="colors"
        />
      </a-drawer>
    </a-list>
    <Loader v-else />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";

export default {
  data() {
    return {
      data: [],
      filterText: "",
      form: {},
      loading: false,
      productDetail: {},
      visibleDetailLoad: false,
      visibleDetail: false,
      detailStock: {
        columns: [],
        data: [],
      },
      sellAndRetuns: [],
      sizes: [],
      colors: [],
      detailLoadBtn: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      axios
        .get(`/api/product-report/best-products?${this.filterText}`)
        .then((result) => {
          this.data = result.data;
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductDetail(sku) {
      this.detailLoadBtn = true;
      this.productDetail = {};
      this.visibleDetail = true;
      this.detailStock = {
        columns: [],
        data: [],
      };
      axios
        .get(`/api/product/sku/${sku}`)
        .then((res) => {
          axios
            .get(`/api/product/${res.data._id}`)
            .then(async (result) => {
              console.log(result.data);
              this.sizes = [];
              this.colors = [];
              this.productDetail = result.data.data;

              await result.data.branch.forEach((b) => {
                this.detailStock.columns.push({
                  title: b.name,
                  dataIndex: b.name,
                  key: "value",
                });
              });
              var dataObject = {};
              await result.data.stock.forEach((s, key1) => {
                result.data.branch.forEach((b, key2) => {
                  var branchName = b.name;
                  var check = s.branchs.find((r) => r.name == branchName);

                  if (check) {
                    dataObject[branchName] = check.quantity; // branchName key olarak, check.quantity value olarak atanıyor
                  } else {
                    dataObject[branchName] = 0; // Eğer check undefined ise, branchName key olarak atanıyor ve value olarak 0 atanıyor
                  }
                });
                this.detailStock.data.push({
                  sku: s.sku,
                  ...dataObject,
                });
              });
              this.sellAndRetuns = await [
                {
                  title: this.$t("Total Sell Quantity"),
                  value: result.data.sell.totalQuantity,
                },
                {
                  title: this.$t("Total Sell Amount"),
                  value: result.data.sell.totalPrice,
                },
                {
                  title: this.$t("Total Return Quantity"),
                  value: result.data.return.totalQuantity,
                },
                {
                  title: this.$t("Total Return Amount"),
                  value: result.data.return.totalPrice,
                },
              ];

              this.visibleDetailLoad = true;
              this.detailLoadBtn = false;
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.productDetail = {};
        this.visibleDetailLoad = false;
      }
    },
  },
  components: { Loader, ProductDetail },
};
</script>

<style lang="scss" scoped></style>
