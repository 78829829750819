<template>
  <FreeTrial :user="user" />

  <div :style="{
    margin: '24px 16px',
    padding: '24px',
    minHeight: '280px',
  }">
    <div class="start-tour">
      <a-button id="start-tour" @click="startTour(0)">{{ $t("Start tour") }}</a-button>
    </div>
    <HomeCards id="home-cards" />
    <a-row id="data-c" :gutter="[8, 0]" style="
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
      <a-col :xs="24" :xl="12" style="margin-top: 15px">
        <Last7Day />
      </a-col>
      <a-col :xs="24" :xl="12" style="margin-top: 15px">
        <Last6Month />
      </a-col>
    </a-row>
    <a-divider v-if="user.role.invoice != false" />
    <LastInvoices id="last-invoices" v-if="user.role.invoice != false" :columns="columns" :data="data"
      :lastInvocieLoader="lastInvocieLoader" />
    <a-divider v-if="user.role.income != false" />
    <LastIncome id="last-invoices" v-if="user.role.income != false" :columns="incomeCols" :data="incomeData"
      :lastIncomeLoader="lastIncomeLoader" />
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

import LastInvoices from "@/components/home/LastInvoices.vue";
import Last7Day from "@/components/home/Last7Day.vue";
import Last6Month from "@/components/home/Last6Month.vue";
import axios from "axios";
import HomeCards from "@/components/home/HomeCards.vue";
import Loader from "@/components/shared/Loader.vue";
import FreeTrial from "@/components/shared/FreeTrial.vue";
import GuideChimp from "guidechimp";
import "guidechimp/dist/guidechimp.min.css";
import DailySaleAmount from "@/components/reports/saleReports/DailySaleAmount.vue";
import LastIncome from "@/components/home/LastIncome.vue";
export default {
  components: {
    Last7Day,
    Last6Month,
    LastInvoices,
    HomeCards,
    Loader,
    FreeTrial,
    barcode: VueBarcode,
    DailySaleAmount,
    LastIncome
  },
  data() {
    return {
      barcodeValue: "",
      LineRef: null,
      tour: [
        {
          title: this.$t("Welcome to Texcofa"),
          description: this.$t(
            "We'll take a little tour of how you can use the panel. This tour is for you to easily find what you are looking for in the future."
          ),
        },
        {
          element: "#home-cards",
          title: this.$t("Sales and Returns Turnovers"),
          description: this.$t(
            "This is the area where you see your daily and monthly sales and returns turnovers."
          ),
        },
        {
          element: "#data-c",
          title: this.$t("Sales and Returns Charts"),
          description: this.$t(
            "In this section, you can see the number of sales and returns you have made in graphics."
          ),
        },
        {
          element: "#last-invoices",
          title: this.$t("Last Invoices"),
          description: this.$t('In this section, you will see the last sales and returns you have made.'),
        },
        {
          title: this.$t("Your Homepage Like This"),
          description: this.$t("To use the panel, you first need to add some data."),
        },
        {
          element: "#branch-side",
          title: this.$t("Branches"),
          description: this.$t(
            "First you need to add a branch. Remember, all transactions take place through branches."
          ),
        },
        {
          element: "#customer-side",
          title: this.$t("Customers"),
          description: this.$t(
            "Then you need to add your customers. Sales transactions are carried out through customers"
          ),
        },
        {
          element: "#category-side",
          title: this.$t("Categories"),
          description: this.$t(
            "You can set the categories of the products here"
          ),
        },
        {
          element: "#product-side",
          title: this.$t("Products"),
          description: this.$t(
            "Great! You can add a product now. You can use the products you add for transactions such as invoices, transfers, internal transactions."
          ),
        },
        {
          element: "#internal-side",
          title: this.$t("Internals & Transfers"),
          description: this.$t(
            "You can distribute the product cards you created from the Internal Transactions and Transfers section to the branches or make transfers between the branches."
          ),
        },
        {
          element: "#invoice-side",
          title: this.$t("Invoices"),
          description:
            this.$t("You can perform your sales and returns transactions from the 'Invoices' section."),
        },
        {
          element: "#stock-side",
          title: this.$t("Stocks"),
          description:
            this.$t("You can control the stock of your branches from the 'Stocks' section after the Sales and Returns transactions."),
        },
        {
          element: "#user-side",
          title: this.$t("Users"),
          description:
            this.$t("From the 'Users' section, you can define the departments of your business and assign employees to these departments. You can set the authorization restrictions of your employees in the 'Roles' section."),
        },
        {
          element: "#report-side",
          title: this.$t("Reports"),
          description:
            this.$t("You can visualize and examine all the transactions you perform on the panel from the 'Reports' section."),
        },
        {
          element: "#fast-sell",
          title: this.$t("Fast Sell"),
          description:
            this.$t("You can use the 'Fast Sale' section for your stores. This section allows you to sell or return quickly."),
        },
        {
          element: "#start-tour",
          title: this.$t("Educational Tour"),
          description:
            this.$t("If you want to watch the training tour again, simply press the 'Start Tour' button."),
        },
        {
          title: this.$t("That's all for now"),
          description:
            this.$t("If there is a part that is on your mind or you do not understand, you can call our 24/7 support line. We wish you a good work"),
        },
      ],
      user: JSON.parse(localStorage.getItem("user")),
      columns: [
        {
          title: this.$t("Invoice No"),
          dataIndex: "invoice_no",
          key: "invoice_no",
        },
        {
          title: this.$t("Branch"),
          dataIndex: "branch_id",
          key: "branch_id",
        },
        {
          title: this.$t("Customer"),
          dataIndex: "customer_id.name",
          key: "customer_id",
        },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "products",
        },
        {
          title: this.$t("Amount"),
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: this.$t("Date"),
          dataIndex: "invoice_date",
          key: "invoice_date",
        },
        // {
        //   title: this.$t("Action"),
        //   dataIndex: "_id",
        //   key: "btn",
        // },
      ],
      data: [],
      incomeCols: [
        {
          title: this.$t("NO"),
          dataIndex: "income_no",
          key: "income_no",
        },
        {
          title: this.$t("Title"),
          dataIndex: "title",
          key: "title",
        },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Price"),
          dataIndex: "price",
          key: "price",
        },
        {
          title: this.$t("Date"),
          dataIndex: "date",
          key: "date",
        }
      ],
      incomeData: [],
      lastInvocieLoader: false,
      lastIncomeLoader: false,
      tourStep: 0,
    };
  },
  mounted() {
    var tour = localStorage.getItem("tour");
    if (tour) {
      if (tour == "false") {
        if (window.innerWidth >= 901) {
          this.startTour(0);
        }
      }
    } else {
      localStorage.setItem("tour", false);
      if (window.innerWidth >= 901) {
        this.startTour(0);
      }
    }
  },
  created() {
    this.getLastInvoices();
    this.getLastIncome();
  },
  methods: {
    startTour(step) {
      const guidechimp = GuideChimp(this.tour);
      guidechimp.start(step);
      localStorage.setItem("tour", true);
    },
    async getLastInvoices() {
      await axios
        .get("/api/invoice/last")
        .then((result) => {
          this.data = result.data;
          this.lastInvocieLoader = true;
        })
        .catch((err) => { });
    },
    async getLastIncome() {
      await axios
        .get("/api/income/last")
        .then((result) => {
          this.incomeData = result.data;
          this.lastIncomeLoader = true;
        })
        .catch((err) => { });
    },
  },
};
</script>

<style scoped>
.start-tour {
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 995px) {
  .start-tour {
    display: none;
  }
}
</style>
