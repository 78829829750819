<template>
  <div>
    <div class="" style="
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  padding: 0px 15px;
                                ">
      <PageTitle :title="$t('Fast Sell')" />
    </div>
    <div class="">
      <a-row style="width: 100%">
        <a-col :xl="10" style="margin-top: 15px;">
          <a-layout-content :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
          }">
            <a-row style="gap: 15px">
              <a-col>
                <a-select style="width: 200px;" v-model:value="form.customer_id" :placeholder="$t('Select Customer')"
                  :disabled="this.form.customer_id && this.form.branch_id">
                  <a-select-option v-for="(item, index) in customers" :key="index" :value="item._id">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select style="width: 200px;" v-model:value="form.branch_id" @change="branch_id = form.branch_id"
                  :disabled="this.form.customer_id && this.form.branch_id" :placeholder="$t('Select Branch')">
                  <a-select-option v-for="(item, index) in branches" :key="index" :value="item._id">{{ item.name
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select v-model:value="form.type" placeholder="Select Type"
                  :disabled="this.form.customer_id && this.form.branch_id">
                  <a-select-option value="sell">{{ $t("Sell") }}</a-select-option>
                  <a-select-option value="return">{{ $t("Return") }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select style="width: 150px;" @change="() => {
                  if (user.firm.currency == '₺') {
                    form.currencyRate = 0
                  }
                }" v-model:value="user.firm.currency" :placeholder="$t('Currency')"
                  :disabled="this.form.customer_id && this.form.branch_id">
                  <a-select-option :value="'$'">$ Dollar</a-select-option>
                  <a-select-option :value="'€'">€ Euro</a-select-option>
                  <a-select-option :value="'₺'">₺ Lira</a-select-option>
                  <a-select-option :value="'₼'">₼ Manat</a-select-option>
                  <a-select-option :value="'£'">£ Pound</a-select-option>
                  <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                  <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                  <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                  <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                  <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                  <a-select-option :value="'SR'">SR Riyal</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-form layout="horizontal">
                  <a-form-item v-if="user.firm.currency != '₺'" :label="$t('Currency Rate')">
                    <a-input type="number" min="0" v-model:value="form.currencyRate" :placeholder="$t('Currency Rate')" />
                  </a-form-item>
                </a-form>
              </a-col>
            </a-row>
            <a-row style="margin-top: 15px" v-if="this.form.customer_id && this.form.branch_id">
              <a-col>
                <div class="" style="display: flex;justify-content: start;align-items: center;gap: 15px;">
                  <a-input @input="getPosProduct()" v-model:value="filterSku" :placeholder="$t('Product Sku')" />
                  <a-button v-if="!isScaningBarcode" @click="scan">{{ $t('Scan Barcode') }}</a-button>
                  <a-button v-else type="danger" @click="() => {
                    isScaningBarcode = false
                  }">{{ $t('Stop Scanning Barcode') }}</a-button>
                  <a-input style="opacity: 0;" id="barcode-scanner" v-model:value="scannedBarcode"
                    :placeholder="$t('SKU')" @keyup.lazy.enter="ScannerRead()" />
                  <!-- <a-modal v-model:visible="scanBarcodeVisible" :title="$t('Scan Barcode')" @cancel="() => {
                    scanBarcodeVisible = false
                    isScaningBarcode = false
                  }" @ok="handleOkScanBarcode" :footer="null">
                    <a-tabs v-model:activeKey="activeKey">
                      <a-tab-pane key="1" :tab="$t('Barcode Scanner')">
                        <div class="" v-if="!isScaningBarcode">
                          <a-button type="primary" @click="scan()">{{ $t('Read Barcode') }}</a-button>
                        </div>
                        <div class="" v-else>
                          <a-button type="danger" @click="() => {
                            scanBarcodeVisible = false
                            isScaningBarcode = false
                          }">{{ $t('Stop Scanning Barcode') }}</a-button>
                        </div>
                    
                      </a-tab-pane>
                      <a-tab-pane key="2" :tab="$t('Camera')">
                        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                      </a-tab-pane>
                    </a-tabs>
                  </a-modal> -->
                </div>
              </a-col>
            </a-row>
            <a-divider />
            <div
              v-if="(user.firm.currency != '₺' && form.currencyRate > 0) || (user.firm.currency == '₺' && form.currencyRate == 0)">
              <a-row v-if="this.form.customer_id && this.form.branch_id" style="
                                          display: flex;
                                          gap: 25px;
                                          max-height: 375px;
                                          overflow-y: scroll;
                                          padding: 10px;
                                        ">
                <a-col v-for="(item, index) in defaultProducts" :key="index" :lg="11">
                  <div class="product">
                    <a-card @click="addCart(item)" style="
                                                cursor: pointer;
                                                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                                              ">
                      <div class="sku" style="display: flex; justify-content: start">
                        <b>{{ item.sku }}</b>
                      </div>
                      <div class="price" style="display: flex; justify-content: start">
                        <b style="color: rgba(0, 0, 0, 0.5)">{{
                          `${user.firm.currency}${moneyFormat(item.price).substr(1)}`
                        }}</b>
                      </div>
                    </a-card>
                  </div>
                </a-col>
              </a-row>
              <a-row v-else>
                <h3>
                  <b>{{ $t("Please Select Branch and Customer") }}</b>
                </h3>
              </a-row>
            </div>
            <a-row v-else>
              <h3>
                <b>{{ $t("Exchange rate cannot be 0") }}</b>
              </h3>
            </a-row>
          </a-layout-content>
        </a-col>
        <a-col :xl="14" style="margin-top: 15px;">
          <a-layout-content v-if="cartProducts.length > 0" :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
            maxHeight: '400px',
            overflowY: 'scroll',
          }">
            <a-row v-for="(item, index) in cartProducts" :key="index" style="
                                          display: flex;
                                          justify-content: space-between;
                                          align-items: end;

                                        ">
              <a-col :xs="24" :md="4" style="padding: 0 10px;">
                <div class="sku" style="width: 100%;overflow-x: scroll;">
                  <div class="">
                    <span style="width: 50%"> {{ item.sku }}</span>
                  </div>
                </div>
              </a-col>
              <a-col :xs="24" :md="3">
                <div class="quantity" style="width: 100%">
                  <label for="" style="font-size: 12px; color: #959595">{{
                    $t("Quantity")
                  }}</label>
                  <a-input v-model:value="item.quantity" type="number" :placeholer="$t('Quantity')" style="width: 100%" />
                </div>
              </a-col>
              <a-col :xs="24" :md="3">
                <div>
                  <div class="tax_price" style="width: 100%">
                    <label for="" style="font-size: 12px; color: #959595">{{
                      $t("No Tax Price")
                    }}</label>
                    <a-input v-model:value="item.no_tax_price" type="number" :placeholer="$t('No Tax Price')"
                      @change="handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)" style="width: 100%" />
                  </div>
                </div>
              </a-col>
              <a-col :xs="24" :md="3">
                <div class="tax" style="width: 100%;margin:0px 0 -25px 0">
                  <label for="" style="font-size: 12px; color: #959595">{{
                    $t("Tax")
                  }}</label>
                  <a-form-item>
                    <a-select v-model:value="item.tax" :placeholder="$t('Tax')"
                      @change="handleTax(item.id, item.no_tax_price, item.tax, item.price)">
                      <a-select-option :value="0">%0</a-select-option>
                      <a-select-option :value="8">%8</a-select-option>
                      <a-select-option :value="10">%10</a-select-option>
                      <a-select-option :value="18">%18</a-select-option>
                      <a-select-option :value="20">%20</a-select-option>
                      <a-select-option :value="24">%24</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </a-col>
              <a-col :xs="24" :md="3">
                <div class="price" style="width: 100%">
                  <label for="" style="font-size: 12px; color: #959595">{{
                    $t("Price")
                  }}</label>
                  <a-input @change="handlePrice(item.id, item.no_tax_price, item.tax, item.price)"
                    v-model:value="item.price" type="number" :placeholer="$t('Price')" style="width: 100%" />
                </div>
              </a-col>
              <a-col style="margin-bottom: 10px;" :xs="24" :md="3">
                <div class="total" style="width: 50%">
                  <span><b>{{
                    `${user.firm.currency}${moneyFormat(
                      item.price * item.quantity
                    ).substr(1)}`
                  }}</b></span>
                </div>
              </a-col>
              <a-col :xs="24" :md="2">
                <div class="" style="
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    gap: 5px;
                                                  ">
                  <a-dropdown v-if="!item.isDiscount">
                    <EllipsisOutlined />
                    <template #overlay>
                      <a-menu>
                        <a-menu-item @click="addProductDiscount(item.id)">
                          <div>{{ $t('Add Discount') }}</div>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </a-col>
              <a-col :xs="24" :md="2">
                <div class="btn" style="width: 50%">
                  <a-button @click="remove(item.id)">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
              <div v-if="item.isDiscount" style="margin-top: 5px;">
                <label for="" style="font-size: 12px; color: #959595">{{
                  $t("Discount")
                }}</label>
                <div style="display: flex;justify-content: center;align-items: center;gap: 10px;">
                  <div class="" style="display: flex;justify-content: center;align-items: center;">
                    <a-input type="number" min="0" @change="handleDiscount(item)" style="width: 100%"
                      v-model:value="item.discount" :placeholder="$t('Discount')" />
                    <a-select @change="handleDiscount(item)" style="width: 100%" v-model:value="item.discountType"
                      :placeholder="$t('Discount Type')">
                      <a-select-option value="%">%</a-select-option>
                      <a-select-option value="$">{{ user.firm.currency }}</a-select-option>
                    </a-select>
                  </div>
                  <div>
                    <CloseOutlined @click="removeProductDiscount(item.id)" />
                  </div>
                </div>
              </div>
            </a-row>
          </a-layout-content>
          <a-layout-content v-else :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
          }">
            <h3>
              <b>{{ $t("There are no items in the cart") }}</b>
            </h3>
          </a-layout-content>

          <a-layout-content v-if="cartProducts.length > 0" :style="{
            margin: '10px 16px 0 16px',
            padding: '24px',
            background: '#fff',
          }">
            <a-row style="gap: 15px">
              <!-- <a-col>
                <a-select v-model:value="form.tax" :placeholder="$t('Tax')">
                  <a-select-option :value="0">%0</a-select-option>
                  <a-select-option :value="18">%18</a-select-option>
                  <a-select-option :value="8">%8</a-select-option>
                  <a-select-option :value="24">%24</a-select-option>
                </a-select>
              </a-col> -->
              <a-col>
                <label style="font-size: 12px; color: #959595">{{ $t('Discount') }}</label>
                <div class="" style="display: flex; justify-content: start">
                  <a-input style="width: 80%" v-model:value="form.discount" :placeholder="$t('Discount')" />
                  <a-select style="width: 50%" v-model:value="form.discountType" :placeholder="$t('Discount Type')">
                    <a-select-option value="%">%</a-select-option>
                    <a-select-option value="$">$</a-select-option>
                  </a-select>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("SubTotal") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(cartTotal()).substr(1)}`
                  }}</b>
                </h3>
              </a-col>
            </a-row>
            <a-row>
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("Tax") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(taxTotal()).substr(1)}`
                  }}</b>
                </h3>
              </a-col>
            </a-row>
            <!-- <a-row v-if="form.tax != 0">
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("Sub Amount") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(
                      cartTotal() + (cartTotal() * form.tax) / 100
                    ).substr(1)}`
                  }}</b>
                </h3>
              </a-col>
            </a-row> -->
            <a-row v-if="discountTotal() > 0">
              <div class="">
                <h3>
                  {{ $t("Discount") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(discountTotal()).substr(1)}`
                  }}</b>
                </h3>
              </div>
            </a-row>
            <a-row v-if="form.discount != 0">
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("Discount") }}:
                  <b>{{
                    form.discountType == "%"
                    ? `-%${form.discount}`
                    : `-${user.firm.currency}${form.discount}`
                  }}</b>
                </h3>
              </a-col>
            </a-row>
            <a-row>
              <a-col style="margin-top: 15px">
                <h3>
                  {{ $t("Total") }}:
                  <b>{{ totalAmount() }}</b>
                </h3>
              </a-col>
            </a-row>
            <a-row v-if="!form.isPaidAll">
              <div class="">
                <h3>
                  {{ $t("Remaining Payment") }}:
                  <b>{{ `${user.firm.currency}${moneyFormat(totalAmountInvoice - form.paidAmount).substr(1)}` }}</b>
                </h3>
              </div>
            </a-row>
            <a-divider v-if="form.type == 'sell'" />
            <div style="display: flex;justify-content: start;align-items: center;width: 100%;gap: 15px;">
              <a-form-item v-if="form.type == 'sell'">
                <a-switch v-model:checked="form.isPaidAll" />
                <label style="margin-left: 15px;" for="">{{ $t('Paid All') }}</label>
              </a-form-item>
              <div v-if="form.type == 'sell'" class="">
                <a-form-item v-if="!form.isPaidAll" :label="$t('Paid Amount')">
                  <div class="" style="display: flex; justify-content: start">
                    <a-input type="number" @change="paidAmountControl()" v-model:value="form.paidAmount"
                      :placeholder="$t('Paid Amount')" />
                  </div>
                </a-form-item>
              </div>
            </div>
            <a-row>
              <div class="" style="display: flex; justify-content: space-between; width: 100%">
                <a-button type="primary" @click="payNowModal()">{{
                  $t("Pay Now")
                }}</a-button>
                <a-button type="danger" @click="resetModal()">{{ $t("Reset") }}</a-button>
              </div>
            </a-row>
          </a-layout-content>
        </a-col>
      </a-row>
    </div>
    <a-modal v-model:visible="payNowVisible" :title="$t('Are you sure you want to make the invoice?')" :footer="null">
      <p>
        {{
          $t("If you encounter any problems, you can edit them in the Invoices section")
        }}
      </p>
      <div class="" style="display: flex; justify-content: end; align-items: center">
        <a-button @click="() => (payNowVisible = false)">{{ $t("Cancel") }}</a-button>
        <a-button :loading="loading" style="margin-left: 5px" type="primary" @click="pay()">{{ $t("Create") }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model:visible="resetVisible" :title="$t('Are you sure?')" :footer="null">
      <p>{{ $t("The products you add to the cart will be deleted") }}</p>
      <div class="" style="display: flex; justify-content: end; align-items: center">
        <a-button @click="() => (resetVisible = false)">{{ $t("Cancel") }}</a-button>
        <a-button :loading="loading" style="margin-left: 5px" type="danger" @click="resetCartProduct()">{{ $t("Reset")
        }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { DeleteOutlined, EllipsisOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Alert from "@/assets/voices/beep.mp3";
import Wrong from "@/assets/voices/wrong.mp3";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: { PageTitle, DeleteOutlined, StreamBarcodeReader, EllipsisOutlined, CloseOutlined },
  data() {
    return {
      activeKey: "1",
      payNowVisible: false,
      resetVisible: false,
      totalAmountInvoice: 0,
      form: {
        tax: 0,
        discount: 0,
        discountType: "%",
        type: "sell",
        customer_id: '',
        isPaidAll: true,
        paidAmount: 0,
        currencyRate: 0,
      },
      customer_id: '',
      branch_id: '',
      user: JSON.parse(localStorage.getItem("user")),
      data: [],
      defaultProducts: [],
      cartProducts: [],
      customers: [],
      branches: [],
      loading: false,
      filterSku: "",
      scanningSku: '',
      scanBarcodeVisible: false,
      scannedBarcode: '',
      isScaningBarcode: false
    };

  },
  mounted() {
    this.getPosProduct();
    this.getCustomer();
    this.getBranch();
  },
  methods: {
    async handleDiscount(item) {
      const discount = parseFloat(item.discount);
      const noTaxPrice = parseFloat(item.no_tax_price);
      const quantity = parseInt(item.quantity);
      const discountType = item.discountType;

      if (discountType === '%' && (discount < 0 || discount > 100)) {
        item.discount = await 0
        await this.handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)
        return;
      } else if (discountType === '$' && discount > noTaxPrice) {
        item.discount = await 0
        await this.handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)
        return;
      } else if (discount < 0) {
        // Indirim değeri negatif olamaz.
        return;
      }

      let totalPrice = noTaxPrice * 1;

      if (discountType === '%') {
        const discountAmount = (totalPrice * discount) / 100;
        totalPrice -= discountAmount;
      } else if (discountType === '$') {
        totalPrice -= discount * 1;
      }

      var newDiscountNoTaxPrice = totalPrice.toFixed(2);

      const formattedTotalPrice = `${this.user.firm.currency}${this.moneyFormat(totalPrice).substr(1)}`;

      var newPrice = (Number(newDiscountNoTaxPrice) + (Number(newDiscountNoTaxPrice) * Number(item.tax) / 100)).toFixed(2)
      item.price = newPrice
    },
    addProductDiscount(id) {
      const check = this.cartProducts.find(e => e.id == id)
      check.isDiscount = true
    },
    removeProductDiscount(id) {
      const check = this.cartProducts.find(e => e.id == id)
      check.discount = 0
      check.isDiscount = false
      this.handleDiscount(check)
    },
    ScannerRead() {
      this.onDecode(this.scannedBarcode)
      this.scannedBarcode = ''
    },
    onDecode(sku) {
      if (sku == null) {
        console.log("okutulmuyor");
      } else {
        this.scanningSku = sku
        axios
          .get(`/api/product/sku/${this.scanningSku}`)
          .then((result) => {
            var data = {
              no_tax_sale_price: result.data.no_tax_sale_price,
              name: result.data.name,
              price: result.data.sale_price,
              purchase: Number(result.data.purchase_price),
              tax: result.data.tax,
              sku: result.data.sku,
            }
            this.addCart(data)
            this.scanningSku = ''
          }).catch(async () => {
            await notification.error({
              message: this.$t("Error"),
              description: this.$t("Barcode not found"),
              duration: 1.5,
            });
            this.playSound(Wrong);
          })
      }
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`)
    },
    playSound(url) {
      const audio = new Audio(url);
      audio.play();
    },
    getCustomer() {
      axios
        .get("/api/customer/all")
        .then((result) => {
          this.customers = result.data;

          var check = result.data.find(e => e.type == 'system')
          if (check) {
            this.form.customer_id = check._id
            this.customer_id = check._id
          }
        })
        .catch((err) => { });
    },
    paidAmountControl() {
      if (Number(this.form.paidAmount) > Number(this.totalAmountInvoice)) {
        this.form.paidAmount = this.totalAmountInvoice
      }
    },
    resetCartProduct() {
      this.cartProducts = [];
      this.resetVisible = false;
      this.form = {
        tax: 0,
        discount: 0,
        discountType: "%",
        type: "sell",
        customer_id: '',
        isPaidAll: true,
      },
        this.getCustomer()
      this.branch_id = ''
    },
    getBranch() {
      axios
        .get("/api/branch/all")
        .then((result) => {
          this.branches = result.data;
        })
        .catch((err) => { });
    },
    async pay() {
      this.loading = true;
      var data = {
        branch_id: this.form.branch_id,
        customer_id: this.form.customer_id,
        products: this.cartProducts,
        type: this.form.type,
        invoice_date: new Date(),
        tax: this.form.tax,
        discount: this.form.discount,
        discountType: this.form.discountType,
        currency: this.user.firm.currency,
        isPaidAll: this.form.isPaidAll,
        paidAmount: this.form.paidAmount,
        currencyRate: this.form.currencyRate
      };
      await axios
        .post("/api/invoice/add", data)
        .then(async (result) => {
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.payNowVisible = false;

          this.getPosProduct();
          this.cartProducts = [];
          this.resetVisible = false;
          this.form = {
            tax: 0,
            discount: 0,
            discountType: "%",
            type: "sell",
            customer_id: '',
            isPaidAll: true,
            paidAmount: 0,
            currencyRate: 0
          },
            this.getCustomer()
          this.branch_id = ''
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    payNowModal() {
      this.payNowVisible = true;
    },
    resetModal() {
      this.resetVisible = true;
    },
    getPosProduct() {
      if (this.filterSku.length > 0) {
        axios
          .get(`/api/product/pos-sell?sku=${this.filterSku}`)
          .then((result) => {
            console.log('====================================');
            console.log(result.data);
            console.log('====================================');
            this.defaultProducts = result.data;
          })
          .catch((err) => { });
      } else {
        axios
          .get("/api/product/pos-sell")
          .then((result) => {
            this.defaultProducts = result.data;
          })
          .catch((err) => { });
      }
    },
    addCart(data) {
      const { price, sku, purchase, tax, no_tax_sale_price } = data;
      const check = this.cartProducts.find((e) => e.sku == sku);
      if (check) {
        check.quantity = Number(check.quantity) + 1;
      } else {
        var newProd = {
          id: Math.ceil(Math.random() * 874589),
          sku: sku,
          price: Number(price),
          purchase: Number(Number(purchase).toFixed(2)),
          quantity: Number(1),
          tax: Number(tax),
          no_tax_price: Number(no_tax_sale_price).toFixed(2),
          discount: 0,
          discountType: '%',
          isDiscount: false
        }
        this.cartProducts.push(newProd);
      }
      this.playSound(Alert);
      console.log(this.cartProducts);
    },
    cartTotal() {
      var total = 0;
      this.cartProducts.forEach((e) => {
        total = total + e.no_tax_price * e.quantity;
      });
      return total;
    },
    taxTotal() {
      var total = 0
      this.cartProducts.forEach((e) => {
        var noTaxPrice = e.no_tax_price
        if (e.discountType == '%') {
          noTaxPrice = noTaxPrice - (noTaxPrice * Number(e.discount) / 100)
        } else {
          noTaxPrice -= Number(e.discount)
        }
        total = total + (noTaxPrice * e.tax / 100) * e.quantity;
      });
      return total;
    },
    discountTotal() {
      var total = 0;
      this.cartProducts.forEach((e) => {
        if (e.discountType == '%') {
          total += ((e.no_tax_price * Number(e.discount) / 100) * e.quantity)
        } else {
          total += (Number(e.discount) * e.quantity)
        }
      });
      return (total).toFixed(2);
    },
    totalAmount() {
      var amount = Number(this.cartTotal()) + Number(this.taxTotal()) - Number(this.discountTotal())
      if (this.form.discount > 0) {
        if (this.form.discountType == "%") {
          amount = amount - (amount * this.form.discount / 100)
        } else {
          amount -= this.form.discount
        }
      }
      this.totalAmountInvoice = amount.toFixed(2)
      return `${this.user.firm.currency}${amount.toFixed(2)}`
    },
    remove(id) {
      const check = this.cartProducts.find((e) => e.id == id);
      const index = this.cartProducts.indexOf(check);
      this.cartProducts.splice(index, 1);
      this.playSound(Alert);
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    async showScanBarcodeModal() {
      this.scanBarcodeVisible = await true
    },
    scan() {
      this.isScaningBarcode = true
      const inp = document.getElementById('barcode-scanner')
      inp.focus()
    },
    // handleNoTaxPrice(id, noTaxPrice, tax, price) {
    //   const check = this.cartProducts.find(e => e.id == id)
    //   console.log(check);
    //   var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
    //   check.price = Number(newPrice)
    // },
    // handleTax(id, noTaxPrice, tax, price) {
    //   const check = this.cartProducts.find(e => e.id == id)
    //   var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
    //   check.price = newPrice
    // },
    // handlePrice(id, noTaxPrice, tax, price) {
    //   const check = this.cartProducts.find(e => e.id == id)
    //   var newPurchase = (Number(price) / (1 + Number(tax) / 100)).toFixed(2)
    //   check.no_tax_price = newPurchase
    // },
    handleNoTaxPrice(id, noTaxPrice, tax, price) {
      // const check = this.cartProducts.find(e => e.id == id)
      // var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
      // check.price = newPrice
      const check = this.cartProducts.find(e => e.id === id);
      let newPrice;

      if (check.isDiscount) {
        // İndirimli fiyatın hesaplanması için gerekli işlemleri yapın
        const discountedNoTaxPrice = this.calculateDiscountedNoTaxPrice(noTaxPrice, check.discount, check.discountType);
        newPrice = (Number(discountedNoTaxPrice) + (Number(discountedNoTaxPrice) * Number(tax) / 100)).toFixed(2);
      } else {
        newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2);
      }

      check.price = newPrice;
    },
    calculateDiscountedNoTaxPrice(noTaxPrice, discount, discountType) {
      let discountedNoTaxPrice = noTaxPrice;

      if (discountType === '%') {
        const discountAmount = (noTaxPrice * discount) / 100;
        discountedNoTaxPrice -= discountAmount;
      } else if (discountType === '$') {
        discountedNoTaxPrice -= discount;
      }

      return discountedNoTaxPrice;
    },
    handleTax(id, noTaxPrice, tax, price) {
      // const check = this.cartProducts.find(e => e.id == id)
      // var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
      // check.price = newPrice
      const check = this.cartProducts.find(e => e.id === id);
      let newPrice;

      if (check.isDiscount) {
        const discountedNoTaxPrice = this.calculateDiscountedNoTaxPrice(noTaxPrice, check.discount, check.discountType);
        newPrice = (Number(discountedNoTaxPrice) + (Number(discountedNoTaxPrice) * Number(tax) / 100)).toFixed(2);
      } else {
        newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2);
      }

      check.price = newPrice;
    },
    async handlePrice(id, noTaxPrice, tax, price) {
      // const check = this.cartProducts.find(e => e.id == id)
      // var newPurchase = (Number(price) / (1 + Number(tax) / 100)).toFixed(2)
      // check.no_tax_price = newPurchase
      const check = this.cartProducts.find(e => e.id === id);

      if (check.isDiscount) {
        if (check.discountType == '%') {
          if (check.discount == 100) {
            check.price = 0
          } else {
            const targetPrice = price; // Hedef fiyat (100 TL)
            const taxRate = check.tax; // Vergi oranı (%)
            const discountRate = check.discount; // İndirim oranı (%)
            // İndirimsiz vergisiz fiyatı hesapla
            const noTaxPrice = targetPrice / (1 + taxRate / 100) / (1 - discountRate / 100);
            console.log(Number(noTaxPrice).toFixed(2));
            check.no_tax_price = Number(noTaxPrice).toFixed(2)
          }
        } else {
          var salePrice = price; // Satış fiyatı
          var taxRate = check.tax; // Vergi yüzdesi
          var discountAmount = check.discount; // İndirim miktarı

          // Vergisiz fiyatı bul
          var salePriceWithoutTax = salePrice / (1 + (taxRate / 100));

          // İndirimi vergisiz fiyattan çıkar
          var salePriceWithoutDiscount = (salePriceWithoutTax + Number(discountAmount)).toFixed(2);
          check.no_tax_price = salePriceWithoutDiscount
        }
      } else {
        const newNoTaxPrice = (Number(price) / (1 + Number(tax) / 100)).toFixed(2);
        check.no_tax_price = newNoTaxPrice;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

