<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;

        justify-content: space-between;

        align-items: center;

        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Products')" />
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <Button :getData="getData" @loader="loader = $event" />
        <MultiProductAddButton :getData="getData" @loader="loader = $event" />
        <!-- <Button :getData="getData" @loader="loader = $event" /> -->
      </div>
    </div>

    <ProductFilter
      @itemFilter="data = $event"
      :loader="loader"
      @newLoad="loader = $event"
    />

    <a-layout-content
      :style="{
        margin: '0px 16px',

        padding: '24px',

        background: '#fff',

        minHeight: '280px',
      }"
    >
      <a-table
        :scroll="{ x: 1000 }"
        v-if="loader"
        :columns="columns"
        :data-source="data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div
              class=""
              style="
                display: flex;

                justify-content: center;

                align-items: center;

                gap: 15px;
              "
            >
              <a-button :loading="detailLoadBtn" @click="showDrawer(text)">
                <EyeOutlined />
              </a-button>

              <router-link :to="'/products/' + text"
                ><a-button> <EditOutlined /> </a-button
              ></router-link>

              <a-popover v-if="record.isAction">
                <template #content>
                  <span style="margin: auto">{{
                    $t("this data is used. Cannot be deleted")
                  }}</span>
                </template>
                <a-button disabled>
                  <DeleteOutlined />
                </a-button>
              </a-popover>
              <a-button
                v-else
                @click="remove(text)"
                :disabled="record.isAction"
              >
                <DeleteOutlined />
              </a-button>
            </div>
          </template>
          <template v-if="column.key === 'sku'">
            <span>{{ text ? text : "-" }}</span>
          </template>
          <template v-if="column.key === 'category_id'">
            <span>{{
              record.category_id != null ? record.category_id.name : "-"
            }}</span>
          </template>
          <!--<template v-if="column.key === 'brand_id'">
            <span>{{ record.brand_id != null ? record.brand_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'material_id'">
            <span>{{ record.material_id != null ? record.material_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'pattern_id'">
            <span>{{ record.pattern_id != null ? record.pattern_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'season_id'">
            <span>{{ record.season_id != null ? record.season_id.name : "-" }}</span>
          </template> -->
          <template v-if="column.key === 'sale_price'">
            <span>{{ user.firm.currency }}{{ text }}</span>
          </template>
          <template v-if="column.key === 'variants'">
            <span>{{ text.length }}</span>
          </template>
          <template v-if="column.key === 'price'">
            <span
              >{{ user.firm.currency }}{{ moneyFormat(text).substr(1) }}</span
            >
          </template>
        </template>
      </a-table>
      <Loader v-else />
      <a-drawer
        v-if="visibleDetailLoad"
        :title="`${productDetail.name}`"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <ProductDetail
          :showDrawer="showDrawer"
          :sellAndRetuns="sellAndRetuns"
          :detailStock="detailStock"
          :data="productDetail"
          :sizes="sizes"
          :colors="colors"
        />
      </a-drawer>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import Button from "@/components/product/Button.vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import ProductFilter from "@/components/product/ProductFilter.vue";
import MultiProductAddButton from "@/components/product/MultiProductAddButton.vue";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loader: false,
      visibleDetail: true,
      visibleDetailLoad: false,
      productDetail: {},
      columns: [
        {
          title: this.$t("Product Name"),
          dataIndex: "name",
        },
        {
          title: this.$t("SKU"),
          dataIndex: "sku",
          key: "sku",
        },
        {
          title: this.$t("Category"),
          dataIndex: "category_id",
          key: "category_id",
        },
        // {
        //   title: this.$t("Brand"),
        //   dataIndex: "brand_id",
        //   key: "brand_id",
        // },
        // {
        //   title: this.$t("Pattern"),
        //   dataIndex: "pattern_id",
        //   key: "pattern_id",
        // },
        // {
        //   title: this.$t("Material"),
        //   dataIndex: "material_id",
        //   key: "material_id",
        // },
        // {
        //   title: this.$t("Season"),
        //   dataIndex: "season_id",
        //   key: "season_id",
        // },
        {
          title: this.$t("Sale Price"),
          dataIndex: "sale_price",
          key: "sale_price",
        },
        // {
        //   title: this.$t("Variant"),
        //   dataIndex: "variants",
        //   key: "variants",
        // },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
      sizes: [],
      colors: [],
      detailLoadBtn: false,
      detailStock: {
        columns: [],
        data: [],
      },
      sellAndRetuns: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showDrawer(id) {
      this.detailLoadBtn = true;
      this.productDetail = {};
      this.visibleDetail = true;
      this.detailStock = {
        columns: [],
        data: [],
      };
      axios
        .get(`/api/product/${id}`)
        .then(async (result) => {
          this.sizes = [];
          this.colors = [];
          this.productDetail = result.data.data;
          await result.data.branch.forEach((b) => {
            this.detailStock.columns.push({
              title: b.name,
              dataIndex: b.name,
              key: "value",
            });
          });
          var dataObject = {};
          await result.data.stock.forEach((s, key1) => {
            result.data.branch.forEach((b, key2) => {
              var branchName = b.name;
              var check = s.branchs.find((r) => r.name == branchName);

              if (check) {
                dataObject[branchName] = check.quantity; // branchName key olarak, check.quantity value olarak atanıyor
              } else {
                dataObject[branchName] = 0; // Eğer check undefined ise, branchName key olarak atanıyor ve value olarak 0 atanıyor
              }
            });
            this.detailStock.data.push({
              sku: s.sku,
              ...dataObject,
            });
          });
          this.sellAndRetuns = await [
            {
              title: this.$t("Total Sell Quantity"),
              value: result.data.sell.totalQuantity,
            },
            {
              title: this.$t("Total Sell Amount"),
              value: result.data.sell.totalPrice,
            },
            {
              title: this.$t("Total Return Quantity"),
              value: result.data.return.totalQuantity,
            },
            {
              title: this.$t("Total Return Amount"),
              value: result.data.return.totalPrice,
            },
          ];
          this.detailLoadBtn = false;
          this.visibleDetailLoad = await true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.productDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    async sendToTelegram(id) {
      await axios
        .post("/api/telegram/send", { product_id: id })
        .then(async (result) => {
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getData() {
      await axios
        .get("/api/product/all")
        .then(async (result) => {
          this.data = await result.data;
          this.loader = true;
        })
        .catch((err) => {});
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content: this.$t(
          "If you delete this data, all related data will be deleted. Are you sure?"
        ),
        onOk: async () => {
          await axios
            .delete(`/api/product/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: {
    Loader,
    PageTitle,
    EditOutlined,
    Button,
    DeleteOutlined,
    EyeOutlined,
    ProductDetail,
    ProductDetail,
    ProductFilter,
    MultiProductAddButton,
  },
};
</script>

<style lang="scss" scoped></style>
