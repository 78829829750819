<template>
    <div>
        <PageTitle :title="$t('Integrations')" />
        <div :style="{
            margin: '0px 16px',
            minHeight: '280px',
        }">
            <a-row class="" style="display: flex;justify-content: start;align-items: center;width: 100%;">
                <a-col :md="8">
                    <Earsiv/>
                </a-col>
                <a-col :md=" 8 " v-for="( item, index ) in  data " :key=" index ">
                    <div class="">
                        <a-card>
                            <div class="" style="display: flex;justify-content: start;align-items: center;gap: 25px;">
                                <div class="img" style="width: 80px;height: 80px;">
                                    <img :src=" require(`@/assets/images/logo/${item.img}.png`) "
                                        style="width: 100%;height: 100%;" alt="" srcset="">
                                </div>
                                <div class="title">
                                    <h3>{{ item.title }}</h3>
                                </div>
                            </div>
                            <a-divider />
                            <div class="subtitle" style="min-height: 50px;max-height: 55px;">
                                <span>{{ item.subtitle }}</span>
                            </div>
                            <div class="btn"
                                style="margin-top: 20px;display: flex;justify-content: end;align-items: center;">
                                <a-button type="primary" disabled>{{ $t("Soon") }}</a-button>
                            </div>
                        </a-card>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import PageTitle from '@/components/shared/PageTitle.vue';
import Earsiv from '@/components/earsiv/earsiv.vue';

export default {
    components: { PageTitle, Earsiv },
    data() {
        return {
            visibleCreate: false,
            data: [
                {
                    img: 'gib',
                    title: 'E-Fatura',
                    subtitle: this.$t("Converting the invoices you created via the panel to e-invoice with a single click")
                }, {
                    img: 'trendyol',
                    title: 'Trendyol',
                    subtitle: this.$t("See your orders and your remaining stocks on Trendyol")
                }, {
                    img: 'hepsiburada',
                    title: 'Hepsiburada',
                    subtitle: this.$t("See your orders and your remaining stocks on Hepsiburada")
                }, {
                    img: 'amazon',
                    title: 'Amazon',
                    subtitle: this.$t("See your orders and your remaining stocks on Amazon")
                },
                // {
                //     img:'parasut',
                //     title:'Paraşüt',
                //     subtitle:this.$t("You can upload your previous data to the panel with Paraşüt integration")
                // },
                {
                    img: 'shopify',
                    title: 'Shopify',
                    subtitle: this.$t("See your orders and your remaining stocks on Shopify")

                }, {
                    img: 'ticimax',
                    title: 'Ticimax',
                    subtitle: this.$t("See your orders and your remaining stocks on Ticimax")
                },
                //  {
                //     img: 'ideasoft',
                //     title: 'İdeasoft',
                //     subtitle: this.$t("See your orders and your remaining stocks on Ideasoft")
                // }
            ]
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped></style>
