<template>
    <div>
        <div class="">
            <div class="" style="display: flex; justify-content: end; align-items: center; gap: 15px">
                <!-- <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" /> -->
                <a-select v-model:value="user.firm.currency" @change="getData()" placeholder="Currency">
                    <a-select-option :value="'$'">$ Dollar</a-select-option>
                    <a-select-option :value="'€'">€ Euro</a-select-option>
                    <a-select-option :value="'₺'">₺ Lira</a-select-option>
                    <a-select-option :value="'£'">£ Pound</a-select-option>
                    <a-select-option :value="'₼'">₼ Manat</a-select-option>
                    <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                    <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                    <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                    <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                    <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                    <a-select-option :value="'SR'">SR Riyal</a-select-option>
                </a-select>
                <a-select style="width: 200px;" v-model:value="form.customer" @change="getData()"
                    :placeholder="$t('Customer')">
                    <a-select-option v-for="(item, index) in customers" :key="index" :value="item._id">{{ item.name
                    }}</a-select-option>
                </a-select>
                <a-button v-if="form.customer" type="danger" @click="cleanFilter()">{{
                    $t("Clear")
                }}</a-button>
            </div>
            <div class="" v-if="loading">
                <div class="datas" style="margin-top: 80px">
                    <a-row>
                        <a-col :xs="24" :md="24" :xl="12">
                            <CurrenctCard :title="$t('Sells')" :value="data.total" :currency="currency" />
                        </a-col>
                        <a-col :xs="24" :md="24" :xl="12">
                            <CurrenctCard :title="$t('No Paid Amount')" :value="data.remainingTotal" :currency="currency" />
                        </a-col>
                    </a-row>
                    <a-divider />
                    <a-row>
                        <a-col :xs="24" :md="24" :xl="24">
                            <div class="">
                                <h3>{{ $t('No Paid Invoices') }}</h3>
                                <NoPaidInvoices :list="list" :getCurrentData="getData" :currency="currency"
                                    :date="form.date" />
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <Loader v-else />
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import CurrenctCard from "../card/CurrenctCard.vue";
import NoPaidInvoices from "./NoPaidInvoices.vue";
export default {
    components: { Loader, CurrenctCard, NoPaidInvoices },
    data() {
        return {
            user: JSON.parse(localStorage.getItem("user")),
            filterText: "",
            form: {},
            loading: false,
            data: {},
            currency: "$",
            customers: [],
            list: [],
            config: {
                data: [],
                color: ["#5D90F7", "#cf1322"],
                height: 350,
                xField: "date",
                yField: "count",
                seriesField: "type",
                smooth: true,
            },
        };
    },
    mounted() {
        this.getData();
        this.getCustomers();
    },
    methods: {
        getData() {
            this.loading = false;
            this.filterText = "";
            // if (this.form.date) {
            //     this.filterText =
            //         this.filterText +
            //         `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
            // }
            if (this.user.firm.currency) {
                this.filterText = this.filterText + `currency=${this.user.firm.currency}&`;
            }

            if (this.form.customer) {
                this.filterText = this.filterText + `customer=${this.form.customer}&`;
            }
            axios
                .get(`/api/sale-report/daily-sales-amount-no-paid?${this.filterText}`)
                .then((result) => {
                    this.data = result.data.data;
                    this.currency = result.data.currency;
                    this.list = result.data.list;
                    this.loading = true;
                })
                .catch((err) => { });
        },
        getCustomers() {
            axios.get('/api/customer/all').then((result) => {
                this.customers = result.data
            }).catch((err) => {

            });
        },
        cleanFilter() {
            this.loader = false;
            this.form = {};
            this.filterText = "";
            this.getData();
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
  