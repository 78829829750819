<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row style="width: 100%">
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('SKU')" style="margin: 10px">
              <a-input v-model:value="filter.sku" :placeholder="$t('SKU')" />
            </a-form-item>
          </a-col>
          <a-form-item v-if="filter.sku" style="margin: 10px">
            <a-button type="primary" @click="setFilter()">{{ $t("Filter") }}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{
              $t("Clear")
            }}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filter: {},
      filterText: "",
      loader: false,
      data: [],
    };
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);
      if (this.filter.sku) {
        this.filterText = this.filterText + `sku=${this.filter.sku}&`;
      }

      await axios
        .get(`/api/stock/all?${this.filterText}`)
        .then(async (result) => {
          var items = [];
          var dataObject = {};
          result.data.stock.forEach((s, key1) => {
            result.data.branch.forEach((b, key2) => {
              var branchName = b.name;
              var check = s.branchs.find((r) => r.name == branchName);

              if (check) {
                dataObject[branchName] = check.quantity; // branchName key olarak, check.quantity value olarak atanıyor
              } else {
                dataObject[branchName] = 0; // Eğer check undefined ise, branchName key olarak atanıyor ve value olarak 0 atanıyor
              }
            });
            items.push({
              sku: s.sku,
              name: s.name,
              ...dataObject,
            });
          });
          this.loader = true;
          this.$emit("itemFilter", items);
          this.$emit("newLoad", this.loader);
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);
      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
