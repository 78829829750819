<template>
  <div>
    <div
      class=""
      style="display: flex; justify-content: end; align-items: center; gap: 15px"
    >
      <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
    </div>
    <a-list
      v-if="loading"
      size="small"
      bordered
      :data-source="data"
      style="margin-top: 15px; width: 100%"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <template #actions>
            <a-button type="primary" @click="saleDetail(item._id)"><span  style="cursor: pointer">{{
                `${item.invoice_qty}  ${$t("qty")}`
              }}</span></a-button>
          </template>
          <a-list-item-meta>
            <template #title>
              <h4>{{ item.customer_name }}</h4>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
      <a-drawer
        v-if="visibleDetailLoad"
        :title="detail.name"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <CustomerDetail :data="detail" />
      </a-drawer>
    </a-list>
    <Loader v-else />
  </div>
</template>

<script>
import Loader from "@/components/shared/Loader.vue";
import CustomerReportDetail from "./CustomerReportDetail.vue";
import axios from "axios";
import CustomerDetail from "@/components/customer/CustomerDetail.vue";

export default {
  components: { Loader, CustomerReportDetail, CustomerDetail },
  data() {
    return {
      data: [],
      filterText: "",
      form: {},
      detail: {},
      visibleDetail: false,
      loading: false,
      visibleDetailLoad: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async saleDetail(id) {
      this.visibleDetail = true;
      this.detail = await {};
      axios.get(`api/customer/${id}`).then(async (result) => {
        this.detail = await result.data;
        this.visibleDetailLoad = true;
        
      }).catch((err) => {
        
      });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.detail = {};
        this.visibleDetailLoad = false;
      }
    },
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      axios
        .get(`/api/customer-reports/sale-report?${this.filterText}`)
        .then((result) => {
          console.log(result.data);
          this.data = result.data;
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
