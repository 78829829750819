<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Transfers')" />
      <Button :getData="getData" />
    </div>
    <TransferFilter
      @itemFilter="data = $event"
      :loader="loader"
      @newLoad="loader = $event"
    />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-table
        :scroll="{ x: 1000 }"
        v-if="loader"
        :columns="columns"
        :data-source="data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'from_branch_id'">
            <span>{{ record.from_branch_id ? record.from_branch_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'to_branch_id'">
            <span>{{ record.to_branch_id ? record.to_branch_id.name : "-" }}</span>
          </template>
          <template v-if="column.key === 'products'">
            <span>{{
              record.products
                ? text.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                  }, 0)
                : "0"
            }}</span>
          </template>
          <template v-if="column.key === 'date'">
            <span>{{ text.substr(0, 10) }}</span>
          </template>
          <template v-if="column.key === 'btn'">
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
              "
            >
              <a-button @click="showDrawer(text)"><EyeOutlined /></a-button>
              <router-link :to="'/transfers/' + text"
                ><a-button><EditOutlined /></a-button
              ></router-link>
              <a-button @click="remove(text)"><DeleteOutlined /></a-button>
            </div>
          </template>
        </template>
      </a-table>
      <Loader v-else />
      <a-drawer
        v-if="visibleDetailLoad"
        :title="transferDetail.transfer_id"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <TransferDetail :data="transferDetail" />
      </a-drawer>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/transfer/Button.vue";
import Loader from "@/components/shared/Loader.vue";
import axios from "axios";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import TransferDetail from "@/components/transfer/TransferDetail.vue";
import TransferFilter from "@/components/transfer/TransferFilter.vue";

export default {
  components: {
    PageTitle,
    Button,
    Loader,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    TransferDetail,
    TransferFilter,
  },
  data() {
    return {
      loader: false,
      visibleDetail: true,
      visibleDetailLoad: false,
      transferDetail: {},
      columns: [
        {
          title: this.$t("ID"),
          dataIndex: "transfer_id",
          key: "transfer_id",
        },
        {
          title: this.$t("From Branch"),
          dataIndex: "from_branch_id",
          key: "from_branch_id",
        },
        {
          title: this.$t("To Branch"),
          dataIndex: "to_branch_id",
          key: "to_branch_id",
        },
        {
          title: this.$t("Product"),
          dataIndex: "products",
          key: "products",
        },
        {
          title: this.$t("Date"),
          dataIndex: "date",
          key: "date",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showDrawer(id) {
      this.visibleDetail = true;
      axios
        .get(`/api/transfer/${id}`)
        .then((result) => {
          this.transferDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.transferDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    async getData() {
      await axios
        .get("/api/transfer/all")
        .then((result) => {
          this.data = result.data;
          this.loader = true;
        })
        .catch((err) => {});
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content:
          this.$t("If you delete this data, all related data will be deleted. Are you sure?"),
        onOk: async () => {
          await axios
            .delete(`/api/transfer/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
