<template>
  <div>
    <div class="">
      <div
        class=""
        style="display: flex; justify-content: end; align-items: center; gap: 15px"
      >
        <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
        <a-select
          v-model:value="user.firm.currency"
          @change="getData()"
          placeholder="Currency"
        >
          <a-select-option :value="'$'">$ Dollar</a-select-option>
          <a-select-option :value="'€'">€ Euro</a-select-option>
          <a-select-option :value="'₺'">₺ Lira</a-select-option>
          <a-select-option :value="'£'">£ Pound</a-select-option>
          <a-select-option :value="'₼'">₼ Manat</a-select-option>
          <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
          <a-select-option :value="'₽'">₽ Ruble</a-select-option>
          <a-select-option :value="'₹'">₹ Rupi</a-select-option>
          <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
          <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
          <a-select-option :value="'SR'">SR Riyal</a-select-option>
        </a-select>
      </div>
      <div class="" v-if="loading">
        <LinePlot v-if="chart" :config="config" :chartRef="(ref) => (LineRef = ref)" />
        <div class="datas" style="margin-top: 80px">
          <a-row >
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Sells')" :value="data.sell" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Paid Amount')" :value="data.paid" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('No Paid Amount')" :value="data.noPaid" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Returns')" :value="data.return" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Tax')" :value="data.tax" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Incomes')" :value="data.income" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="12" :xl="6">
              <CurrenctCard :title="$t('Expenses')" :value="data.exprens" :currency="currency" />
            </a-col>
            <a-col :xs="24" :md="24" :xl="6">
              <CurrenctCard :title="$t('Remainder')" :value="data.profit" :currency="currency" />
            </a-col>
          </a-row>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LinePlot } from "ant-design-charts-vue";
import Loader from "@/components/shared/Loader.vue";
import CurrenctCard from "../card/CurrenctCard.vue";
export default {
  props:['chart'],
  components: { LinePlot, Loader, CurrenctCard },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      filterText: "",
      form: {},
      loading: false,
      data: {},
      currency: "$",
      config: {
        data: [],
        color: ["#5D90F7", "#cf1322"],
        height: 350,
        xField: "date",
        yField: "count",
        seriesField: "type",
        smooth: true,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      if (this.user.firm.currency) {
        this.filterText = this.filterText + `currency=${this.user.firm.currency}&`;
      }
      axios
        .get(`/api/sale-report/daily-sales-amount?${this.filterText}`)
        .then((result) => {
          this.data = result.data.data;
          this.currency = result.data.currency;
          this.config.data = result.data.list;
          this.loading = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
