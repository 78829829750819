<template>
  <div>
    <div
      class=""
      style="display: flex; justify-content: end; align-items: center; gap: 15px"
    >
      <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
    </div>
    <PiePlot v-if="loading" :config="config" :chartRef="(ref) => (PiePlot = ref)" />
    <Loader v-else />
  </div>
</template>

<script>
import { PiePlot } from "ant-design-charts-vue";
import Loader from "@/components/shared/Loader.vue";
import axios from "axios";

export default {
  components: {
    PiePlot,
    Loader,
  },
  data() {
    return {
      loading: false,
      form: {},
      config: {
        appendPadding: 10,
        data: [],
        angleField: "rate",
        colorField: "name",
        radius: 0.9,
        label: {
          type: "inner",
          offset: "-30%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [
          {
            type: "element-active",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = false;
      this.filterText = "";
      if (this.form.date) {
        this.filterText =
          this.filterText +
          `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
      }
      axios
        .get(`/api/product-report/season?${this.filterText}`)
        .then((result) => {
          console.log(result.data);
          this.config.data = result.data;
          this.loading = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
