<template>
    <div>
        <div class="">
            <div class="" style="display: flex; justify-content: end; align-items: center; gap: 15px">
                <a-range-picker v-model:value="form.date" @change="getData()" format="DD/MM/YY" />
                <a-select v-model:value="user.firm.currency" @change="getData()" :placeholder="$t('Currency')">
                    <a-select-option :value="'$'">$ Dollar</a-select-option>
                    <a-select-option :value="'€'">€ Euro</a-select-option>
                    <a-select-option :value="'₺'">₺ Lira</a-select-option>
                    <a-select-option :value="'£'">£ Pound</a-select-option>
                    <a-select-option :value="'₼'">₼ Manat</a-select-option>
                    <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                    <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                    <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                    <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                    <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                    <a-select-option :value="'SR'">SR Riyal</a-select-option>
                </a-select>
                <a-select v-model:value="form.customer" @change="getData()" :placeholder="$t('Customer')">
                    <a-select-option v-for="(item, index) in customers" :key="index" :value="item._id">{{ item.name
                    }}</a-select-option>
                </a-select>
            </div>
            <div class="" style="margin-top: 15px;" v-if="loading">
                <a-table :scroll="{ x: 1000 }" :columns="columns" :data-source="data" @change="onChange">
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.key === 'customer_name'">
                            {{ record.invoice_id.customer_id.name }}
                        </template>
                        <template v-if="column.key === 'invoice_no'">
                            {{ record.invoice_id.invoice_no }}
                        </template>
                        <template v-if="column.key === 'date'">
                            {{ record.date.substr(0, 10) }}
                        </template>
                        <template v-if="column.key === 'price'">
                            {{ `${record.currency}${record.price}` }}
                        </template>
                        <template v-if="column.key === 'btn'">
                            <div class="" style="
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                gap: 15px;
                                                              ">
                                <a-button @click="showDrawerInvoice(record.invoice_id._id)">
                                    <EyeOutlined />
                                </a-button>
                            </div>
                        </template>
                    </template>
                </a-table>
            </div>
            <Loader v-else />
            <a-drawer v-if="visibleInvoiceDetailLoad" :title="`${$t('Invoice')} ` + invoiceDetail.invoice_no"
                placement="right" :closable="false" v-model:visible="visibleDetail"
                :after-visible-change="afterVisibleInvoiceChange">
                <InvoiceDetail @invoiceDetail="invoiceDetail = $event" :data="invoiceDetail" />
            </a-drawer>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import CurrenctCard from "../card/CurrenctCard.vue";
import NoPaidInvoices from "./NoPaidInvoices.vue";
import { EyeOutlined } from "@ant-design/icons-vue";
import InvoiceDetail from "@/components/invoice/InvoiceDetail.vue";
export default {
    components: { Loader, CurrenctCard, NoPaidInvoices, EyeOutlined, InvoiceDetail },
    data() {
        return {
            invoiceDetail: {},
            loader: false,
            visibleDetail: true,
            visibleDetailLoad: false,
            visibleInvoiceDetailLoad: false,
            customers: [],
            user: JSON.parse(localStorage.getItem("user")),
            filterText: "",
            form: {},
            loading: false,
            data: {},
            currency: "$",
            columns: [
                {
                    title: this.$t("Customer Name"),
                    dataIndex: "invoice_id",
                    key: "customer_name",
                },
                {
                    title: this.$t("Invoice No"),
                    dataIndex: "invoice_id",
                    key: "invoice_no",
                },
                {
                    title: this.$t("Payment"),
                    dataIndex: "price",
                    key: "price",
                },
                {
                    title: this.$t("Date"),
                    dataIndex: "date",
                    key: "date",
                },
                {
                    title: this.$t("Action"),
                    dataIndex: "invoice_id",
                    key: "btn",
                },
            ],
            data: [],
        };
    },
    mounted() {
        this.getData();
        this.getCustomers();
    },
    methods: {
        showDrawerInvoice(id) {
            this.visibleDetail = true;
            axios
                .get(`/api/invoice/${id}`)
                .then((result) => {
                    this.invoiceDetail = result.data;
                    this.visibleInvoiceDetailLoad = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        afterVisibleInvoiceChange(bool) {
            if (bool == false) {
                this.invoiceDetail = {};
                this.visibleInvoiceDetailLoad = false;
                this.getData()
            }
        },
        getData() {
            this.loading = false;
            this.filterText = "";
            if (this.form.date) {
                this.filterText =
                    this.filterText +
                    `startDateQuery=${this.form.date[0]}&endDateQuery=${this.form.date[1]}&`;
            }
            if (this.user.firm.currency) {
                this.filterText = this.filterText + `currency=${this.user.firm.currency}&`;
            }
            if (this.form.customer) {
                this.filterText = this.filterText + `customer=${this.form.customer}&`;
            }
            axios
                .get(`/api/payments/all?${this.filterText}`)
                .then((result) => {
                    this.data = result.data;
                    this.loading = true;
                })
                .catch((err) => { });
        },
        getCustomers() {
            axios.get('/api/customer/all').then((result) => {
                this.customers = result.data
            }).catch((err) => {

            });
        }
    },
};
</script>
  
<style lang="scss" scoped></style>
  