<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Seasons')" />
      <Button :getData="getData" />
    </div>
    <SeasonFilter
      @itemFilter="data = $event"
      :loader="loader"
      @newLoad="loader = $event"
    />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-table
        :scroll="{ x: 1000 }"
        v-if="loader"
        :columns="columns"
        :data-source="data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
              "
            >
              <router-link :to="'/seasons/' + text"
                ><a-button><EditOutlined /></a-button
              ></router-link>
              <a-popover>
                <template #content>
                  <span style="margin: auto">{{
                    $t("this data is used. Cannot be deleted")
                  }}</span>
                </template>
                <a-popover v-if="record.isAction">
                  <template #content>
                    <span style="margin: auto">{{
                      $t("this data is used. Cannot be deleted")
                    }}</span>
                  </template>
                  <a-button disabled><DeleteOutlined /></a-button>
                </a-popover>
                <a-button v-else @click="remove(text)" :disabled="record.isAction"
                  ><DeleteOutlined
                /></a-button>
              </a-popover>
            </div>
          </template>
        </template>
      </a-table>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/season/Button.vue";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import SeasonFilter from "@/components/season/SeasonFilter.vue";

export default {
  data() {
    return {
      loader: false,
      columns: [
        {
          title: this.$t("Season Name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async isAction() {
      this.data.forEach(async (e) => {
        await axios
          .get(`/api/season/is-action/${e._id}`)
          .then((result) => {
            if (result.data.status) {
              e.isAction = false;
            } else {
              e.isAction = true;
            }
          })
          .catch((err) => {});
      });
      console.log(this.data);
    },
    async getData() {
      await axios
        .get("/api/season/all")
        .then((result) => {
          this.data = result.data;
          this.isAction();
          setTimeout(() => {
            this.loader = true;
          }, 500);
        })
        .catch((err) => {});
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content: this.$t(
          "If you delete this data, all related data will be deleted. Are you sure?"
        ),
        onOk: async () => {
          await axios
            .delete(`/api/season/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
  },
  components: { Loader, PageTitle, Button, EditOutlined, DeleteOutlined, SeasonFilter },
};
</script>

<style lang="scss" scoped></style>
