<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row>
          <a-col :xs="24" :xl="24" :md="24">
            <a-form-item :label="$t('Category Name')" style="margin: 10px">
              <a-input v-model:value="filter.name" :placeholder="$t('Category Name')" />
            </a-form-item>
          </a-col>
          <a-form-item v-if="filter.name" style="margin: 10px">
            <a-button type="primary" @click="setFilter()">{{$t('Filter')}}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{$t('Clear')}}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filter: {},
      filterText: "",
      loader: false,
    };
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);
      if (this.filter.name) {
        this.filterText = this.filterText + `name=${this.filter.name}&`;
      }

      await axios
        .get(`/api/category/all?${this.filterText}`)
        .then((result) => {
          this.loader = true;
          result.data.forEach(async (e) => {
            await axios
              .get(`/api/category/is-action/${e._id}`)
              .then((res) => {
                if (res.data.status) {
                  e.isAction = false;
                } else {
                  e.isAction = true;
                }
              })
              .catch((err) => {});
          });
          this.$emit("itemFilter", result.data);
          setTimeout(() => {
            this.$emit("newLoad", this.loader);
          }, 500);
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
