<template>
  <div>
    <a-button type="primary" @click="showModal">
      <PlusOutlined /><span>{{ $t("Create") }}</span>
    </a-button>
    <a-modal :mask-closable="false" :width="1200" v-model:visible="visible" :title="$t('New Invoice Create')"
      :footer="null">
      <a-form layout="vertical">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" :tab="$t('Branch')">
            <a-form-item :label="$t('Type')">
              <a-radio-group v-model:value="form.type">
                <a-radio value="sell">{{ $t("Sell") }}</a-radio>
                <a-radio value="return">{{ $t("Return") }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item :label="$t('Customer')">
              <a-select v-model:value="form.customer_id" :placeholder="$t('Customer')">
                <a-select-option v-for="(item, index) in customers" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('Branch')">
              <a-select v-model:value="form.branch_id" :placeholder="$t('Branch')">
                <a-select-option v-for="(item, index) in branchs" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('Invoice Date')">
              <a-date-picker v-model:value="form.invoice_date" />
            </a-form-item>
            <a-form-item :label="$t('Currency')">
              <a-select v-model:value="user.firm.currency" @change="() => {
                if (user.firm.currency == '₺') {
                  form.currencyRate = 0
                }
              }" :placeholder="$t('Currency')">
                <a-select-option :value="'$'">$ Dollar</a-select-option>
                <a-select-option :value="'€'">€ Euro</a-select-option>
                <a-select-option :value="'₺'">₺ Lira</a-select-option>
                <a-select-option :value="'£'">£ Pound</a-select-option>
                <a-select-option :value="'₼'">₼ Manat</a-select-option>
                <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                <a-select-option :value="'SR'">SR Riyal</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="user.firm.currency != '₺'" :label="$t('Currency Rate')">
              <a-input type="number" min="0" v-model:value="form.currencyRate" :placeholder="$t('Currency Rate')" />
            </a-form-item>
            <a-form-item :label="$t('Description')">
              <a-textarea v-model:value="form.desc" :placeholder="$t('Description')" />
            </a-form-item>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (visible = false)">{{ $t("Cancel") }}</a-button>
                <a-button :loading="loading" style="margin-left: 5px" type="primary" :disabled="(!form.type ||
                  !form.customer_id ||
                  !form.branch_id ||
                  !form.invoice_date) || (user.firm.currency != '₺' && form.currencyRate == 0)
                  " @click="() => (activeKey = '2')">{{ $t("Next") }}</a-button>
              </div>
            </a-form-item>
          </a-tab-pane>
          <!-- :disabled="(!form.type || !form.customer_id || !form.branch_id || !form.invoice_date) || (user.firm.currency != '₺' && form.currencyRate == 0)" -->
          <a-tab-pane
            :disabled="(!form.type || !form.customer_id || !form.branch_id || !form.invoice_date) || (user.firm.currency != '₺' && form.currencyRate == 0)"
            key="2" :tab="$t('Products')" force-render>
            <div class="" v-for="(item, index) in products" :key="index">
              <div class="invoiceProducts">
                <a-form-item style="width: 100%;" :label="$t('Product')">
                  <a-select v-model:value="item.sku" show-search label-in-value :filter-option="true" style="width: "
                    :not-found-content="isSkuFind ? undefined : null" :options="skuDatas" @search="searchSku" @change="(data) =>
                      handleProductSku(item.id, data.option.value, 1, data.option.price)
                      " :placeholder="$t('Product')">
                    <template v-if="isSkuFind" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                  </a-select>
                </a-form-item>
                <a-form-item class="prod-item" :label="$t('Quantity')">
                  <a-input type="number" min="0" :disabled="item.sku == '' || item.sku == null" :id="'quantity-' + index"
                    v-model:value="item.quantity" :placeholder="$t('Quantity')" />
                </a-form-item>
                <div class="prod-item">
                  <a-form-item :label="$t('No Tax Sale Price')">
                    <a-input type="number" min="0" :disabled="item.sku == '' || item.sku == null" :id="'price-' + index"
                      v-model:value="item.no_tax_price"
                      @change="handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)"
                      :placeholder="$t('No Tax Sale Price')" />
                    <a-form-item v-if="item.isDiscount" style="margin-top: 15px;" :label="$t('Discount')">
                      <div style="display: flex;justify-content: center;align-items: center;gap: 10px;">
                        <div class="" style="display: flex; justify-content: start">
                          <a-input type="number" min="0" @change="handleDiscount(item)" style="width: 60%"
                            v-model:value="item.discount" :placeholder="$t('Discount')" />
                          <a-select @change="handleDiscount(item)" style="width: 40%" v-model:value="item.discountType"
                            :placeholder="$t('Discount Type')">
                            <a-select-option value="%">%</a-select-option>
                            <a-select-option value="$">{{ user.firm.currency }}</a-select-option>
                          </a-select>
                        </div>
                        <div>
                          <CloseOutlined @click="removeProductDiscount(item.id)" />
                        </div>
                      </div>
                    </a-form-item>
                  </a-form-item>
                </div>
                <a-form-item :label="$t('Tax')">
                  <a-select :disabled="item.sku == '' || item.sku == null" v-model:value="item.tax"
                    :placeholder="$t('Tax')" @change="handleTax(item.id, item.no_tax_price, item.tax, item.price)">
                    <a-select-option :value="0">%0</a-select-option>
                    <a-select-option :value="8">%8</a-select-option>
                    <a-select-option :value="10">%10</a-select-option>
                    <a-select-option :value="18">%18</a-select-option>
                    <a-select-option :value="20">%20</a-select-option>
                    <a-select-option :value="24">%24</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item class="prod-item" :label="$t('Sale Price')">
                  <a-input type="number" min="0" :disabled="item.sku == '' || item.sku == null" :id="'price-' + index"
                    v-model:value="item.price" :placeholder="$t('Price')"
                    @change="handlePrice(item.id, item.no_tax_price, item.tax, item.price)" />
                </a-form-item>
                <a-form-item class="prod-total" :label="$t('Total')">
                  {{ `${user.firm.currency}${moneyFormat(item.price * item.quantity).substr(1)}` }}
                </a-form-item>
                <a-form-item>
                  <div class="" style="
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    gap: 5px;
                                                  ">
                    <a-dropdown v-if="(products.length > 1 && products.length != index + 1) && !item.isDiscount">
                      <EllipsisOutlined />
                      <template #overlay>
                        <a-menu>
                          <a-menu-item @click="addProductDiscount(item.id)">
                            <div>{{ $t('Add Discount') }}</div>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </div>
                </a-form-item>
                <a-form-item>
                  <div class="" style="
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    gap: 5px;
                                                  ">
                    <a-button v-if="products.length > 1 && products.length != index + 1" @click="removeProduct(item.id)">
                      <MinusOutlined />
                    </a-button>
                  </div>
                </a-form-item>
              </div>
              <a-divider />
            </div>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '1')">{{ $t("Back") }}</a-button>
                <a-button :loading="loading" style="margin-left: 5px" type="primary" :disabled="(!form.type ||
                  !form.customer_id ||
                  !form.branch_id ||
                  !form.invoice_date) || (user.firm.currency != '₺' && form.currencyRate == 0) || !products[0].sku"
                  key="3" :tab="$t('Others')" @click="() => (activeKey = '3')">{{ $t("Next") }}</a-button>
              </div>
            </a-form-item>
          </a-tab-pane>
          <!-- 
             :disabled="(!form.type ||
            !form.customer_id ||
            !form.branch_id ||
            !form.invoice_date ||
            products.length == 0) || (user.firm.currency != '₺' && form.currencyRate == 0)"
           -->
          <a-tab-pane :disabled="(!form.type ||
            !form.customer_id ||
            !form.branch_id ||
            !form.invoice_date) || (user.firm.currency != '₺' && form.currencyRate == 0) || !products[0].sku" key="3"
            :tab="$t('Others')" force-render>
            <!-- <a-form-item :label="$t('Tax')">
              <a-select v-model:value="form.tax" :placeholder="$t('Tax')">
                <a-select-option :value="0">%0</a-select-option>
                <a-select-option :value="8">%8</a-select-option>
                <a-select-option :value="18">%18</a-select-option>
                <a-select-option :value="24">%24</a-select-option>
              </a-select>
            </a-form-item> -->
            <a-form-item :label="$t('Discount')">
              <div class="" style="display: flex; justify-content: start">
                <a-input style="width: 80%" v-model:value="form.discount" :placeholder="$t('Discount')" />
                <a-select style="width: 20%" v-model:value="form.discountType" :placeholder="$t('Discount Type')" defa>
                  <a-select-option value="%">%</a-select-option>
                  <a-select-option value="$">{{ user.firm.currency }}</a-select-option>
                </a-select>
              </div>
            </a-form-item>
            <a-divider v-if="form.type == 'sell'" />
            <a-form-item :label="$t('Paid Amount')" v-if="form.type == 'sell'">
              <a-switch v-model:checked="form.isPaidAll" />
              <label style="margin-left: 15px;" for="">{{ $t('Paid All') }}</label>
            </a-form-item>
            <div v-if="form.type == 'sell'" class="">
              <a-form-item v-if="!form.isPaidAll" :label="$t('Paid Amount')">
                <div class="" style="display: flex; justify-content: start">
                  <a-input type="number" @change="paidAmountControl()" v-model:value="form.paidAmount"
                    :placeholder="$t('Paid Amount')" />
                </div>
              </a-form-item>
            </div>
            <a-divider />
            <div class="">
              <div class="">
                <h3>
                  {{ $t("SubTotal") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(cartTotal()).substr(1)}`
                  }}</b>
                </h3>
              </div>
              <div class="">
                <h3>
                  {{ $t("Tax") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(taxTotal()).substr(1)}`
                  }}</b>
                </h3>
              </div>
              <!-- <div v-if="form.tax != 0" class="">
                <h3>
                  {{ $t("Sub Amount") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(
                      cartTotal() + (cartTotal() * form.tax) / 100
                    ).substr(1)}`
                  }}</b>
                </h3>
              </div> -->
              <div class="" v-if="discountTotal() > 0">
                <h3>
                  {{ $t("Discount") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(discountTotal()).substr(1)}`
                  }}</b>
                </h3>
              </div>
              <div v-if="form.discount != 0" class="">
                <h3>
                  {{ $t("Discount") }}:
                  <b>{{
                    form.discountType == "%"
                    ? `-%${form.discount}`
                    : `-${user.firm.currency}${form.discount}`
                  }}</b>
                </h3>
              </div>
              <div class="">
                <h3>
                  {{ $t("Total") }}:
                  <b>{{
                    `${user.firm.currency}${moneyFormat(totalAmount()).substr(1)}`
                  }}</b>
                </h3>
              </div>
              <div v-if="!form.isPaidAll" class="">
                <h3>
                  {{ $t("Remaining Payment") }}:
                  <b>{{ `${user.firm.currency}${moneyFormat(totalAmountInvoice - form.paidAmount).substr(1)}` }}</b>
                </h3>
              </div>
            </div>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '2')">{{ $t("Back") }}</a-button>
                <a-popconfirm :title="$t('Are you sure?')" :ok-text="$t('Yes')" :cancel-text="$t('No')"
                  @confirm="handleOk()" @cancel="cancel">
                  <a-button :loading="loading" style="margin-left: 5px" type="primary">{{ $t("Create")
                  }}</a-button>
                </a-popconfirm>
              </div>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { MinusOutlined, PlusOutlined, CloseOutlined, EllipsisOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import moment from 'moment'
import dayjs from 'dayjs'
export default {
  props: ["getData"],
  components: { PlusOutlined, MinusOutlined, CloseOutlined, EllipsisOutlined },
  created() {
    this.form.invoice_date = dayjs()
    this.getBranchs();
    this.getCustomers();
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      visible: false,
      form: {
        type: "sell",
        tax: 0,
        discountType: "%",
        discount: 0,
        isPaidAll: true,
        paidAmount: 0,
        currencyRate: 0
      },
      loading: false,
      activeKey: "1",
      branchs: [],
      skuDatas: [],
      isSkuFind: false,
      customers: [],
      totalAmountInvoice: 0,
      products: [
        {
          id: Math.ceil(Math.random() * 8498615),
          sku: "",
          tax: 20,
          quantity: null,
          price: null,
          no_tax_price: null,
          discount: 0,
          discountType: '%',
          isDiscount: false
        },
      ],
    };
  },
  methods: {
    discountTotal() {
      var total = 0;
      this.products.forEach((e) => {
        if (e.discountType == '%') {
          total += ((e.no_tax_price * Number(e.discount) / 100) * e.quantity)
        } else {
          total += (Number(e.discount) * e.quantity)
        }
      });
      return (total).toFixed(2);
    },
    async handleDiscount(item) {
      const discount = parseFloat(item.discount);
      const noTaxPrice = parseFloat(item.no_tax_price);
      const quantity = parseInt(item.quantity);
      const discountType = item.discountType;

      if (discountType === '%' && (discount < 0 || discount > 100)) {
        item.discount = await 0
        await this.handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)
        return;
      } else if (discountType === '$' && discount > noTaxPrice) {
        item.discount = await 0
        await this.handleNoTaxPrice(item.id, item.no_tax_price, item.tax, item.price)
        return;
      } else if (discount < 0) {
        // Indirim değeri negatif olamaz.
        return;
      }

      let totalPrice = noTaxPrice * 1;

      if (discountType === '%') {
        const discountAmount = (totalPrice * discount) / 100;
        totalPrice -= discountAmount;
      } else if (discountType === '$') {
        totalPrice -= discount * 1;
      }

      var newDiscountNoTaxPrice = totalPrice.toFixed(2);

      const formattedTotalPrice = `${this.user.firm.currency}${this.moneyFormat(totalPrice).substr(1)}`;

      var newPrice = (Number(newDiscountNoTaxPrice) + (Number(newDiscountNoTaxPrice) * Number(item.tax) / 100)).toFixed(2)
      item.price = newPrice
    },
    addProductDiscount(id) {
      const check = this.products.find(e => e.id == id)
      check.isDiscount = true
    },
    removeProductDiscount(id) {
      const check = this.products.find(e => e.id == id)
      check.discount = 0
      check.isDiscount = false
      this.handleDiscount(check)
    },
    cartTotal() {
      var total = 0;
      this.products.forEach((e) => {
        total = total + e.no_tax_price * e.quantity;
      });
      return total;
    },
    taxTotal() {
      var total = 0;
      this.products.forEach((e) => {
        var noTaxPrice = e.no_tax_price
        if (e.discountType == '%') {
          noTaxPrice = noTaxPrice - (noTaxPrice * Number(e.discount) / 100)
        } else {
          noTaxPrice -= Number(e.discount)
        }
        total = total + (noTaxPrice * e.tax / 100) * e.quantity;
      });
      return total;
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    showModal() {
      this.visible = true;
    },
    async searchSku(sku) {
      this.isSkuFind = true;
      this.skuDatas = await [];
      await axios
        .get(`/api/product/find-product-sku?sku=${sku}`)
        .then((result) => {
          this.skuDatas = result.data;
          this.isSkuFind = false;
        })
        .catch((err) => { });
    },
    totalAmount() {
      var amount = Number(this.cartTotal()) + Number(this.taxTotal()) - Number(this.discountTotal())
      if (this.form.discount > 0) {
        if (this.form.discountType == "%") {
          amount = amount - (amount * this.form.discount / 100)
        } else {
          amount -= this.form.discount
        }
      }
      this.totalAmountInvoice = amount.toFixed(2)
      return amount.toFixed(2)
    },
    async handleProductSku(id, sku, quantity, price) {
      const check = this.products.find((s) => s.id == id);
      if (check) {
        axios.get(`/api/product/sku/${sku.split('///*%&&%*///')[0]}`).then((result) => {
          console.log(result);
          check.sku = sku;
          check.quantity = Number(quantity);
          check.purchase = Number(result.data.purchase_price).toFixed(2)
          check.tax = Number(result.data.tax);
          check.price = Number(price);
          check.no_tax_price = Number(result.data.no_tax_sale_price).toFixed(2);
          check.discount = 0,
            check.discountType = '%',
            check.isDiscount = false
        }).catch((err) => {

        });
      }
      await this.addProduct();
    },
    async handleOk() {
      this.loading = true;
      const emptyProducts = this.products.filter(
        (e) => e.sku == null || e.quantity == null
      );
      if (emptyProducts.length > 0) {
        emptyProducts.forEach((element) => {
          const check = this.products.find((e) => e.id == element.id);
          const index = this.products.indexOf(check);
          this.products.splice(index, 1);
        });
      }

      var data = {
        branch_id: this.form.branch_id,
        customer_id: this.form.customer_id,
        products: this.products,
        type: this.form.type,
        invoice_date: this.form.invoice_date,
        tax: this.form.tax,
        discount: this.form.discount,
        discountType: this.form.discountType,
        currency: this.user.firm.currency,
        currencyRate: this.form.currencyRate,
        desc: this.form.desc,
        isPaidAll: this.form.isPaidAll,
        paidAmount: this.form.paidAmount,
      };

      for (const e of data.products) {
        e.sku = e.sku.split('///*%&&%*///')[0];
      }


      if (this.products.length > 0) {

        await axios
          .post("/api/invoice/add", data)
          .then(async (result) => {
            this.loading = false;
            await notification.success({
              message: this.$t("Success"),
              description: this.$t("Transaction Successfully Performed"),
              duration: 1.5,
            });
            this.visible = false;
            this.form = {
              type: "sell",
              tax: 20,
              discountType: "%",
              discount: 0,
              isPaidAll: true,
              paidAmount: 0,
              invoice_date: dayjs()
            };
            this.activeKey = "1";
            this.getData();
            this.products = [
              {
                id: Math.ceil(Math.random() * 8498615),
                sku: "",
                quantity: null,
              },
            ];
          })
          .catch(async (err) => {
            this.loading = false;
            await notification.error({
              message: this.$t("Error"),
              description: this.$t("Error Occurred During Transaction"),
              duration: 1.5,
            });
          });
      } else {
        this.loading = false;
        (this.products = [
          {
            id: Math.ceil(Math.random() * 8498615),
            sku: "",
            quantity: null,
          },
        ]),
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error! Product not found"),

            duration: 1.5,
          });
      }
    },
    paidAmountControl() {
      if (Number(this.form.paidAmount) > Number(this.totalAmountInvoice)) {
        this.form.paidAmount = this.totalAmountInvoice
      }
    },
    async getBranchs() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
          if (result.data.length == 1) {
            this.form.branch_id = result.data[0]._id
          }
        })
        .catch((err) => { });
    },
    async getCustomers() {
      await axios
        .get("/api/customer/all")
        .then((result) => {
          this.customers = result.data;
          if (result.data.length == 1) {
            this.form.customer_id = result.data[0]._id
          }
        })
        .catch((err) => { });
    },
    addProduct() {
      this.products.push({
        id: Math.ceil(Math.random() * 8498615),
        sku: null,
        quantity: null,
        purchase: null,
        tax: 20,
        price: null,
        no_tax_price: null,
        discount: 0,
        discountType: '%',
        isDiscount: false
      });
    },
    async productControl(type, value, next, newSection, id) {
      if (type == "sku") {
        axios
          .get(`/api/product/sku/${value}`)
          .then((result) => {
            var qtyInput = document.getElementById(next);
            qtyInput.disabled = false;
            setTimeout(() => {
              qtyInput.focus();
            }, 500);
          })
          .catch(async (err) => {
            var qtyInput = document.getElementById(next);
            qtyInput.value = 0;
            var skuInput = document.getElementById(newSection);
            skuInput.value = "";
            const c = this.products.find((i) => i.id == id);
            c.sku = "";
            await notification.warning({
              message: this.$t("Error"),
              description: this.$t("Error! Product not found"),

              duration: 1.5,
            });
          });
      } else if (type == "qty") {
        var priceInput = document.getElementById(next);
        priceInput.disabled = false;
        setTimeout(() => {
          priceInput.focus();
        }, 500);
      } else if (type == "price") {
        var btn = document.getElementById(next);
        btn.click();
        setTimeout(() => {
          var skuInput = document.getElementById(newSection);
          skuInput.focus();
        }, 500);
      }
    },
    removeProduct(id) {
      const check = this.products.find((e) => e.id == id);
      const index = this.products.indexOf(check);
      this.products.splice(index, 1);
    },
    handleNoTaxPrice(id, noTaxPrice, tax, price) {
      // const check = this.products.find(e => e.id == id)
      // var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
      // check.price = newPrice
      const check = this.products.find(e => e.id === id);
      let newPrice;

      if (check.isDiscount) {
        // İndirimli fiyatın hesaplanması için gerekli işlemleri yapın
        const discountedNoTaxPrice = this.calculateDiscountedNoTaxPrice(noTaxPrice, check.discount, check.discountType);
        newPrice = (Number(discountedNoTaxPrice) + (Number(discountedNoTaxPrice) * Number(tax) / 100)).toFixed(2);
      } else {
        newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2);
      }

      check.price = newPrice;
    },
    calculateDiscountedNoTaxPrice(noTaxPrice, discount, discountType) {
      let discountedNoTaxPrice = noTaxPrice;

      if (discountType === '%') {
        const discountAmount = (noTaxPrice * discount) / 100;
        discountedNoTaxPrice -= discountAmount;
      } else if (discountType === '$') {
        discountedNoTaxPrice -= discount;
      }

      return discountedNoTaxPrice;
    },
    handleTax(id, noTaxPrice, tax, price) {
      // const check = this.products.find(e => e.id == id)
      // var newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2)
      // check.price = newPrice
      const check = this.products.find(e => e.id === id);
      let newPrice;

      if (check.isDiscount) {
        const discountedNoTaxPrice = this.calculateDiscountedNoTaxPrice(noTaxPrice, check.discount, check.discountType);
        newPrice = (Number(discountedNoTaxPrice) + (Number(discountedNoTaxPrice) * Number(tax) / 100)).toFixed(2);
      } else {
        newPrice = (Number(noTaxPrice) + (Number(noTaxPrice) * Number(tax) / 100)).toFixed(2);
      }

      check.price = newPrice;
    },
    async handlePrice(id, noTaxPrice, tax, price) {
      // const check = this.products.find(e => e.id == id)
      // var newPurchase = (Number(price) / (1 + Number(tax) / 100)).toFixed(2)
      // check.no_tax_price = newPurchase
      const check = this.products.find(e => e.id === id);

      if (check.isDiscount) {
        if (check.discountType == '%') {
          if (check.discount == 100) {
            check.price = 0
          } else {
            const targetPrice = price; // Hedef fiyat (100 TL)
            const taxRate = check.tax; // Vergi oranı (%)
            const discountRate = check.discount; // İndirim oranı (%)
            // İndirimsiz vergisiz fiyatı hesapla
            const noTaxPrice = targetPrice / (1 + taxRate / 100) / (1 - discountRate / 100);
            console.log(Number(noTaxPrice).toFixed(2));
            check.no_tax_price = Number(noTaxPrice).toFixed(2)
          }
        } else {
          var salePrice = price; // Satış fiyatı
          var taxRate = check.tax; // Vergi yüzdesi
          var discountAmount = check.discount; // İndirim miktarı

          // Vergisiz fiyatı bul
          var salePriceWithoutTax = salePrice / (1 + (taxRate / 100));

          // İndirimi vergisiz fiyattan çıkar
          var salePriceWithoutDiscount = (salePriceWithoutTax + Number(discountAmount)).toFixed(2);
          check.no_tax_price = salePriceWithoutDiscount
        }
      } else {
        const newNoTaxPrice = (Number(price) / (1 + Number(tax) / 100)).toFixed(2);
        check.no_tax_price = newNoTaxPrice;
      }
    },
  },
};
</script>

<style  scoped>
.invoiceProducts {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 15px;
  width: 100%;
}

.prod-item {
  width: 33%;
}

.prod-total {
  width: 15%;
}

@media only screen and (max-width: 800px) {
  .prod-item {
    width: 100%;
  }

  .prod-total {
    width: 100%;
  }

  .invoiceProducts {
    display: grid;
    width: 100%;
    /* justify-content: space-between;
  align-items: center;
  gap: 15px; */
  }
}
</style>
