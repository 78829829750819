<template>
    <div>
        <a-layout-content :style="{
            margin: '0px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
        }">
            <a-table :scroll="{ x: 1000 }" v-if="loader" :columns="columns" :data-source="list" @change="onChange">
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.key === 'btn'">
                        <div class="" style="
                                                          display: flex;
                                                          justify-content: center;
                                                          align-items: center;
                                                          gap: 15px;
                                                        ">
                            <a-button @click="showDrawer(text)">
                                <EyeOutlined />
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.key === 'branch_id'">
                        <span>{{ record.branch_id != null ? record.branch_id.name : "-" }}</span>
                    </template>
                    <template v-if="column.key === 'customer_id'">
                        <span>{{ record.customer_id != null ? record.customer_id.name : "-" }}</span>
                    </template>
                    <template v-if="column.key === 'invoice_date'">
                        <span>{{ text ? text.substr(0, 10) : "-" }}</span>
                    </template>
                    <template v-if="column.key === 'amount'">
                        <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}`
                            + moneyFormat(text).substr(1) }}</span>
                                <!-- <span>{{ record.currency }}{{ moneyFormat(text).substr(1) }}</span> -->
                    </template>
                    <template v-if="column.key === 'products'">
                        <span>{{
                            record.products
                            ? text.reduce((accumulator, object) => {
                                return accumulator + object.quantity;
                            }, 0)
                            : "0"
                        }}</span>
                    </template>
                    <template v-if="column.key === 'remainingAmount'">
                        <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}`
                            + moneyFormat(text).substr(1) }}</span>
                    </template>
                    <template v-if="column.key === 'type'">
                        <div class="" style="display: flex;justify-content: start;align-items: center;">
                            <div class="">
                                <a-tag v-if="text == 'sell'" message="Sell" color="green">{{
                                    $t("Sell")
                                }}</a-tag>
                                <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
                            </div>
                            <div class="">
                                <a-tag v-if="record.remainingAmount == 0" message="Paid" color="blue">{{
                                    $t("Paid")
                                }}</a-tag>
                                <a-tag v-else message="Return" color="orange">{{ $t("No Paid") }}</a-tag>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <Loader v-else />
            <a-drawer v-if="visibleDetailLoad" :title="`${$t('Invoice')} ` + invoiceDetail.invoice_no" placement="right"
                :closable="false" v-model:visible="visibleDetail" :after-visible-change="afterVisibleChange">
                <InvoiceDetail @invoiceDetail="invoiceDetail = $event" :data="invoiceDetail" />
            </a-drawer>
        </a-layout-content>
    </div>
</template>
  
<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
} from "@ant-design/icons-vue";
import Button from "@/components/invoice/Button.vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import InvoiceDetail from "@/components/invoice/InvoiceDetail.vue";
import InvoiceFilter from "@/components/invoice/InvoiceFilter.vue";

export default {
    props: ['currency', 'date','getCurrentData','list'],
    data() {
        return {
            loader: false,
            visibleDetail: true,
            visibleDetailLoad: false,
            invoiceDetail: {},
            columns: [
                {
                    title: this.$t("Invoice No"),
                    dataIndex: "invoice_no",
                    key: "invoice_no",
                },
                {
                    title: this.$t("Branch"),
                    dataIndex: "branch_id",
                    key: "branch_id",
                },
                {
                    title: this.$t("Customer"),
                    dataIndex: "customer_id.name",
                    key: "customer_id",
                },
                {
                    title: this.$t("Type"),
                    dataIndex: "type",
                    key: "type",
                },
                {
                    title: this.$t("Quantity"),
                    dataIndex: "products",
                    key: "products",
                },
                {
                    title: this.$t("Amount"),
                    dataIndex: "amount",
                    key: "amount",
                },
                {
                    title: this.$t("No Paid"),
                    dataIndex: "remainingAmount",
                    key: "remainingAmount",
                },
                {
                    title: this.$t("Date"),
                    dataIndex: "invoice_date",
                    key: "invoice_date",
                },
                {
                    title: this.$t("Action"),
                    dataIndex: "_id",
                    key: "btn",
                },
            ],
            data: [],
            filter: {},
            filterText: "",
        };

    },
    created() {
        this.getData();
    },
    methods: {
        showDrawer(id) {
            this.visibleDetail = true;
            axios
                .get(`/api/invoice/${id}`)
                .then((result) => {
                    this.invoiceDetail = result.data;
                    this.visibleDetailLoad = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        afterVisibleChange(bool) {
            if (bool == false) {
                this.invoiceDetail = {};
                this.visibleDetailLoad = false;
                this.getData()
                this.getCurrentData()
            }
        },
        async getData() {

            if (this.currency) {
                this.filterText = this.filterText + `currency=${this.currency}&`;
            }
            if (this.date) {
                this.filterText =
                    this.filterText +
                    `startDateQuery=${this.date[0]}&endDateQuery=${this.date[1]}&`;
            }
            await axios
                .get(`/api/sale-report/daily-sales-amount-no-paid?${this.filterText}`)
                .then((result) => {
                    this.data = result.data;
                    this.loader = true;
                })
                .catch((err) => { });
        },
        moneyFormat(number) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            });
            return formatter.format(number);
        },
    },
    components: {
        Loader,
        PageTitle,
        EditOutlined,
        Button,
        DeleteOutlined,
        EyeOutlined,
        InvoiceDetail,
        InvoiceFilter,
    },
};
</script>
  
<style lang="scss" scoped></style>
  