<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Income & Exprenses')" />
      <Button :getData="getData" />
    </div>
    <IncomeFilter
      @itemFilter="data = $event"
      :loader="loader"
      @newLoad="loader = $event"
    />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-table
        :scroll="{ x: 1000 }"
        v-if="loader"
        :columns="columns"
        :data-source="data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
              "
            >
              <a-button @click="showDrawer(text)"><EyeOutlined /></a-button>

              <router-link :to="'/income-expenses/' + text">
                <a-button><EditOutlined /></a-button>
              </router-link>
              <a-button @click="remove(text)"><DeleteOutlined /></a-button>
            </div>
          </template>
          <template v-if="column.key === 'title'">
            <span>{{ text }}</span>
          </template>
          <template v-if="column.key === 'date'">
            <span>{{ record.date ? text.substr(0, 10) : "-" }}</span>
          </template>
          <template v-if="column.key === 'price'">
            <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text*-1).substr(1) : `${record.currency}` + moneyFormat(text).substr(1) }}</span>
            <!-- <span>{{ record.currency }}{{ moneyFormat(text).substr(1) }}</span> -->
          </template>
          <template v-if="column.key === 'products'">
            <span>{{
              record.products
                ? text.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                  }, 0)
                : "0"
            }}</span>
          </template>
          <template v-if="column.key === 'type'">
            <a-tag v-if="text == 'income'" message="Income" color="green">{{
              $t("Income")
            }}</a-tag>
            <a-tag v-else message="Exprens" color="yellow">{{ $t("Exprens") }}</a-tag>
          </template>
        </template>
      </a-table>
      <Loader v-else />
      <a-drawer
        v-if="visibleDetailLoad"
        :title="incomeDetail.income_no"
        placement="right"
        :closable="false"
        v-model:visible="visibleDetail"
        :after-visible-change="afterVisibleChange"
      >
        <IncomeDetail :data="incomeDetail" />
      </a-drawer>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import Button from "@/components/income/Button.vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import IncomeDetail from "@/components/income/IncomeDetail.vue";
import IncomeFilter from "@/components/income/IncomeFilter.vue";

export default {
  data() {
    return {
      loader: false,
      visibleDetail: true,
      visibleDetailLoad: false,
      incomeDetail: {},
      columns: [
        {
          title: this.$t("NO"),
          dataIndex: "income_no",
          key: "income_no",
        },
        {
          title: this.$t("Title"),
          dataIndex: "title",
          key: "title",
        },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Price"),
          dataIndex: "price",
          key: "price",
        },
        {
          title: this.$t("Date"),
          dataIndex: "date",
          key: "date",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showDrawer(id) {
      this.visibleDetail = true;
      axios
        .get(`/api/income/${id}`)
        .then((result) => {
          this.incomeDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.incomeDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    async getData() {
      await axios
        .get("/api/income/all")
        .then((result) => {
          this.data = result.data;
          this.loader = true;
        })
        .catch((err) => {});
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content:
          this.$t("If you delete this data, all related data will be deleted. Are you sure?"),
        onOk: async () => {
          await axios
            .delete(`/api/income/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: {
    Loader,
    PageTitle,
    EditOutlined,
    Button,
    DeleteOutlined,
    EyeOutlined,
    IncomeDetail,
    IncomeFilter,
  },
};
</script>

<style lang="scss" scoped></style>
