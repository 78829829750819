<template>
  <div>
    <a-layout-content :style="{
      margin: '15px 16px',
      padding: '12px',
      background: '#fff',
    }">
      <a-form layout="vertical">
        <a-row>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('SKU')" style="margin: 10px">
              <a-input v-model:value="filter.sku" :placeholder="$t('SKU')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('PName')" style="margin: 10px">
              <a-input v-model:value="filter.name" :placeholder="$t('PName')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Category')" style="margin: 10px">
              <a-select v-model:value="filter.category_id" :placeholder="$t('Category')">
                <a-select-option v-for="(item, index) in categories" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Brand')" style="margin: 10px">
              <a-select v-model:value="filter.brand_id" :placeholder="$t('Brand')">
                <a-select-option
                  v-for="(item, index) in brands"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Pattern')" style="margin: 10px">
              <a-select v-model:value="filter.pattern_id" :placeholder="$t('Pattern')">
                <a-select-option
                  v-for="(item, index) in patterns"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Material')" style="margin: 10px">
              <a-select v-model:value="filter.material_id" :placeholder="$t('Material')">
                <a-select-option
                  v-for="(item, index) in materials"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Season')" style="margin: 10px">
              <a-select v-model:value="filter.season_id" :placeholder="$t('Season')">
                <a-select-option
                  v-for="(item, index) in season"
                  :key="index"
                  :value="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Sale Price')" style="margin: 10px">
              <a-select v-model:value="filter.sale_price" :placeholder="$t('Sale Price')">
                <a-select-option :value="1">{{ `${user.firm.currency}0-${user.firm.currency}100` }}</a-select-option>
                <a-select-option :value="2">{{ `${user.firm.currency}100-${user.firm.currency}500` }}</a-select-option>
                <a-select-option :value="3">{{ `${user.firm.currency}500-${user.firm.currency}1.000` }}</a-select-option>
                <a-select-option :value="4">{{ `+${user.firm.currency}1.000` }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-form-item v-if="
            filter.sku ||
            filter.name ||
            filter.category_id ||
            filter.brand_id ||
            filter.pattern_id ||
            filter.material_id ||
            filter.season_id ||
            filter.sale_price
          " style="margin: 10px">
            <a-button type="primary" @click="setFilter()">{{ $t('Filter') }}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{ $t('Clear') }}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      brands: [],
      categories: [],
      patterns: [],
      materials: [],
      season: [],
      filter: {},
      filterText: "",
      loader: false,
      user: JSON.parse(localStorage.getItem("user")),

    };
  },
  mounted() {
    this.getCategory();
    // this.getBrand();
    // this.getSeason();
    // this.getPattern();
    // this.getMaterial();
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);
      if (this.filter.sku) {
        this.filterText = this.filterText + `sku=${this.filter.sku}&`;
      }
      if (this.filter.name) {
        this.filterText = this.filterText + `name=${this.filter.name}&`;
      }
      if (this.filter.category_id) {
        this.filterText = this.filterText + `category_id=${this.filter.category_id}&`;
      }
      if (this.filter.brand_id) {
        this.filterText = this.filterText + `brand_id=${this.filter.brand_id}&`;
      }
      if (this.filter.pattern_id) {
        this.filterText = this.filterText + `pattern_id=${this.filter.pattern_id}&`;
      }
      if (this.filter.material_id) {
        this.filterText = this.filterText + `material_id=${this.filter.material_id}&`;
      }
      if (this.filter.season_id) {
        this.filterText = this.filterText + `season_id=${this.filter.season_id}&`;
      }
      if (this.filter.sale_price) {
        this.filterText = this.filterText + `sale_price=${this.filter.sale_price}&`;
      }

      await axios
        .get(`/api/product/all?${this.filterText}`)
        .then(async (result) => {

          await result.data.forEach(async (e) => {
            await axios
              .get(`/api/product/is-action/${e._id}`)
              .then((res) => {
                if (res.data.status) {
                  e.isAction = false;
                } else {
                  e.isAction = true;
                }
              })
              .catch((err) => { });
          });
          this.loader = await true;
          await this.$emit("itemFilter", result.data);
          await setTimeout(() => {
            this.$emit("newLoad", this.loader);
          }, 500);
        })
        .catch((err) => { });
    },
    async getBrand() {
      await axios
        .get("/api/brand/all")
        .then((result) => {
          this.brands = result.data;
        })
        .catch((err) => { });
    },
    async getPattern() {
      await axios
        .get("/api/pattern/all")
        .then((result) => {
          this.patterns = result.data;
        })
        .catch((err) => { });
    },
    async getMaterial() {
      await axios
        .get("/api/material/all")
        .then((result) => {
          this.materials = result.data;
        })
        .catch((err) => { });
    },
    async getCategory() {
      await axios
        .get("/api/category/all")
        .then((result) => {
          this.categories = result.data;
        })
        .catch((err) => { });
    },
    async getSeason() {
      await axios
        .get("/api/season/all")
        .then((result) => {
          this.season = result.data;
        })
        .catch((err) => { });
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
