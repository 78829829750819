<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle title="Telegram" />
    </div>
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-form v-if="load" style="width: 50%">
        <a-form-item label="Telegram Bot ID">
          <a-input v-model:value="user.telegram_bot_id" placeholder="Telegram Bot ID" />
        </a-form-item>
        <a-form-item label="Telegram Chat ID">
          <a-input v-model:value="user.telegram_chat_id" placeholder="Telegram Chat ID" />
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end">
            <a-button type="primary" :loading="loadBtn" @click="update()"
              >Update</a-button
            >
          </div>
        </a-form-item>
      </a-form>
      <Loader v-else/>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  components: { PageTitle, Loader },
  data() {
    return {
      user: {},
      loadBtn: false,
      load:false
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async update() {
        this.loadBtn=true
      await axios
        .post("/api/telegram/add", {
          telegram_bot_id: this.user.telegram_bot_id,
          telegram_chat_id: this.user.telegram_chat_id,
        })
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
          description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description:this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    getUser() {
      axios
        .get("/api/auth/user")
        .then((result) => {
          this.user = result.data;
          this.load=true
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
