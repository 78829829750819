<template>
  <div>
    <a-button type="primary" @click="showModal">
      <PlusOutlined /><span>{{ $t("Create") }}</span>
    </a-button>
    <a-modal :mask-closable="false" :width="modalSize > 900 ? '45%' : '100%'" v-model:visible="visible"
      :title="$t('New Product Create')" :footer="null">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="$t('Product Variables')">
          <a-form layout="vertical">
            <a-form-item id="product-img" :label="$t('Product Image')">
              <a-input type="file" @change="handleFileUpload($event)" />
            </a-form-item>
            <a-form-item :label="$t('Product Name') + '(*)'">
              <a-input v-model:value="form.name" :placeholder="$t('Product Name')" />
            </a-form-item>
            <a-form-item :label="$t('Category') + '(*)'">
              <a-select v-model:value="form.category_id" :placeholder="$t('Category')">
                <a-select-option v-for="(item, index) in categories" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('Product Description')">
              <a-textarea v-model:value="form.description" />
            </a-form-item>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (visible = false)">{{ $t("Cancel") }}</a-button>
                <a-button :disabled="!form.name || !form.category_id" :loading="loading" style="margin-left: 5px"
                  type="primary" @click="() => (activeKey = '2')">{{
                    $t("Next") }}</a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane :disabled="!form.name || !form.category_id" key="2" :tab="$t('Barcode')">
          <a-form layout="vertical">
            <a-form-item :label="$t('SKU') + '(*)'" v-if="!isHasVariant">
              <div style="display: flex;">
                <a-input maxlength="13" :style="isHasSku ? 'border: 1px solid red;' : ''" v-model:value="form.sku"
                  @input="skuFind(form.sku)" :placeholder="$t('SKU')" />
                <a-button @click="randomSku(form.sku, 'single')">
                  <RetweetOutlined />
                </a-button>
              </div>
            </a-form-item>

            <div class="" style="display: flex; justify-content: end; margin: 10px 0px">
              <a-button v-if="!isHasVariant" @click="() => {
                isHasVariant = true
                delete form.sku
                isHasSku = false
              }" type="primary">
                <PlusOutlined />{{ $t("Add Variant") }}
              </a-button>
              <a-button v-else @click="() => {
                isHasSku = false
                isHasVariant = false;
                variants = [];
                colors = [];
                sizes = [];
              }
                " type="danger">
                <MinusOutlined />{{ $t("Delete All Variants") }}
              </a-button>
            </div>
            <div class="" style="
                                                                display: flex;
                                                                justify-content: start;
                                                                align-items: center;
                                                                gap: 15px;
                                                              " v-if="isHasVariant"></div>
            <div class="" v-if="isHasVariant">
              <h3>
                <b>{{ $t("Sizes") }}</b>
              </h3>
              <a-row>
                <a-col :span="3">
                  <a-checkbox value="xs" @change="changeVariantValue('size', 'XS')" name="type">XS</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', 'S')" value="s" name="type">S</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', 'M')" value="m" name="type">M</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', 'L')" value="l" name="type">L</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', 'XL')" value="xl" name="type">XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '2XL')" value="2xl" name="type">2XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '3XL')" value="3xl" name="type">3XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '4XL')" value="4xl" name="type">4XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '5XL')" value="5xl" name="type">5XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '6XL')" value="6xl" name="type">6XL</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', $t('Fix'))" :value="$t('Fix')" name="type">{{ $t('Fix')
                  }}</a-checkbox>
                </a-col>
              </a-row>
              <a-divider />
              <a-row>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '30')" value="30" name="type">30</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '32')" value="32" name="type">32</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '33')" value="33" name="type">33</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '34')" value="34" name="type">34</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '35')" value="35" name="type">35</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '36')" value="36" name="type">36</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '37')" value="37" name="type">37</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '38')" value="38" name="type">38</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '39')" value="39" name="type">39</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '40')" value="40" name="type">40</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '41')" value="41" name="type">41</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '42')" value="42" name="type">42</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '43')" value="43" name="type">43</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '44')" value="44" name="type">44</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('size', '45')" value="45" name="type">45</a-checkbox>
                </a-col>
              </a-row>
            </div>
            <div class="" style="margin-top: 15px" v-if="isHasVariant">
              <h3>
                <b>{{ $t("Colors") }}</b>
              </h3>
              <a-row style="display: flex; gap: 10px">
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Black'))" value="black" name="type">{{ $t("Black")
                  }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('White'))" value="white" name="type">{{ $t("White")
                  }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Red'))" value="red" name="type">{{ $t("Red")
                  }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Blue'))" value="blue" name="type">{{ $t("Blue")
                  }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Yellow'))" value="yellow" name="type">{{
                    $t("Yellow") }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Green'))" value="green" name="type">{{ $t("Green")
                  }}</a-checkbox>
                </a-col>
                <a-col :span="3">
                  <a-checkbox @change="changeVariantValue('color', $t('Orange'))" value="orange" name="type">{{
                    $t("Orange") }}</a-checkbox>
                </a-col>
              </a-row>
            </div>
            <div class="" v-if="colors.length > 0 || sizes.length > 0"
              style="display: flex; justify-content: end; margin: 15px 0px">
              <a-button @click="createVariations()" type="primary">
                <PlusOutlined /> {{ $t("Create Variations") }}
              </a-button>
            </div>
            <div class="" v-if="(colors.length > 0 || sizes.length > 0) && variants.length > 0" style="margin-top: 15px">
              <a-form-item ::label="`${item.size ? item.size : ''} ${item.color ? item.color : ''
                } SKU`" v-for="(item, index) in variants" :key="index">
                <label for="">{{
                  `${$t(`${item.color}`)} - ${$t(`${item.size}`)}` + '(*)'
                }}</label>
                <div style="display: flex;">
                  <a-input maxlength="13" :style="isHasSku ? 'border: 1px solid red;' : ''" v-model:value="item.sku"
                    :placeholder="`${$t(`${item.color}`)} - ${$t(`${item.size}`)}`" @input="skuFind(item.sku)" />
                  <a-button @click="randomSku(item.id, 'multi')">
                    <RetweetOutlined />
                  </a-button>
                </div>
              </a-form-item>
            </div>
            <a-form-item style="margin-top: 25px;">
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '1')">{{ $t("Back") }}</a-button>
                <a-button
                  :disabled="(variants.length == 0 || variants.filter(e => e.sku == '').length > 0 || variants.filter(e => !e.sku).length > 0) && (!form.sku) || (isHasSku)"
                  :loading="loading" style="margin-left: 5px" type="primary" @click="() => (activeKey = '3')">
                  {{ $t("Next") }}
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('Price')"
          :disabled="(variants.length == 0 || variants.filter(e => e.sku == '').length > 0 || variants.filter(e => !e.sku).length > 0) && (!form.sku) || (isHasSku)">
          <a-form layout="vertical">
            <a-form-item :label="$t('Tax')">
              <a-select v-model:value="form.tax" :placeholder="$t('Tax')">
                <a-select-option :value="0">%0</a-select-option>
                <a-select-option :value="8">%8</a-select-option>
                <a-select-option :value="10">%10</a-select-option>
                <a-select-option :value="18">%18</a-select-option>
                <a-select-option :value="20">%20</a-select-option>
                <a-select-option :value="24">%24</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="`${$t('Purchase')} (${$t('tax included')})`">
              <a-input min="0" type="number" v-model:value="form.purchase_cost" :placeholder="$t('Purchase')" />
            </a-form-item>
            <a-form-item :label="`${$t('Sale Price')} (${$t('tax included')})`">
              <a-input min="1" type="number" v-model:value="form.sale_price" :placeholder="$t('Sale Price')" />
            </a-form-item>
            <a-row>
              <a-col>
                <div>
                  {{ $t("Tax") }} : <b>{{ `%${form.tax}` }}</b>
                </div>
                <div>
                  {{ $t("Sale Price") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.sale_price).substring(1)}`
                  }}</b>
                </div>
                <div>
                  {{ $t("No Tax Sale Price") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.sale_price / (1 +
                    form.tax / 100)).substring(1)}` }}</b>
                </div>
                <div>
                  {{ $t("Purchase") }} : <b>{{ totalPurcase("total") }}</b>
                </div>
                <div>
                  {{ $t("No Tax Purchase") }} : <b>{{ `${user.firm.currency}${moneyFormat(form.purchase_cost / (1 +
                    form.tax / 100)).substring(1)}` }}</b>
                </div>
              </a-col>
            </a-row>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '2')">{{ $t("Back") }}</a-button>
                <a-button
                  :disabled="totalPurcase('total') == `${this.user.firm.currency}${this.moneyFormat(0).substr(1)}` || form.sale_price <= 0"
                  :loading="loading" style="margin-left: 5px" type="primary" @click="() => (activeKey = '4')">{{
                    $t('Next') }}</a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane
          :disabled="totalPurcase('total') == `${this.user.firm.currency}${this.moneyFormat(0).substr(1)}` || form.sale_price <= 0"
          key="4" :tab="$t('Stocks')">
          <a-form layout="vertical">
            <a-form-item v-if="!isHasVariant" v-for="(item, index) in stocks" :key="index" :label="item.name">
              <a-input type="number" min="0" v-model:value="item.qty" :placeholder="item.name" />
            </a-form-item>
            <div v-else>
              <a-form-item v-for="(item, index) in variants">
                <h3>{{ item.sku }} ({{ `${item.color} - ${item.size}` }})</h3>
                <a-form-item v-for="(val, index2) in item.stocks" :key="index2" :label="val.name">
                  <a-input type="number" min="0" v-model:value="val.qty" :placeholder="val.name" />
                </a-form-item>
                <a-divider />
              </a-form-item>
            </div>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '3')">{{ $t("Back") }}</a-button>
                <a-popconfirm :title="$t('Are you sure?')" :ok-text="$t('Yes')" :cancel-text="$t('No')"
                  @confirm="handleOk()" @cancel="cancel">
                  <a-button :loading="loading" style="margin-left: 5px" type="primary">{{ $t("Create")
                  }}</a-button>
                </a-popconfirm>
              </div>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import { MinusOutlined, PlusOutlined, RetweetOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  props: ["getData"],
  components: { PlusOutlined, MinusOutlined, RetweetOutlined },
  data() {
    return {
      modalSize: window.innerWidth,
      isHasColor: false,
      isHasSize: false,
      isHasVariant: false,
      visible: false,
      form: {
        sale_price: null,
        dollar_rate: 19,
        tax: 20,
        material_cost: 0,
        purchase_cost: 0,
        cut_cost: 0,
        planting_cost: 0,
        print_cost: 0,
        stone_cost: 0,
        embroidery_cost: 0,
        washing_cost: 0,
        accessory_cost: 0,
        ironing_cost: 0,
        packaging_cost: 0,
        other_cost: 0,
      },
      loading: false,
      categories: [],
      patterns: [],
      materials: [],
      brands: [],
      seasons: [],
      activeKey: "1",
      variants: [],
      sizes: [],
      colors: [],
      user: JSON.parse(localStorage.getItem("user")),
      isHasSku: false,
      stocks: []
    };
  },
  watch: {
    'form.purchase_cost': {
      handler: function (newVal, oldVal) {
        if (this.variants.length > 0) {
          this.variants.forEach(variant => {
            variant.stocks.forEach(stock => {
              stock.purchase = Number(newVal);
            });
          });
        } else {
          const stocks = this.stocks; // this.stocks'u bir değişkene atadık
          stocks.forEach(stock => {
            stock.purchase = Number(newVal);
          });
        }
      },
      deep: true
    }
  },
  created() {
    this.getCategory();
    this.getBranchs();
  },
  methods: {
    randomSku(id, type) {
      if (type == 'single') {
        this.form.sku = this.generateRandom()
      } else {
        const check = this.variants.find(e => e.id == id)
        check.sku = this.generateRandom()
      }
    },
    generateRandom() {
      const min = 1000000000000; // Minimum 13 haneli sayı
      const max = 9999999999999; // Maximum 13 haneli sayı
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    totalPurcase(params) {
      var data =
        Number(this.form.material_cost) +
        Number(this.form.purchase_cost) +
        Number(this.form.cut_cost) +
        Number(this.form.planting_cost) +
        Number(this.form.print_cost) +
        Number(this.form.stone_cost) +
        Number(this.form.embroidery_cost) +
        Number(this.form.washing_cost) +
        Number(this.form.accessory_cost) +
        Number(this.form.ironing_cost) +
        Number(this.form.packaging_cost) +
        Number(this.form.other_cost);
      if (params == "total") {
        return `${this.user.firm.currency}${this.moneyFormat(data).substr(1)}`;
      } else {
        return data;
      }
    },
    getBranchs() {
      axios.get('/api/branch/all').then(async (result) => {
        var data = []
        await result.data.forEach(async e => {
          e.qty = await 0
          e.purchase = await this.form.purchase_cost
          await data.push(e)
        });
        this.stocks = data
      }).catch((err) => {

      });
    },
    skuFind(sku) {
      axios
        .get(`/api/product/sku/${sku}`)
        .then(async (result) => {

          this.isHasSku = true
          await notification.error({
            message: this.$t("Error"),
            description: `${this.$t("this barcode is used")}: ${sku}`,
            duration: 1.5,
          });

        }).catch(async (err) => {
          if (this.variants.length > 0) {
            var check = this.variants.filter(e => e.sku == sku)

            if (check.length > 1) {
              this.isHasSku = true
              await notification.error({
                message: this.$t("Error"),
                description: `${this.$t("this barcode is used")}: ${sku}`,
                duration: 1.5,
              });
            } else {
              this.isHasSku = false
            }
          } else {
            this.isHasSku = false
          }
        })
    },
    async handleFileUpload(event) {
      this.form.img = event.target.files[0];
    },
    async createVariations() {
      if (this.colors.length == 0 || this.sizes.length == 0) {
        notification.error({
          message: this.$t("Error"),
          description: this.$t("you must choose at least one size and one color"),
          duration: 1.5,
        });
      } else {
        var attributes = { color: this.colors, size: this.sizes };
        let attrs = [];

        for (const [attr, values] of Object.entries(attributes))
          attrs.push(values.map((v) => ({ [attr]: v })));
        attrs = attrs.reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))));
        console.log(attrs);
        await attrs.forEach(e => {
          e.id = Math.ceil(Math.random() * 8498615),
            e.stocks = []
          this.stocks.forEach(f => {
            var stocks = {}
            stocks._id = f._id
            stocks.name = f.name
            stocks.qty = 0
            stocks.purchase = this.form.purchase_cost ? this.form.purchase_cost : 0
            e.stocks.push(stocks)
          });
        });
        console.log(attrs);
        this.variants = attrs;
      }
    },
    changeVariantValue(type, value) {
      if (type == "size") {
        const checkSize = this.sizes.filter((e) => e == value);
        if (checkSize.length == 0) {
          this.sizes.push(value);
          console.log(this.sizes);
        } else {
          const item = this.sizes.find((e) => e == value);
          const index = this.sizes.indexOf(item);
          this.sizes.splice(index, 1);
          console.log(this.sizes);
        }
      } else {
        // beden seçimi
        const checkColor = this.colors.filter((e) => e == value);
        if (checkColor.length == 0) {
          this.colors.push(value);
          console.log(this.colors);
        } else {
          const item = this.colors.find((e) => e == value);
          const index = this.colors.indexOf(item);
          this.colors.splice(index, 1);
          console.log(this.colors);
        }
      }
    },
    async getMaterial() {
      await axios
        .get("/api/material/all")
        .then(async (result) => {
          this.materials = result.data;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
    async getCategory() {
      await axios
        .get("/api/category/all")
        .then(async (result) => {
          this.categories = result.data;
          if (result.data.length == 1) {
            this.form.category_id = result.data[0]._id
          }
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    showModal() {
      this.visible = true;
    },
    async handleOk() {
      // this.loading = true;

      let formData = new FormData();
      await formData.append("img", this.form.img);
      await formData.append("name", this.form.name);
      await formData.append("category_id", this.form.category_id);
      await formData.append("pattern_id", this.form.pattern_id);
      await formData.append("brand_id", this.form.brand_id);
      await formData.append("material_id", this.form.material_id);
      await formData.append("season_id", this.form.season_id);
      await formData.append("purchase_price", this.form.purchase_price);
      await formData.append("sale_price", this.form.sale_price);
      if (this.form.description) {
        await formData.append("description", this.form.description);
      }
      await formData.append("telegram", this.form.telegram);
      await formData.append("material_cost", this.form.material_cost);
      await formData.append("purchase_cost", this.form.purchase_cost);
      await formData.append("cut_cost", this.form.cut_cost);
      await formData.append("planting_cost", this.form.planting_cost);
      await formData.append("print_cost", this.form.print_cost);
      await formData.append("stone_cost", this.form.stone_cost);
      await formData.append("embroidery_cost", this.form.embroidery_cost);
      await formData.append("washing_cost", this.form.washing_cost);
      await formData.append("accessory_cost", this.form.accessory_cost);
      await formData.append("ironing_cost", this.form.ironing_cost);
      await formData.append("packaging_cost", this.form.packaging_cost);
      await formData.append("dollar_rate", this.form.dollar_rate);
      await formData.append("other_cost", this.form.other_cost);
      await formData.append("tax", this.form.tax);
      await formData.append("stocks", JSON.stringify(this.stocks));

      if (this.variants.length > 0) {
        await formData.append("variants", JSON.stringify(this.variants));
      } else {
        await formData.append("sku", this.form.sku);
      }
      // console.log(this.stocks);
      await axios.post("/api/product/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (result) => {
          this.$emit('loader', false)
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.visible = false;
          this.form = {
            sale_price: null,
            dollar_rate: 19,
            tax: 20,
            material_cost: 0,
            purchase_cost: 0,
            cut_cost: 0,
            planting_cost: 0,
            print_cost: 0,
            stone_cost: 0,
            embroidery_cost: 0,
            washing_cost: 0,
            accessory_cost: 0,
            ironing_cost: 0,
            packaging_cost: 0,
            other_cost: 0,
          }
          this.activeKey = '1'

          setTimeout(() => {
            this.getData();
          }, 3000);
        })
        .catch(async (err) => {
          this.loading = false;
          console.log('====================================');
          console.log(err);
          console.log('====================================');
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  #product-img {
    display: none;
  }
}
</style>
