<template>
  <div>
    <a-layout-content
      :style="{
        margin: '15px 16px',
        padding: '12px',
        background: '#fff',
      }"
    >
      <a-form layout="inline">
        <a-row>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('NO')" style="margin: 10px">
              <a-input v-model:value="filter.income_no" :placeholder="$t('NO')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Title')" style="margin: 10px">
              <a-input v-model:value="filter.title" :placeholder="$t('Title')" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Price')" style="margin: 10px">
              <a-select v-model:value="filter.price" :placeholder="$t('Price')">
                <a-select-option :value="1">$0-$100</a-select-option>
                <a-select-option :value="2">$100-$500</a-select-option>
                <a-select-option :value="3">$500-$1.000</a-select-option>
                <a-select-option :value="4">$1.000-$5.000</a-select-option>
                <a-select-option :value="5">$5.000-$10.000</a-select-option>
                <a-select-option :value="6">$10.000-$50.000</a-select-option>
                <a-select-option :value="7">$50.000-$100.000</a-select-option>
                <a-select-option :value="8">+$100.000</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Type')" style="margin: 10px">
              <a-select v-model:value="filter.type" :placeholder="$t('Type')">
                <a-select-option :value="1">{{$t('Income')}}</a-select-option>
                <a-select-option :value="2">{{$t('Exprens')}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :xl="6" :md="12">
            <a-form-item :label="$t('Date')" style="margin: 10px">
                <a-range-picker v-model:value="filter.date" />
            </a-form-item>
          </a-col>
          <a-form-item
            v-if="filter.income_no || filter.title|| filter.date || filter.price || filter.type"
            style="margin: 10px"
          >
            <a-button type="primary" @click="setFilter()">{{$t('Filter')}}</a-button>
            <a-button style="margin-left: 10px" @click="cleanFilter()">{{$t('Clear')}}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      customer: [],
      branchs: [],
      filter: {},
      filterText: "",
      loader: false
    };
  },
  methods: {
    async setFilter() {
      this.filterText = "";
      this.loader = false;
      this.$emit("newLoad", this.loader);

      if (this.filter.income_no) {
        this.filterText = this.filterText + `income_no=${this.filter.income_no}&`;
      }
      if (this.filter.title) {
        this.filterText = this.filterText + `title=${this.filter.title}&`;
      }
      if (this.filter.price) {
        this.filterText = this.filterText + `price=${this.filter.price}&`;
      }
      if (this.filter.type) {
        this.filterText = this.filterText + `type=${this.filter.type}&`;
      }
        if (this.filter.date) {
          this.filterText = this.filterText + `date=${this.filter.date}&`;
        }

      await axios
        .get(`/api/income/all?${this.filterText}`)
        .then((result) => {
          this.loader = true;
          this.$emit("itemFilter", result.data);
          this.$emit("newLoad", this.loader);
        })
        .catch((err) => {});
    },
    cleanFilter() {
      this.loader = false;
      this.$emit("newLoad", this.loader);

      this.filter = {};
      this.filterText = "";
      this.setFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
