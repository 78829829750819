<template>
  <div>
    <a-button type="primary" @click="showModal">
      <PlusOutlined /><span>{{ $t("Create") }}</span>
    </a-button>
    <a-modal :mask-closable="false" v-model:visible="visible" :title="$t('New Transfer Create')" :footer="null">
      <a-form layout="vertical">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" :tab="$t('Branch')">
            <a-form-item :label="$t('From Branch')">
              <a-select v-model:value="form.from_branch_id" :placeholder="$t('From Branch')">
                <a-select-option v-for="(item, index) in branchs" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('To Branch')">
              <a-select v-model:value="form.to_branch_id" :placeholder="$t('To Branch')">
                <a-select-option v-for="(item, index) in branchs" :key="index" :value="item._id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('Date')">
              <a-date-picker v-model:value="form.date" />
            </a-form-item>
            <a-form-item :label="$t('Description')">
              <a-textarea v-model:value="form.desc" :placeholder="$t('Description')" />
            </a-form-item>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (visible = false)">{{ $t("Cancel") }}</a-button>
                <a-button :loading="loading" style="margin-left: 5px" type="primary"
                  :disabled="!form.to_branch_id || !form.from_branch_id || !form.date" @click="() => (activeKey = '2')">{{
                    $t("Next") }}</a-button>
              </div>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane key="2" :disabled="!form.to_branch_id || !form.from_branch_id || !form.date" :tab="$t('Products')"
            force-render>
            <div class="" style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
              " v-for="(item, index) in products" :key="index">
              <a-form-item style="width: 100%">
                <a-select v-model:value="item.sku" show-search label-in-value :filter-option="true" style="width: 100%"
                  :not-found-content="isSkuFind ? undefined : null" :options="skuDatas" @search="searchSku" @change="(data) =>
                    handleProductSku(item.id, data.option.value, 1, data.option.price, data.option.purchase)
                    " :placeholder="$t('SKU')">
                  <template v-if="isSkuFind" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-form-item>
              <a-form-item style="width: 30%">
                <a-input :id="'quantity-' + index" v-model:value="item.quantity" :placeholder="$t('Quantity')" />
              </a-form-item>
              <!-- <a-form-item>
            <a-input
              :id="'sku-' + index"
              v-model:value="item.sku"
              :placeholder="$t('SKU')"
              v-on:keydown.enter.prevent="
                productControl(
                  'sku',
                  item.sku,
                  `quantity-${index}`,
                  `sku-${index}`,
                  item.id
                )
              "
              v-on:blur="
                productControl(
                  'sku',
                  item.sku,
                  `quantity-${index}`,
                  `sku-${index}`,
                  item.id
                )
              "
            />
          </a-form-item>
          <a-form-item>
            <a-input
              :id="'quantity-' + index"
              disabled
              v-model:value="item.quantity"
              :placeholder="$t('Quantity')"
              v-on:keydown.enter.prevent="
                productControl(
                  'qty',
                  item.quantity,
                  `btn-${index}`,
                  `sku-${index + 1}`
                )
              "
            />
          </a-form-item> -->
              <a-form-item>
                <div class="" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                  ">
                  <a-button v-if="products.length > 1 && products.length != index + 1" @click="removeProduct(item.id)">
                    <MinusOutlined />
                  </a-button>
                  <!-- <a-button
                v-if="products.length > 1 && products.length == index + 1"
                @click="removeProduct(item.id)"
                ><MinusOutlined
              /></a-button>
              <a-button
                :id="'btn-' + index"
                @click="addProduct()"
                v-if="products.length == index + 1"
                ><PlusOutlined
              /></a-button> -->
                </div>
              </a-form-item>
            </div>
            <a-form-item>
              <div class="" style="display: flex; justify-content: end; align-items: center">
                <a-button @click="() => (activeKey = '1')">{{ $t("Back") }}</a-button>
                <a-popconfirm :title="$t('Are you sure?')" :ok-text="$t('Yes')" :cancel-text="$t('No')"
                  @confirm="handleOk()" @cancel="cancel">
                  <a-button :loading="loading" style="margin-left: 5px" type="primary">{{ $t("Create")
                  }}</a-button>
                </a-popconfirm>
              </div>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { MinusOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import dayjs from "dayjs";

export default {
  props: ["getData"],
  components: { PlusOutlined, MinusOutlined },
  created() {
    this.form.date = dayjs()
    this.getBranchs();
  },
  data() {
    return {
      visible: false,
      form: {},
      loading: false,
      activeKey: "1",
      branchs: [],
      skuDatas: [],
      isSkuFind: false,
      products: [
        {
          id: Math.ceil(Math.random() * 8498615),
          sku: "",
          quantity: null,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async searchSku(sku) {
      this.isSkuFind = true;
      this.skuDatas = await [];
      await axios
        .get(`/api/product/find-product-sku?sku=${sku}`)
        .then((result) => {
          this.skuDatas = result.data;
          this.isSkuFind = false;
        })
        .catch((err) => { });
    },
    async handleProductSku(id, sku, quantity, price, purchase) {
      const check = this.products.find((s) => s.id == id);
      if (check) {
        check.sku = sku;
        check.quantity = Number(quantity);
        check.price = Number(price);
        check.purchase = Number(purchase);
      }
      await this.addProduct();
    },
    async handleOk() {
      this.loading = true;
      const emptyProducts = this.products.filter((e) => e.sku == "" || e.quantity == 0);

      if (emptyProducts.length > 0) {
        emptyProducts.forEach((element) => {
          const check = this.products.find((e) => e.id == element.id);
          const index = this.products.indexOf(check);
          this.products.splice(index, 1);
        });
      }

      var data = {
        from_branch_id: this.form.from_branch_id,
        to_branch_id: this.form.to_branch_id,
        products: this.products,
        date: this.form.date,
        desc: this.form.desc,
      };

      for (const e of data.products) {
        e.sku = e.sku.split('///*%&&%*///')[0];
      }

      await axios
        .post("/api/transfer/add", data)
        .then(async (result) => {
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.visible = false;
          this.form = {
            date: dayjs()
          };
          this.getData();
          this.activeKey = "1"
          this.products = [
            {
              id: Math.ceil(Math.random() * 8498615),
              sku: "",
              quantity: null,
            },
          ];
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async productControl(type, value, next, newSection, id) {
      if (type == "sku") {
        axios
          .get(`/api/product/sku/${value}`)
          .then((result) => {
            var qtyInput = document.getElementById(next);
            qtyInput.disabled = false;
            setTimeout(() => {
              qtyInput.focus();
            }, 500);
          })
          .catch(async (err) => {
            var qtyInput = document.getElementById(next);
            qtyInput.value = 0;
            var skuInput = document.getElementById(newSection);
            skuInput.value = "";
            const c = this.products.find((i) => i.id == id);
            c.sku = "";
            await notification.warning({
              message: this.$t("Error"),
              description: this.$t("Error! Product not found"),
              duration: 1.5,
            });
          });
      } else if (type == "qty") {
        var btn = document.getElementById(next);
        btn.click();
        setTimeout(() => {
          var skuInput = document.getElementById(newSection);
          skuInput.focus();
        }, 500);
      }
    },
    async getBranchs() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
        })
        .catch((err) => { });
    },
    addProduct() {
      this.products.push({
        id: Math.ceil(Math.random() * 8498615),
        sku: "",
        quantity: null,
      });
    },
    cc() {
      console.log(this.products);
    },
    removeProduct(id) {
      const check = this.products.find((e) => e.id == id);
      const index = this.products.indexOf(check);
      this.products.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
