<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Workers')" />
      <router-link to="/workers"><a-button>{{$t('Go to Back')}}</a-button></router-link>
    </div>
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <div v-if="load" class="" style="display: flex; justify-content: space-between">
        <a-form style="width: 40%" layout="vertical">
          <a-form-item :label="$t('Name')">
            <a-input v-model:value="form.name" :placeholder="$t('Name')" />
          </a-form-item>
          <a-form-item :label="$t('E-mail')">
            <a-input v-model:value="form.email" :placeholder="$t('E-mail')" />
          </a-form-item>
          <a-form-item :label="$t('Role')">
            <a-select
              :value="form.role_id.name"
              v-model:value="form.role_id._id"
              :placeholder="$t('Role')"
            >
              <a-select-option
                v-for="(item, index) in roles"
                :key="index"
                :value="item._id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <div class="" style="display: flex; justify-content: end">
              <a-button type="primary" :loading="loadBtn" @click="update()"
                >{{$t('Update')}}</a-button
              >
            </div>
          </a-form-item>
        </a-form>
        <a-form style="width: 40%" layout="vertical">
          <h3>{{$t('Password Change')}}</h3>
          <a-form-item :label="$t('New Password')">
            <a-input v-model:value="password.newPassword" :placeholder="$t('New Password')" />
          </a-form-item>
          <a-form-item>
            <div class="" style="display: flex; justify-content: end">
              <a-button type="primary" :loading="loadBtnP" @click="changePassword()"
                >{{$t('Update')}}</a-button
              >
            </div>
          </a-form-item>
        </a-form>
      </div>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";

export default {
  components: { PageTitle, PageTitle, Loader },
  data() {
    return {
      form: {},
      password: {},
      roles: [],
      loadBtn: false,
      loadBtnP: false,
      load: false,
    };
  },
  created() {
    this.getData();
    this.getRoles();
  },
  methods: {
    async update() {
      console.log(this.form.role_id._id);
      this.loadBtn = true;
      var data = {
        name: this.form.name,
        email: this.form.email,
        role_id: this.form.role_id._id,
      };
      await axios
        .patch(`/api/worker/${this.$route.params.id}`, data)
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    changePassword() {
      this.loadBtnP = true;
      axios
        .patch(`/api/worker/change-password/${this.$route.params.id}`, this.password)
        .then(async (result) => {
          this.password = {};
          this.loadBtnP = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtnP = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    getData() {
      axios
        .get(`/api/worker/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data;
          this.load = true;
        })
        .catch((err) => {});
    },
    getRoles() {
      axios
        .get(`/api/role/all`)
        .then((result) => {
          result.data.forEach((element) => {
            if (element.type != "system") {
              this.roles.push(element);
            }
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
