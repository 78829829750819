<template>
  <div class="">
    <PageTitle :title="$t('Firm Settings')" />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <div class="" style="display: flex; justify-content: space-between">
        <a-row style="width: 100%">
          <a-col :xs="24" :md="12" :xl="6">
            <a-form layout="vertical" style="width: 100%">
              <a-form-item v-if="user.firm.logo">
                <div class="logo" style="">
                  <div class="img" style="width: 120px; height: 100px">
                    <img
                      style="width: 100%; height: 100%"
                      :src="'https://storage.googleapis.com/texco1/' + user.firm.logo"
                      alt=""
                    />
                  </div>
                </div>
              </a-form-item>
              <a-form-item style="margin-top: 100px" :label="$t('New Logo')">
                <a-input type="file" @change="handleFileUpload($event)" />
              </a-form-item>
              <a-form-item :label="$t('Firm Name')">
                <a-input v-model:value="user.firm.name" :placeholder="$t('Firm Name')" />
              </a-form-item>
              <a-form-item :label="$t('Phone')">
                <a-input v-model:value="user.firm.phone" :placeholder="$t('Phone')" />
              </a-form-item>
              <a-form-item :label="$t('Country')">
                <a-select v-model:value="user.firm.country" :placeholder="$t('Country')">
                  <a-select-option
                    v-for="(item, index) in countries"
                    :key="index"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('City')">
                <a-select v-model:value="user.firm.city" :placeholder="$t('City')">
                  <a-select-option
                    v-for="(item, index) in cities"
                    :key="index"
                    :value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('Zipcode')">
                <a-input v-model:value="user.firm.zipCode" :placeholder="$t('Zipcode')" />
              </a-form-item>
              <a-form-item :label="$t('Address')">
                <a-input v-model:value="user.firm.address" :placeholder="$t('Address')" />
              </a-form-item>
              <a-form-item :label="$t('Currency')">
                <a-select v-model:value="user.firm.currency" placeholder="$t('Currency')">
                  <a-select-option :value="'$'">$ Dollar</a-select-option>
                  <a-select-option :value="'€'">€ Euro</a-select-option>
                  <a-select-option :value="'₼'">₼ Manat</a-select-option>
                  <a-select-option :value="'₺'">₺ Lira</a-select-option>
                  <a-select-option :value="'£'">£ Pound</a-select-option>
                  <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
                  <a-select-option :value="'₽'">₽ Ruble</a-select-option>
                  <a-select-option :value="'₹'">₹ Rupi</a-select-option>
                  <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
                  <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
                  <a-select-option :value="'SR'">SR Riyal</a-select-option>
                </a-select>
                <!-- <a-input v-model:value="user.firm.address" placeholder="$t('Address')" /> -->
              </a-form-item>
              <a-form-item style="float: right">
                <a-button :loading="loading" type="primary" @click="update()">{{
                  $t("Update")
                }}</a-button>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  components: { PageTitle },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      loadingP: false,
      password: {},
      newlogo: null,
      countries: [{ name: "Türkiye", value: "Türkiye" }],
      cities: [
        "Adana",
        "Adıyaman",
        "Afyonkarahisar",
        "Ağrı",
        "Amasya",
        "Ankara",
        "Antalya",
        "Artvin",
        "Aydın",
        "Balıkesir",
        "Bilecik",
        "Bingöl",
        "Bitlis",
        "Bolu",
        "Burdur",
        "Bursa",
        "Çanakkale",
        "Çankırı",
        "Çorum",
        "Denizli",
        "Diyarbakir",
        "Edirne",
        "Elazığ",
        "Erzincan",
        "Erzurum",
        "Eskişehir",
        "Gaziantep",
        "Giresun",
        "Gümüşhane",
        "Hakkari",
        "Hatay",
        "Isparta",
        "Mersin",
        "İstanbul",
        "İzmir",
        "Kars",
        "Kastamonu",
        "Kayseri",
        "Kırklareli",
        "Kırşehir",
        "Kocaeli",
        "Konya",
        "Kütahya",
        "Malatya",
        "Manisa",
        "Kahramanmaraş",
        "Mardin",
        "Muğla",
        "Muş",
        "Nevşehir",
        "Niğde",
        "Ordu",
        "Rize",
        "Sakarya",
        "Samsun",
        "Siirt",
        "Sinop",
        "Sivas",
        "Tekirdağ",
        "Tokat",
        "Trabzon",
        "Tunceli",
        "Şanlıurfa",
        "Uşak",
        "Van",
        "Yozgat",
        "Zonguldak",
        "Aksaray",
        "Bayburt",
        "Karaman",
        "Kırıkkale",
        "Batman",
        "Şırnak",
        "Bartın",
        "Ardahan",
        "Iğdır",
        "Yalova",
        "Karabük",
        "Kilis",
        "Osmaniye",
        "Düzce",
      ],
    };
  },
  methods: {
    handleFileUpload(event) {
      this.newlogo = event.target.files[0];
    },
    async update() {
      this.loading = true;
      let formData = new FormData();
      await formData.append("name", this.user.firm.name);
      await formData.append("address", this.user.firm.address);
      await formData.append("phone", this.user.firm.phone);
      await formData.append("currency", this.user.firm.currency);
      await formData.append("city", this.user.firm.city);
      await formData.append("country", this.user.firm.country);
      await formData.append("zipCode", this.user.firm.zipCode);
      await formData.append("logo", this.newlogo);
      axios
        .patch(`/api/firm/${this.user.firm._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (result) => {
          await axios
            .get("/api/auth/user")
            .then(async (result) => {
              await localStorage.setItem("user", JSON.stringify(result.data));
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.user = await JSON.parse(localStorage.getItem("user"));
              this.loading = false;
            })
            .catch(async (err) => {
              this.loading = false;
              await notification.error({
                message: "Warning",
                description: err.response.data.msg,
                duration: 1.5,
              });
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: "Warning",
            description: err.response.data.msg,
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
