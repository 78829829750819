<template>
  <div>
    <div class="" style="display: flex; margin: 15px">
      <h2 class="text-2xl" ><b>{{title}}</b></h2>
    </div>
  </div>
</template>

<script>
export default {
    props:['title']
};
</script>

<style lang="scss" scoped></style>
