<template>
  <div class="">
    <div class="" style="
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 0px 15px;
                                  ">
      <PageTitle :title="$t('Customers')" />
      <Button :getData="getData" />
    </div>
    <CustomerFilter @itemFilter="data = $event" :loader="loader" @newLoad="loader = $event" />
    <a-layout-content :style="{
      margin: '0px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px',
    }">
      <a-table :scroll="{ x: 1000 }" v-if="loader" :columns="columns" :data-source="data"
        class="components-table-demo-nested" @change="onChange">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'btn'">
            <div class="" style="
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 15px;
                                          ">
              <a-button @click="showDrawer(text)">
                <EyeOutlined />
              </a-button>
              <router-link v-if="record.type != 'system'" :to="'/customers/' + text"><a-button>
                  <EditOutlined />
                </a-button></router-link>
              <a-popover v-if="record.isAction && record.type != 'system'">
                <template #content>
                  <span style="margin: auto">{{
                    $t("this data is used. Cannot be deleted")
                  }}</span>
                </template>
                <a-button disabled>
                  <DeleteOutlined />
                </a-button>
              </a-popover>
              <a-button v-else v-if="record.type != 'system'" @click="remove(text)" :disabled="record.isAction">
                <DeleteOutlined />
              </a-button>
              <!-- <a-button @click="remove(text)"><DeleteOutlined /></a-button> -->
            </div>
          </template>
        </template>
        <template #expandedRowRender="{ record }">
          <a-table :columns="innerColumns" :data-source="record.invoices" rowKey="key">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'btn'">
                <div class="" style="
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 15px;
                                              ">
                  <a-button @click="showDrawerInvoice(text)">
                    <EyeOutlined />
                  </a-button>
                </div>
              </template>
              <template v-if="column.key === 'invoice_date'">
                <span>{{ text ? text.substr(0, 10) : "-" }}</span>
              </template>
              <template v-if="column.key === 'amount'">
                <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
                  moneyFormat(text).substr(1) }}</span>
              </template>
              <template v-if="column.key === 'remainingAmount'">
                <span>{{ text < 0 ? `-${record.currency}` + moneyFormat(text * -1).substr(1) : `${record.currency}` +
                  moneyFormat(text).substr(1) }}</span>
              </template>
              <template v-if="column.key === 'products'">
                <span>{{
                  record.products
                  ? text.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                  }, 0)
                  : "0"
                }}</span>
              </template>
              <template v-if="column.key === 'type'">
                <div class="" style="display: flex;justify-content: start;align-items: center;">
                  <div class="">
                    <a-tag v-if="text == 'sell'" message="Sell" color="green">{{
                      $t("Sell")
                    }}</a-tag>
                    <a-tag v-else message="Return" color="yellow">{{ $t("Return") }}</a-tag>
                  </div>
                  <div class="">
                    <a-tag v-if="record.remainingAmount == 0" message="Paid" color="blue">{{
                      $t("Paid")
                    }}</a-tag>
                    <a-tag v-else message="Return" color="orange">{{ $t("No Paid") }}</a-tag>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
        </template>
      </a-table>
      <Loader v-else />
      <a-drawer v-if="visibleDetailLoad" :title="customerDetail.name" placement="right" :closable="false"
        v-model:visible="visibleDetail" :after-visible-change="afterVisibleChange">
        <CustomerDetail :data="customerDetail" />
      </a-drawer>
      <a-drawer v-if="visibleInvoiceDetailLoad" :title="`${$t('Invoice')} ` + invoiceDetail.invoice_no" placement="right"
        :closable="false" v-model:visible="visibleDetail" :after-visible-change="afterVisibleInvoiceChange">
        <InvoiceDetail @invoiceDetail="invoiceDetail = $event" :data="invoiceDetail" />
      </a-drawer>
    </a-layout-content>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Button from "@/components/customer/Button.vue";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import { Modal, notification } from "ant-design-vue";
import { createVNode } from "vue";
import CustomerFilter from "@/components/customer/CustomerFilter.vue";
import CustomerDetail from "@/components/customer/CustomerDetail.vue";
import InvoiceDetail from "@/components/invoice/InvoiceDetail.vue";

export default {
  data() {
    return {
      invoiceDetail:{},
      loader: false,
      visibleDetail: true,
      visibleDetailLoad: false,
      visibleInvoiceDetailLoad: false,
      customerDetail: {},
      columns: [
        {
          title: this.$t("Customer Name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      data: [],
      innerColumns: [
        {
          title: this.$t("Invoice No"),
          dataIndex: "invoice_no",
          key: "invoice_no",
        },
        // {
        //   title: this.$t("Branch"),
        //   dataIndex: "branch_id",
        //   key: "branch_id",
        // },
        // {
        //   title: this.$t("Customer"),
        //   dataIndex: "customer_id.name",
        //   key: "customer_id",
        // },
        {
          title: this.$t("Type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "products",
        },
        {
          title: this.$t("Amount"),
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: this.$t("No Paid"),
          dataIndex: "remainingAmount",
          key: "remainingAmount",
        },
        {
          title: this.$t("Date"),
          dataIndex: "invoice_date",
          key: "invoice_date",
        },
        {
          title: this.$t("Action"),
          dataIndex: "_id",
          key: "btn",
        },
      ],
      innerData: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showDrawerInvoice(id) {
      this.visibleDetail = true;
      axios
        .get(`/api/invoice/${id}`)
        .then((result) => {
          console.log(result.data);
          this.invoiceDetail = result.data;
          this.visibleInvoiceDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleInvoiceChange(bool) {
      if (bool == false) {
        this.invoiceDetail = {};
        this.visibleInvoiceDetailLoad = false;
        this.getData()
      }
    },
    async isAction() {
      this.data.forEach(async (e) => {
        await axios
          .get(`/api/customer/is-action/${e._id}`)
          .then((result) => {
            if (result.data.status) {
              e.isAction = false;
            } else {
              e.isAction = true;
            }
          })
          .catch((err) => { });
      });
      console.log(this.data);
    },
    showDrawer(id) {
      this.visibleDetail = true;
      axios
        .get(`/api/customer/${id}`)
        .then((result) => {
          this.customerDetail = result.data;
          this.visibleDetailLoad = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    afterVisibleChange(bool) {
      if (bool == false) {
        this.customerDetail = {};
        this.visibleDetailLoad = false;
      }
    },
    async getData() {
      await axios
        .get("/api/customer/all")
        .then(async (result) => {
          var list = []
          var invoices = []
          await result.data.forEach((e, key) => {
            e.key = key
            list.push(e)
            invoices = []
            e.invoices.forEach((i, key2) => {
              i.key = key2
              invoices.push(i)
            });
          });
          this.innerData = invoices;
          this.data = list;
          console.log(this.innerData);
          this.isAction()
          setTimeout(() => {
            this.loader = true;
          }, 500);
        })
        .catch((err) => { });
    },
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content:
          this.$t("If you delete this data, all related data will be deleted. Are you sure?"),
        onOk: async () => {
          await axios
            .delete(`/api/customer/${id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              await this.getData();
            })
            .catch(async (err) => { });
        },
        onCancel() { },
      });
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: {
    Loader,
    PageTitle,
    Button,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CustomerFilter,
    CustomerDetail,
    InvoiceDetail
},
};
</script>

<style lang="scss" scoped></style>
