<template>
  <div>
    <a-button
      style="background-color: #008000; border-color: #008000"
      type="primary"
      @click="showModal"
    >
    <FileExcelOutlined /><span>Excel ile Ürün Yükle</span>
    </a-button>
    <a-modal
      :mask-closable="false"
      :width="modalSize > 900 ? '45%' : '100%'"
      v-model:visible="visible"
      :title="'Excel ile Ürün Yükle'"
      :footer="null"
    >
      <div class="">
        <div class="">
          <div class="">
            Lütfen örnek dosyayı indirin ve başlıklarda herhangi bir değişiklik
            yapmadan ürünlerinizi excel dosyasına yükleyin aksi halde ürünler
            yüklenmeyecektir.
          </div>
          <div class="">
            <a href="#" @click.prevent="downloadFile"
              ><DownloadOutlined /> Örnek Dosya</a
            >
          </div>
        </div>
        <a-divider></a-divider>
        <div class="">
          <a-form layout="vertical">
            <a-form-item id="product-img" :label="'Excel Dosyası'">
              <a-input type="file" @change="handleFileUpload($event)" />
            </a-form-item>
            <a-form-item>
              <div
                class=""
                style="display: flex; justify-content: end; align-items: center"
              >
                <a-button @click="() => (visible = false)">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  :loading="loading"
                  style="margin-left: 5px"
                  type="primary"
                  @click="handleOk()"
                  >{{ $t("Create") }}</a-button
                >
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div></a-modal
    >
  </div>
</template>

<script>
import {
  MinusOutlined,
  PlusOutlined,
  RetweetOutlined,
  DownloadOutlined,
  FileExcelOutlined,
} from "@ant-design/icons-vue";
import axios from "axios";
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
      modalSize: window.innerWidth,
      visible: false,
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
      form: {},
    };
  },
  components: { PlusOutlined, DownloadOutlined, FileExcelOutlined },
  props: ["getData"],
  methods: {
    async handleOk() {
      this.loading = true;

      let formData = new FormData();
      await formData.append("file", this.form.file);

      await axios
        .post("/api/product/excel-add", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (result) => {
          this.$emit("loader", false);
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.visible = false;
          this.form = {};

          setTimeout(() => {
            this.getData();
          }, 3000);
        })
        .catch(async (err) => {
          this.loading = false;
          console.log("====================================");
          console.log(err);
          console.log("====================================");
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    showModal() {
      this.visible = true;
    },
    async handleFileUpload(event) {
      this.form.file = event.target.files[0];
    },
    downloadFile() {
      // Dosyanın public klasöründeki yolu
      const fileUrl = "/excel/ornek-dosya.xlsx";

      // Yeni bir link oluştur ve download attribute'u ekle
      const link = document.createElement("a");
      link.href = fileUrl;

      // Dosya ismini belirleyelim
      link.setAttribute("download", "ornek-dosya.xlsx");

      // Linki tıklat
      document.body.appendChild(link);
      link.click();

      // Tıklamadan sonra linki temizleyin
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped></style>
