<template>
  <div class="">
    <PageTitle :title="$t('Reports')" />
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-tabs v-model:activeKey="activeMainKey">
        <a-tab-pane key="1" :tab="$t('Sale Reports')">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" :tab="$t('Sale quantity')">
              <DailySaleQuantity />
            </a-tab-pane>
            <a-tab-pane key="2" :tab="$t('Sale price')" force-render
              ><DailySaleAmount :chart="true" />
            </a-tab-pane>
            <a-tab-pane key="3" :tab="$t('No Paid Invoices')" force-render>
              <NoPaid />
            </a-tab-pane>
            <a-tab-pane key="4" :tab="$t('Payments')" force-render>
              <Payments />
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('Product Reports')" force-render>
          <a-tabs v-model:activeKey="activeKey2">
            <a-tab-pane key="1" :tab="$t('Best Sellers')"> <BestSellers /> </a-tab-pane>
            <!-- <a-tab-pane key="2" :tab="$t('Worst Sellers')"> <WorstSellers /> </a-tab-pane> -->
            <a-tab-pane key="2" :tab="$t('Category Report')">
              <CategoryReport />
            </a-tab-pane>
            <!-- <a-tab-pane key="4" :tab="$t('Brands Report')"> <BrandReport /> </a-tab-pane>
            <a-tab-pane key="5" :tab="$t('Materials Report')">
              <MaterialReport />
            </a-tab-pane>
            <a-tab-pane key="6" :tab="$t('Patterns Report')">
              <PatternReport />
            </a-tab-pane>
            <a-tab-pane key="7" :tab="$t('Seasons Report')">
              <SeasonReport />
            </a-tab-pane> -->
          </a-tabs>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('Income & Expenses Reports')" force-render>
          <IncomeReport />
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('Customer Reports')" force-render>
          <CustomerReport />
        </a-tab-pane>
        <a-tab-pane key="5" :tab="$t('Branch Reports')" force-render>
          <BranchReport />
        </a-tab-pane>
      </a-tabs>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import DailySaleQuantity from "@/components/reports/saleReports/DailySaleQuantity.vue";
import DailySaleAmount from "@/components/reports/saleReports/DailySaleAmount.vue";
import NoPaid from "@/components/reports/saleReports/NoPaid.vue";
import Payments from "@/components/reports/saleReports/Payments.vue";
import BestSellers from "@/components/reports/productReports/BestSellers.vue";
import WorstSellers from "@/components/reports/productReports/WorstSellers.vue";
import CategoryReport from "@/components/reports/productReports/CategoryReport.vue";
import BrandReport from "@/components/reports/productReports/BrandReport.vue";
import MaterialReport from "@/components/reports/productReports/MaterialReport.vue";
import PatternReport from "@/components/reports/productReports/PatternReport.vue";
import SeasonReport from "@/components/reports/productReports/SeasonReport.vue";
import IncomeReport from "@/components/reports/IncomeReports/IncomeReport.vue";
import CustomerReport from "@/components/reports/customerReport/CustomerReport.vue";
import BranchReport from "@/components/reports/branchReport/BranchReport.vue";

export default {
  components: {
    PageTitle,
    DailySaleQuantity,
    DailySaleAmount,
    BestSellers,
    WorstSellers,
    CategoryReport,
    BrandReport,
    SeasonReport,
    MaterialReport,
    PatternReport,
    IncomeReport,
    CustomerReport,
    BranchReport,
    Payments,
    NoPaid
},
  data() {
    return {
      activeMainKey: "1",
      activeKey: "1",
      activeKey2: "1",
      config: {
        appendPadding: 10,
        data: [],
        angleField: "rate",
        colorField: "name",
        radius: 0.9,
        label: {
          type: "inner",
          offset: "-30%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [
          {
            type: "element-active",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
