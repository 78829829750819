<template>
  <div>
    <a-row style="display: flex; justify-content: space-between; align-items: center">
      <HomeCard
        :title="$t('Today Sale')"
        :value="data.todaySell"
        :loading="loading"
        :current="todaySellCurrent"
      />
      <HomeCard
        :title="$t('Today Return')"
        :value="data.todayReturn"
        :loading="loading"
        :current="todayReturnCurrent"
      />
      <HomeCard
        :title="$t('This Month Sale')"
        :value="data.thisMonthSell"
        :loading="loading"
        :current="thisMonthSellCurrent"
      />
      <HomeCard
        :title="$t('This Month Return')"
        :value="data.thisMonthReturn"
        :loading="loading"
        :current="thisMonthReturnCurrent"
      />
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import HomeCard from "./HomeCard.vue";

export default {
  components: { HomeCard },
  data() {
    return {
      data: {},
      todaySellCurrent: 0,
      todayReturnCurrent: 0,
      thisMonthSellCurrent: 0,
      thisMonthReturnCurrent: 0,
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/api/statistic/home-cards")
        .then((result) => {
          this.data = result.data;
          this.todaySellCurrent =
            result.data.yesterdaySell != 0
              ? (result.data.todaySell / result.data.yesterdaySell) * 100 - 100
              : 0;
          this.todayReturnCurrent =
            result.data.yesterdayReturn != 0
              ? (result.data.todayReturn / result.data.yesterdayReturn) * 100 - 100
              : 0;
          this.thisMonthSellCurrent =
            result.data.yesterMonthSell != 0
              ? (result.data.thisMonthSell / result.data.yesterMonthSell) * 100 - 100
              : 0;
          this.thisMonthReturnCurrent =
            result.data.yesterMonthReturn != 0
              ? (result.data.thisMonthReturn / result.data.yesterMonthReturn) * 100 - 100
              : 0;
          console.log(this.todaySellCurrent);
          console.log(this.todayReturnCurrent);
          this.loading = true;
        })
        .catch((err) => {
          this.getData()
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
