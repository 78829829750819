<template>
  <div>
    <div class="main">
      <div class="left">
        <img
          style="width: 100%; height: 100%; opacity: 0.03"
          src="@/assets/images/login.png"
          alt=""
        />
      </div>
      <div class="rigth">
        <div class="form">
          <div class="" style="display: flex; justify-content: center; padding: 10px">
            <Language />
          </div>
          <div class="">
            <div
              class=""
              style="
                width: 180px;
                height: 180px;
                display: flex;
                justify-content: center;
                margin: 50px 0 0px 0;
              "
            >
              <!-- <h1><b>TEXCOFA</b></h1> -->

              <img
                style="width: 100%; height: 100%"
                src="@/assets/images/logo/logo.png"
                alt=""
              />
            </div>
          </div>
          <div class="">
            <h1>{{ $t("Forgot Password") }}</h1>
          </div>
          <a-form layout="vertical" @finish="login" style="margin-top: 30px">
            <a-form-item :label="$t('E-mail')">
              <a-input
                v-model:value="form.email"
                type="text"
                :placeholder="$t('E-mail')"
              />
            </a-form-item>
            <a-button :loading="loading" type="button" @click="forgot()">{{
              $t("Send")
            }}</a-button>
          </a-form>
          <div class="" style="margin: 15px 0">
            <router-link to="/">{{ $t("Login") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import axios from "axios";
import Language from "@/components/shared/Language.vue";
export default {
  data() {
    return {
      form: {
        email: "",
      },
      loading: false,
      isError: false,
    };
  },
  mounted() {
    axios
      .get(`api/locale/ip`)
      .then((result) => {
        if (localStorage.getItem("lang") == null) {
          localStorage.setItem("lang", result.data.lang);
          this.$i18n.locale = result.data.lang;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    async forgot() {
      this.loading = true;
      await axios
        .post("/api/auth/forgot-password", this.form)
        .then(async (result) => {
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Check your e-mail box to change your password"),
            duration: 1.5,
          });
          this.form = {
            email: "",
          };
          this.$router.push('/')
          this.loading = false;
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("User not found"),
            duration: 1.5,
          });
          this.loading = false;
        });
    },
  },
  components: { Language },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
}
.left {
  background-color: #001529;
  height: 100vh;
  width: 100%;
}
.rigth {
  background-color: #f0f2f5;
  height: 100vh;
  width: 60%;
  display: flex;
  justify-content: center;
}
.form {
}

@media only screen and (max-width: 900px) {
  .left {
    display: none;
  }
  .rigth {
    width: 100%;
  }
}
</style>
