<template>
  <div>
    <a-card :loading="load" style="width: 100%">
      <a-card-meta style="margin-bottom: 15px" :title="$t('Daily Report')">
        <template #description>{{ $t("Last 7 days sales and returns graph") }}</template>
      </a-card-meta>
      <LinePlot
        v-if="last7DayLoad"
        :config="last7Config"
        :chartRef="(ref) => (LineRef = ref)"
      />
      <Loader v-else />
    </a-card>
  </div>
</template>

<script>
import { LinePlot } from "ant-design-charts-vue";
import Last7Day from "@/components/home/Last7Day.vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
export default {
  components: {
    LinePlot,
    Last7Day,
    Loader,
  },
  data() {
    return {
      last7DayLoad: false,
      last7Config: {
        data: [],
        color: ["#5D90F7", "#cf1322"],
        height: 350,
        xField: "date",
        yField: "count",
        seriesField: "type",
        smooth: true,
      },
      load: true,
    };
  },
  watch: {
    // whenever question changes, this function will run
    last7DayLoad(newData, oldData) {
      this.load = false;
    },
  },
  created() {
    this.getLast7Day();
  },
  methods: {
    async getLast7Day() {
      axios
        .get("/api/statistic/last-7-day")
        .then((result) => {
          this.last7Config.data = result.data;
          this.last7DayLoad = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
