<template>
  <div>
    <div class="main">
      <div class="left">
        <img
          style="width: 100%; height: 100%; opacity: 0.03"
          src="https://www.yucelengroup.com/images/my_pictures/galeri/yucelen-merkez-210120228382.JPG"
          alt=""
        />
      </div>
      <div class="rigth">
        <div class="form">
          <div
            class=""
            style="display: flex; justify-content: center; padding: 10px 0 0 0"
          >
            <Language />
          </div>
          <div class="">
            <div
              class=""
              style="
                width: 180px;
                height: 180px;
                display: flex;
                justify-content: center;
                text-align: center;
                margin: auto;
              "
            >
              <!-- <h1><b>TEXCOFA</b></h1> -->
              <img
                style="width: 100%; height: 100%"
                src="@/assets/images/logo/logo.png"
                alt=""
              />
            </div>
          </div>
          <div class="">
            <h1>{{ $t("Sign Up") }}</h1>
          </div>
          <a-tabs v-model:activeKey="activeKey" centered size="small" style="width: 100%">
            <a-tab-pane key="1" :tab="$t('User Info')">
              <a-form layout="vertical">
                <a-form-item>
                  <a-input
                    v-model:value="form.name"
                    type="text"
                    :placeholder="$t('Name')"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    v-model:value="form.email"
                    type="text"
                    :placeholder="$t('E-mail')"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input-password
                    v-model:value="form.password"
                    type="password"
                    :placeholder="$t('Password')"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button :loading="loading" type="button" @click="activeKey = '2'">{{
                    $t("Next")
                  }}</a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="$t('Firm Info')" force-render>
              <a-form layout="vertical">
                <a-form-item>
                  <a-input
                    v-model:value="form.firmName"
                    type="text"
                    :placeholder="$t('Firm Name')"
                  />
                </a-form-item>
                <a-form-item>
                  <div style="display: flex; justify-content: start; width: 100%">
                    <a-select v-model:value="form.firmPhoneCode" style="width: 55%">
                      <a-select-option
                        v-for="(item, index) in phoneCodes"
                        :key="index"
                        :value="item.value"
                      >
                        <div
                          class=""
                          style="display: flex; justify-content: center; gap: 5px"
                        >
                          <div class="img" style="width: 30px; height: 20px">
                            <img
                              style="width: 100%; height: 100%"
                              :src="require(`@/assets/images/langs/${item.img}.png`)"
                              alt=""
                            />
                          </div>
                          <span>({{ item.value }})</span>
                        </div>
                      </a-select-option>
                    </a-select>
                    <a-input
                      v-model:value="form.firmPhone"
                      id="phoneNumber"
                      maxlength="14"
                      :placeholder="'(5XX) XXX XX XX'"
                    />
                  </div>
                </a-form-item>
                <a-button :loading="loading" type="button" @click="activeKey = '3'">{{
                  $t("Next")
                }}</a-button>
              </a-form>
            </a-tab-pane>
            <a-tab-pane key="3" :tab="$t('Firm Address')" force-render>
              <a-form layout="vertical">
                <div
                  class=""
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;
                  "
                >
                  <a-form-item style="width: 100%">
                    <a-select v-model:value="form.country" :placeholder="$t('Country')">
                      <a-select-option
                        v-for="(item, index) in countries"
                        :key="index"
                        :value="item.value"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                  <a-form-item style="width: 100%">
                    <a-select v-model:value="form.city" :placeholder="$t('City')">
                      <a-select-option
                        v-for="(item, index) in cities"
                        :key="index"
                        :value="item"
                        >{{ item }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </div>
                <a-form-item>
                  <a-input
                    v-model:value="form.zipCode"
                    type="text"
                    maxlength="5"
                    :placeholder="$t('Zipcode')"
                  />
                </a-form-item>
                <a-form-item>
                  <a-textarea
                    v-model:value="form.firmAddress"
                    type="text"
                    :placeholder="$t('Firm Address')"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button :loading="loading" type="button" @click="register()">{{
                    $t("Sign Up")
                  }}</a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
          </a-tabs>
          <div class="" style="margin: 15px 0">
            <router-link to="/">{{ $t("Sign In") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import axios from "axios";
import Language from "@/components/shared/Language.vue";
import countries from "@/data/country.js";
export default {
  data() {
    return {
      form: {
        firmPhoneCode: "+90",
        firmPhone: "",
      },
      loading: false,
      activeKey: "1",
      countries: [{ name: "Türkiye", value: "Türkiye" }],
      cities: [
        "Adana",
        "Adıyaman",
        "Afyonkarahisar",
        "Ağrı",
        "Amasya",
        "Ankara",
        "Antalya",
        "Artvin",
        "Aydın",
        "Balıkesir",
        "Bilecik",
        "Bingöl",
        "Bitlis",
        "Bolu",
        "Burdur",
        "Bursa",
        "Çanakkale",
        "Çankırı",
        "Çorum",
        "Denizli",
        "Diyarbakir",
        "Edirne",
        "Elazığ",
        "Erzincan",
        "Erzurum",
        "Eskişehir",
        "Gaziantep",
        "Giresun",
        "Gümüşhane",
        "Hakkari",
        "Hatay",
        "Isparta",
        "Mersin",
        "İstanbul",
        "İzmir",
        "Kars",
        "Kastamonu",
        "Kayseri",
        "Kırklareli",
        "Kırşehir",
        "Kocaeli",
        "Konya",
        "Kütahya",
        "Malatya",
        "Manisa",
        "Kahramanmaraş",
        "Mardin",
        "Muğla",
        "Muş",
        "Nevşehir",
        "Niğde",
        "Ordu",
        "Rize",
        "Sakarya",
        "Samsun",
        "Siirt",
        "Sinop",
        "Sivas",
        "Tekirdağ",
        "Tokat",
        "Trabzon",
        "Tunceli",
        "Şanlıurfa",
        "Uşak",
        "Van",
        "Yozgat",
        "Zonguldak",
        "Aksaray",
        "Bayburt",
        "Karaman",
        "Kırıkkale",
        "Batman",
        "Şırnak",
        "Bartın",
        "Ardahan",
        "Iğdır",
        "Yalova",
        "Karabük",
        "Kilis",
        "Osmaniye",
        "Düzce",
      ],
      phoneCodes: [
        {
          value: "+33",
          img: "fr",
        },
        {
          value: "+32",
          img: "bg",
        },
        {
          value: "+49",
          img: "de",
        },
        {
          value: "+1",
          img: "usa",
        },
        {
          value: "+44",
          img: "en",
        },
        {
          value: "+376",
          img: "ar",
        },
        {
          value: "+43",
          img: "at",
        },
        {
          value: "+387",
          img: "ba",
        },
        {
          value: "+973",
          img: "bh",
        },
        {
          value: "+1",
          img: "canada",
        },
        {
          value: "+41",
          img: "ch",
        },
        {
          value: "+420",
          img: "cek",
        },
        {
          value: "+45",
          img: "denmark",
        },
        {
          value: "+34",
          img: "es",
        },
        {
          value: "+358",
          img: "fi",
        },
        {
          value: "+995",
          img: "ge",
        },
        {
          value: "+30",
          img: "gr",
        },
        {
          value: "+353",
          img: "ie",
        },
        {
          value: "+972",
          img: "il",
        },
        {
          value: "+91",
          img: "hi",
        },
        {
          value: "+964",
          img: "iq",
        },
        {
          value: "+98",
          img: "ir",
        },
        {
          value: "+354",
          img: "iceland",
        },
        {
          value: "+39",
          img: "it",
        },
        {
          value: "+81",
          img: "jap",
        },
        {
          value: "+965",
          img: "kuweit",
        },
        {
          value: "+31",
          img: "nl",
        },
        {
          value: "+47",
          img: "norway",
        },
        {
          value: "+48",
          img: "le",
        },
        {
          value: "+351",
          img: "pt",
        },
        {
          value: "+974",
          img: "qt",
        },
        {
          value: "+40",
          img: "rm",
        },
        {
          value: "+381",
          img: "sb",
        },
        {
          value: "+7",
          img: "ru",
        },
        {
          value: "+966",
          img: "ar",
        },
        {
          value: "+46",
          img: "sw",
        },
        {
          value: "+386",
          img: "si",
        },
        {
          value: "+421",
          img: "slovakia",
        },
        {
          value: "+90",
          img: "tr",
        },
        {
          value: "+380",
          img: "ua",
        },
        {
          value: "+213",
          img: "algeria",
        },
      ],
    };
  },
  mounted() {
    axios
      .get(`api/locale/ip`)
      .then((result) => {
        if (localStorage.getItem("lang") == null) {
          localStorage.setItem("lang", result.data.lang);
          this.$i18n.locale = result.data.lang;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return (
        (key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };

    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (
        event.shiftKey === true ||
        key === 35 ||
        key === 36 || // Allow Shift, Home, End
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        // Allow Ctrl/Command + A,C,V,X,Z
        ((event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
      );
    };

    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
      }
    };

    const formatToPhone = (event) => {
      if (isModifierKey(event)) {
        return;
      }

      const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        event.target.value = `(${areaCode}) ${middle} ${last}`;
      } else if (input.length > 3) {
        event.target.value = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        event.target.value = `${areaCode}`;
      }
    };

    const inputElement = document.getElementById("phoneNumber");
    inputElement.addEventListener("keydown", enforceFormat);
    inputElement.addEventListener("keyup", formatToPhone);
  },
  methods: {
    acceptNumber() {
      var x = this.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.firmPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    onChangeStep(current) {
      this.current = current;
    },
    async register() {
      this.loading = true;
      this.form.firmPhone = this.form.firmPhone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace(" ", "");
      await axios
        .post("/api/auth/register", this.form)
        .then(async (result) => {
          await axios
            .post("/api/auth/login", {
              email: this.form.email,
              password: this.form.password,
            })
            .then(async (result) => {
              if (result.data.token) {
                localStorage.setItem("token", result.data.token);
                axios.defaults.headers.common[
                  "Authorization"
                ] = await `Bearer ${localStorage.getItem("token")}`;
                await axios
                  .get("/api/auth/user")
                  .then(async (result) => {
                    localStorage.setItem("user", JSON.stringify(result.data));
                    if (result.data.isActive) {
                      await notification.success({
                        message: this.$t("Success"),
                        description: this.$t("You Have Successfully Registered"),
                        duration: 1.5,
                      });
                      this.loading = false;
                      await this.$router.push("/home");
                    } else {
                      await notification.info({
                        message: this.$t("Choose plan"),
                        description: this.$t("Choose The Best Plan For Your Business"),
                        duration: 1.5,
                      });
                      this.loading = false;
                      // await this.$router.push("/plans");
                    }
                  })
                  .catch((err) => {
                    this.loading = false;
                  });
              } else {
                this.isError = true;
                this.loading = false;
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: err.response.data.msg,
            duration: 1.5,
          });
        });
    },
  },
  components: { Language },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
}

.left {
  background-color: #001529;
  height: 100vh;
  width: 100%;
}

.rigth {
  background-color: #f0f2f5;
  height: 100vh;
  width: 60%;
  display: flex;
  justify-content: center;
}

.form {
}

@media only screen and (max-width: 900px) {
  .left {
    display: none;
  }

  .rigth {
    width: 100%;
  }
}
</style>
