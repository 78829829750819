<template>
  <div>
    <a-button type="primary" @click="showModal"
      ><PlusOutlined /><span>{{ $t("Create") }}</span></a-button
    >
    <a-modal :mask-closable="false" v-model:visible="visible" :title="$t('New Worker Create')" :footer="null">
      <a-form layout="vertical">
        <a-form-item :label="$t('Name')">
          <a-input v-model:value="form.name" :placeholder="$t('Name')" />
        </a-form-item>
        <a-form-item :label="$t('E-mail')">
          <a-input v-model:value="form.email" :placeholder="$t('E-mail')" />
        </a-form-item>
        <a-form-item :label="$t('Password')">
          <a-input v-model:value="form.password" :placeholder="$t('Password')" />
        </a-form-item>
        <a-form-item :label="$t('Role')">
          <a-select v-model:value="form.role_id" :placeholder="$t('Role')">
            <a-select-option
              v-for="(item, index) in roles"
              :key="index"
              :value="item._id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end; align-items: center">
            <a-button @click="() => (visible = false)">{{ $t("Cancel") }}</a-button>
            <a-button
              :loading="loading"
              style="margin-left: 5px"
              type="primary"
              @click="handleOk()"
              >{{ $t("Create") }}</a-button
            >
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  props: ["getData"],
  components: { PlusOutlined },
  data() {
    return {
      visible: false,
      form: {},
      roles: [],
      loading: false,
    };
  },
  created() {
    this.getRole();
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async handleOk() {
      this.loading = true;
      await axios
        .post("/api/worker/add", this.form)
        .then(async (result) => {
          this.loading = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
          this.visible = false;
          this.form = {};
          this.getData();
        })
        .catch(async (err) => {
          this.loading = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    getRole() {
      axios
        .get("/api/role/all")
        .then((result) => {
          result.data.forEach((e) => {
            if (e.type != "system") {
              this.roles.push(e);
            }
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
