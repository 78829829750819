<template>
  <a-layout style="height: 100%">
    <a-layout>
      <a-layout-header
        style="
          background: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div class="logo"></div>
        <div class="" style="display: flex; gap: 15px">
          <div class="home">
            <router-link to="/home">
              <a-button><home-outlined /> {{ $t("Dashboard") }}</a-button></router-link
            >
          </div>
          <!-- <div class="money">
            <a-dropdown :trigger="['click']">
              <euro-outlined style="cursor: pointer" />
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <span>Dolar: 18.4₺</span>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <span>Euro: 19.4₺</span>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <span>Pound: 21.4₺</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div> -->
          <div class="lang">
            <Language />
          </div>
          <!-- <div class="alert">
            <a-dropdown :trigger="['click']">
              <a-badge dot>
                <bell-outlined style="cursor: pointer" />
              </a-badge>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <a href="http://www.alipay.com/">1st menu item</a>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <a href="http://www.taobao.com/">2nd menu item</a>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="3">3rd menu item</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div> -->
          <div class="user">
            <a-dropdown :trigger="['click']">
              <div
                v-if="user.firm.logo != null"
                class="img"
                style="width: 36px; height: 36px"
              >
                <img
                  style="width: 100%; height: 100%; border-radius: 100%; cursor: pointer"
                  :src="'https://storage.googleapis.com/texco1/' + user.firm.logo"
                  alt=""
                  srcset=""
                />
              </div>
              <a-avatar v-else :size="36" style="cursor: pointer">
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <span>{{ $t("Hello") }}, {{ user.name }}</span>
                  </a-menu-item>
                  <!-- <a-menu-item key="1">
                    <UserOutlined />
                    <router-link to="/account" style="margin-left: 5px"
                      >Account</router-link
                    >
                  </a-menu-item> -->
                  <a-menu-item key="2" @click="logout()">
                    <arrow-left-outlined style="color: red" />
                    <span style="margin-left: 5px; color: red">{{ $t("Logout") }}</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </a-layout-header>
      <div class="" style="min-height: 100vh">
        <router-view></router-view>
      </div>
      <div
        class="footer"
        style="
          margin-top: 15px;
          margin-bottom: 15px;
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div class="">
          <span>Copyright {{ new Date().getFullYear() }} Texcofa</span>
        </div>
        <div class="">
          <span>{{ $t("Support and Help") }}: (+90) 546 571 14 45</span>
        </div>
      </div>
    </a-layout>
  </a-layout>
</template>

<script>
import Sidebar from "@/components/shared/Sidebar.vue";
import Header from "@/components/shared/Header.vue";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  GlobalOutlined,
  HomeOutlined,
  EuroOutlined,
  ArrowLeftOutlined,
  BarChartOutlined,
  ApartmentOutlined,
  TagsOutlined,
  FileOutlined,
  IdcardOutlined,
  SwapOutlined,
  RetweetOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  ClusterOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import Language from "@/components/shared/Language.vue";
export default {
  components: {
    Header,
    SwapOutlined,
    RetweetOutlined,
    TagsOutlined,
    IdcardOutlined,
    FileOutlined,
    UserOutlined,
    EuroOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BellOutlined,
    ApartmentOutlined,
    BarChartOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    ArrowLeftOutlined,
    GlobalOutlined,
    Sidebar,
    ShareAltOutlined,
    ShoppingCartOutlined,
    ClusterOutlined,
    Language,
  },
  data() {
    return {
      collapsed: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    async logout() {
      await localStorage.removeItem("token");
      await localStorage.removeItem("user");
      await this.$router.push("/");
      await notification.success({
        message: this.$t("Success"),
        description: this.$t("logged out"),
        duration: 1.5,
      });
    },
    switchLang(param) {
      localStorage.setItem("lang", param);
      this.$i18n.locale = param;
    },
  },
};
</script>

<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>
