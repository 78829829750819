import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layouts/index.vue'
import FastLayout from '../layouts/fastSellLayout.vue'
import Home from '../pages/home/index.vue'
import Forgot from '../pages/forgot/forgot.vue'
import Invoice from '../pages/invoices/invoice.vue'
import InvoiceDetail from '../pages/invoices/invoiceDetail.vue'
import Product from '../pages/products/product.vue'
import ProductDetail from '../pages/products/productDetail.vue'
import Category from '../pages/categories/category.vue'
import CategoryDetail from '../pages/categories/categoryDetail.vue'
import Pattern from '../pages/patterns/pattern.vue'
import PatternDetail from '../pages/patterns/patternDetail.vue'
import Material from '../pages/materials/material.vue'
import MaterialDetail from '../pages/materials/materialDetail.vue'
import Brand from '../pages/brands/brand.vue'
import BrandDetail from '../pages/brands/brandDetail.vue'
import Season from '../pages/seasons/season.vue'
import SeasonDetail from '../pages/seasons/seasonDetail.vue'
import Branch from '../pages/branches/branch.vue'
import BranchDetail from '../pages/branches/branchDetail.vue'
import Customer from '../pages/customers/customer.vue'
import CustomerDetail from '../pages/customers/customerDetail.vue'
import Internal from '../pages/internals/internal.vue'
import InternalDetail from '../pages/internals/internalDetail.vue'
import IncomeExpenses from '../pages/income-expenses/income-expense.vue'
import IncomeExpensesDetail from '../pages/income-expenses/income-expenseDetail.vue'
import Reports from '../pages/reports/reports.vue'
import Account from '../pages/account/account.vue'
import Login from '../pages/login/login.vue'
import Plans from '../pages/plans/plans.vue'
import Checkout from '../pages/checkout/checkout.vue'
import Register from '../pages/register/register.vue'
import Telegram from '../pages/telegram/telegram.vue'
import Order from '../pages/orders/order.vue'
import OrderDetail from '../pages/orders/orderDetail.vue'
import Transfers from '../pages/transfers/transfer.vue'
import TransfersDetail from '../pages/transfers/transferDetail.vue'
import Stock from '../pages/stock/stock.vue'
import FastSell from '../pages/fast-sell/fastSell.vue'
import FastTransfer from '../pages/fast-transfer/fastTransfer.vue'
import FastInternal from '../pages/fast-internal/fastInternal.vue'
import FirmSettings from '../pages/firm/firm.vue'
import Roles from '../pages/roles/roles.vue'
import SubscriptionSettings from '../pages/subscription/subscription.vue'
import Worker from '../pages/worker/worker.vue'
import WorkerDetail from '../pages/worker/workerDetail.vue'
import Paid from '../pages/paid/paid.vue'
import ResetPassword from '../pages/reset-password/reset-password.vue'
import Integrations from '../pages/integrations/integration.vue'

const routes = [{
    path: '/',
    name: 'login',
    component: Login,
    // beforeEnter: (to, from, next) => {
    //     if (localStorage.getItem("token") && localStorage.getItem('user')) {
    //         return next({
    //             name: 'home'
    //         })
    //     }
    // },
},
{
    path: '/register',
    name: 'register',
    component: Register
},
{
    path: '/forgot-password',
    name: 'forgot',
    component: Forgot
},
{
    path: '/reset-password/:code',
    name: 'reset',
    component: ResetPassword
},
// {
//     path: '/plans',
//     name: 'plans',
//     beforeEnter: (to, from, next) => {
//         if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
//             return next({
//                 name: 'login'
//             })
//         } else {
//             const user = JSON.parse(localStorage.getItem("user"))
//             console.log(user.isActive);
//             if (user.isActive) {
//                 return next({
//                     name: 'login'
//                 })
//             } else {
//                 next()
//             }
//         }
//     },
//     component: Plans
// },
// {
//     path: '/checkout',
//     name: 'checkout',
//     beforeEnter: (to, from, next) => {
//         if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
//             return next({
//                 name: 'login'
//             })
//         } else {
//             const user = JSON.parse(localStorage.getItem("user"))
//             if (user.isActive && user.isSub) {
//                 return next({
//                     name: 'login'
//                 })
//             } else {
//                 next()
//             }
//         }
//     },
//     component: Checkout
// },
// {
//     path: '/paid',
//     name: 'paid',
//     beforeEnter: (to, from, next) => {
//         if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
//             return next({
//                 name: 'login'
//             })
//         } else {
//             const user = JSON.parse(localStorage.getItem("user"))
//             if (user.isPaid == true) {
//                 return next({
//                     name: 'login'
//                 })
//             } else {
//                 if (user.role.name != 'admin') {
//                     return next({
//                         name: 'login'
//                     })
//                 } else {
//                     next()
//                 }
//             }
//         }
//     },
//     component: Paid
// },
{
    path: '/fast',
    component: FastLayout,
    name: 'fast',
    beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
            return next({
                name: 'login'
            })
        } else {
            next()
        }
        // else {
        //     const user = JSON.parse(localStorage.getItem("user"))
        //     if (user.isActive) {
        //         next()
        //     } else {
        //         return next({
        //             name: 'plans'
        //         })
        //     }
        // }
    },
    children: [{
        path: '/fast-sell',
        name: 'fastSell',
        component: FastSell
    },
    {
        path: '/fast-transfer',
        name: 'fastTransfer',
        component: FastTransfer
    }, {
        path: '/fast-internal',
        name: 'fastInternal',
        component: FastInternal
    }]
},
{
    path: '/dashboard',
    component: Layout,
    beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
            return next({
                name: 'login'
            })
        } else {
            next()
        }
        //  else {
        //     const user = JSON.parse(localStorage.getItem("user"))
        //     if (user.isActive) {
        //         next()
        //     } else {
        //         return next({
        //             name: 'plans'
        //         })
        //     }
        // }
    },
    children: [{
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/invoices',
        name: 'invoice',
        component: Invoice,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.invoice == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },

    {
        path: '/invoices/:id',
        name: 'InvoiceDetail',
        component: InvoiceDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.invoice == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    // {
    //     path: '/subscription-settings',
    //     name: 'SubscriptionSettings',
    //     component: SubscriptionSettings,
    //     beforeEnter: (to, from, next) => {
    //         const user = JSON.parse(localStorage.getItem("user"))
    //         if (user.role.subSetting == false) {
    //             return next({
    //                 name: 'home'
    //             })
    //         } else {
    //             next()
    //         }
    //     },
    // },
    {
        path: '/products',
        name: 'products',
        component: Product,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/roles',
        name: 'roles',
        component: Roles,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.users == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/firm-settings',
        name: 'firm-settings',
        component: FirmSettings,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.firmSetting == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/products/:id',
        name: 'productsDetail',
        component: ProductDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/categories',
        name: 'categories',
        component: Category,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/workers',
        name: 'worker',
        component: Worker,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.users == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/workers/:id',
        name: 'workerDetail',
        component: WorkerDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.users == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/categories/:id',
        name: 'categoriesDetail',
        component: CategoryDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/orders',
        name: 'orders',
        component: Order
    },
    {
        path: '/orders/:id',
        name: 'ordersDetail',
        component: OrderDetail
    },
    {
        path: '/patterns',
        name: 'patterns',
        component: Pattern,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/patterns/:id',
        name: 'patternsDetail',
        component: PatternDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/materials',
        name: 'materials',
        component: Material,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/materials/:id',
        name: 'materialsDetail',
        component: MaterialDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/transfers',
        name: 'transfers',
        component: Transfers,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.internal == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/transfers/:id',
        name: 'transfersDetail',
        component: TransfersDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.internal == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/brands',
        name: 'brands',
        component: Brand,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/brands/:id',
        name: 'brandsDetail',
        component: BrandDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/branches',
        name: 'branches',
        component: Branch,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.branch == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/branches/:id',
        name: 'branchesDetail',
        component: BranchDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.branch == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/seasons',
        name: 'seasons',
        component: Season,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/seasons/:id',
        name: 'seasonsDetail',
        component: SeasonDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.product == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/customers',
        name: 'customers',
        component: Customer,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.customer == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/customers/:id',
        name: 'customersDetail',
        component: CustomerDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.customer == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/internals',
        name: 'internals',
        component: Internal,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.internal == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/internals/:id',
        name: 'internalsDetail',
        component: InternalDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.internal == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/income-expenses',
        name: 'income-expenses',
        component: IncomeExpenses,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.income == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/income-expenses/:id',
        name: 'income-expensesDetail',
        component: IncomeExpensesDetail,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.income == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/stock',
        name: 'stock',
        component: Stock,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.stock == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user.role.reports == false) {
                return next({
                    name: 'home'
                })
            } else {
                next()
            }
        },
    },
    {
        path: '/integrations',
        name: 'integrations',
        component: Integrations,
    },
    {
        path: '/account',
        name: 'account',
        component: Account
    },
    {
        path: '/telegram-settings',
        name: 'telegram-settings',
        component: Telegram
    },
    ]
},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router