<template>
  <div>
    <div class="">
      <h3>{{ $t("Permissions") }}</h3>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Invoices") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.invoice" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Stocks") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.stock" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Products") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.product" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Branches") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.branch" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Customers") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.customer" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Internals") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.internal" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Transfers") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.transfer" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Income & Expenses") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.income" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Reports") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.reports" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Users") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.users" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Firm Settings") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.firmSetting" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Subscription Settings") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.subSetting" @change="update()" />
        </div>
      </div>
      <a-divider />
      <div
        class=""
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div class="">
          <span>{{ $t("Fast Sell") }}</span>
        </div>
        <div class="">
          <a-switch v-model:checked="data.pos" @change="update()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  props: ["data"],
  methods: {
    update() {
      axios
        .patch(`/api/role/${this.data._id}`, this.data)
        .then((result) => {
          notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch((err) => {
          notification.warning({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
