<template>
  <div>
    <div class="" style="margin-bottom: 50px">
      <div
        v-if="data.img"
        style="width: 150px; height: 200px; display: flex; margin: auto"
        class="img"
      >
        <a-image
          style="
            width: 100%;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          "
          :src="'https://storage.googleapis.com/texco1/' + data.img"
          alt=""
          srcset=""
        />
      </div>
      <a-divider v-if="data.img" />
      <div class="info">
        <h3>{{ $t("Product Info") }}</h3>
        <div class="name" style="margin-bottom: 10px">
          <!-- <span
            >{{ $t("Product Name") }}: <b>{{ data.name }}</b></span
          > -->
        </div>
        <div v-if="data.sku" class="sku" style="margin-bottom: 10px">
          <span
            >{{ $t("SKU") }}: <b>{{ data.sku }}</b>
            <a-button
              style="margin-left: 15px"
              @click="openSkuBarcodeModal()"
              size="small"
              type="primary"
            >
              <PrinterOutlined />
              {{ $t("barcode print") }}
            </a-button>
          </span>
          <a-modal
            v-model:visible="skuBarcode"
            :title="data.sku"
            :footer="null"
          >
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <span style="text-align: center"
                >{{
                  barcodeType == "small"
                    ? $t(
                        "The print dimensions of your barcode printer should be 30x20. Otherwise, your label form will be corrupt."
                      )
                    : $t(
                        "The print dimensions of your barcode printer should be 50x30. Otherwise, your label form will be corrupt."
                      )
                }}.</span
              >
            </div>
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
              "
            >
              <span style="text-align: center"
                >Yazıcılarınızı görmeniz için
                <a href="https://qz.io/download/" target="_blank">QZ Tray</a>
                programını indirmeniz ve çalıştırmanız gereklidir. Aksi halde
                yazıcılarınızı göremezsiniz.</span
              >
            </div>
            <a-divider />

            <div
              id="barcode"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="" style="margin-top: 10px">
                <a-form layout="vertical">
                  <a-button
                    v-if="printers.length == 0"
                    type="primary"
                    @click="fetchPrinters"
                    >Yazıcıları Çek</a-button
                  >

                  <div
                    v-if="printers.length > 0"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 15px;
                    "
                  >
                    <a-radio-group
                      v-model:value="barcodeType"
                      button-style="solid"
                      size="small"
                      @change="saveBarcodeType"
                    >
                      <a-radio-button :value="'small'">Küçük</a-radio-button>
                      <a-radio-button :value="'big'">Büyük</a-radio-button>
                    </a-radio-group>
                  </div>

                  <a-form-item
                    v-if="printers.length > 0"
                    :label="'Yazıcı Seçin'"
                  >
                    <a-select
                      style="width: 100%"
                      v-model:value="selectedPrinter"
                      :placeholder="'Yazıcı Seçin'"
                      @change="saveSelectedPrinter"
                    >
                      <a-select-option
                        v-for="(item, index) in printers"
                        :key="index"
                        :value="item.value"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                  <a-form-item
                    v-if="printers.length > 0"
                    :label="'Kaç Adet Barkod Basacaksınız?'"
                  >
                    <a-input
                      type="number"
                      min="1"
                      v-model:value="barcodeCount"
                      :placeholder="'Kaç Adet Barkod Basacaksınız?'"
                      @change="validateBarcodeCount"
                    />
                  </a-form-item>
                  <a-form-item v-if="printers.length > 0 && selectedPrinter">
                    <a-button
                      style="margin: auto; width: 100%"
                      @click="printBarcode(data.sku)"
                      size="small"
                      type="primary"
                    >
                      <PrinterOutlined />
                      {{ $t("barcode print") }}
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div
              class=""
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
              "
            ></div>
          </a-modal>
        </div>
        <div class="category" style="margin-bottom: 10px">
          <span
            >{{ $t("Category") }}: <b>{{ data.category_id.name }}</b></span
          >
        </div>
        <!-- <div class="brand" style="margin-bottom: 10px">
          <span
            >{{ $t("Brand") }}: <b>{{ data.brand_id.name }}</b></span
          >
        </div>
        <div class="pattern" style="margin-bottom: 10px">
          <span
            >{{ $t("Pattern") }}: <b>{{ data.pattern_id.name }}</b></span
          >
        </div>
        <div class="material" style="margin-bottom: 10px">
          <span
            >{{ $t("Material") }}: <b>{{ data.material_id.name }}</b></span
          >
        </div>
        <div class="season" style="margin-bottom: 10px">
          <span
            >{{ $t("Season") }}: <b>{{ data.season_id.name }}</b></span
          >
        </div> -->
      </div>
      <a-divider v-if="data.description" />
      <div class="desc" v-if="data.description">
        <h3>{{ $t("Description") }}</h3>
        <div class="description" style="margin-bottom: 10px">
          <span>{{ data.description }}</span>
        </div>
      </div>
      <a-divider />
      <div class="price">
        <h3>{{ $t("Price") }}</h3>
        <div class="purchase_price" style="margin-bottom: 10px">
          <span
            >{{ $t("Purchase Price") }}:
            <b
              >{{ user.firm.currency
              }}{{ moneyFormat(data.purchase_price).substr(1) }}</b
            ></span
          >
        </div>
        <div class="sale_price" style="margin-bottom: 10px">
          <span
            >{{ $t("Sale Price") }}:
            <b
              >{{ user.firm.currency
              }}{{ moneyFormat(data.sale_price).substr(1) }}</b
            ></span
          >
        </div>
      </div>
      <a-divider />
      <div class="price">
        <h3>{{ $t("Tax") }}</h3>
        <div class="purchase_price" style="margin-bottom: 10px">
          <span
            >{{ $t("Tax") }}: <b>%{{ data.tax }}</b></span
          >
        </div>
        <div class="sale_price" style="margin-bottom: 10px">
          <span
            >{{ $t("No Tax Sale Price") }}:
            <b
              >{{ user.firm.currency
              }}{{
                moneyFormat(data.sale_price / (1 + data.tax / 100)).substr(1)
              }}</b
            ></span
          >
        </div>
        <div class="sale_price" style="margin-bottom: 10px">
          <span
            >{{ $t("No Tax Purchase") }}:
            <b
              >{{ user.firm.currency
              }}{{
                moneyFormat(data.purchase_cost / (1 + data.tax / 100)).substr(1)
              }}</b
            ></span
          >
        </div>
      </div>
      <a-divider v-if="data.variants.length > 0" />
      <div class="variants" v-if="data.variants.length > 0">
        <h3>{{ $t("Colors") }}</h3>
        <div class="colors">
          <a-list size="small" bordered :data-source="colors">
            <template #renderItem="{ item }">
              <a-list-item>
                <span>{{ item }}</span>
              </a-list-item>
            </template>
          </a-list>
        </div>
        <a-divider />
        <h3>{{ $t("Sizes") }}</h3>
        <div class="sizes">
          <a-list size="small" bordered :data-source="sizes">
            <template #renderItem="{ item }">
              <a-list-item>
                <span>{{ item }}</span>
              </a-list-item>
            </template>
          </a-list>
        </div>
        <a-divider />
        <h3>{{ $t("Variants") }}</h3>
        <a-list size="small" bordered :data-source="data.variants">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #title>
                  <a>{{ item.sku }}</a
                  ><a-button
                    style="margin-left: 15px"
                    @click="printSku(item.sku)"
                    size="small"
                    type="primary"
                  >
                    <PrinterOutlined />
                    {{ $t("barcode print") }}
                  </a-button>
                </template>
              </a-list-item-meta>
              <span
                >{{ item.color ? item.color : "" }}
                {{ item.size ? item.size : "" }}</span
              >
            </a-list-item>
          </template>
        </a-list>
      </div>
      <!-- <a-divider />
      <h3>{{ $t("Purcases") }}</h3>
      <a-list size="small" bordered :data-source="purcases">
        <template #renderItem="{ item }">
          <a-list-item v-if="item.value != 0">
            <a-list-item-meta>
              <template #title>
                <a>{{ item.name }}</a>
              </template>
            </a-list-item-meta>
            <div v-if="item.name != $t('Dollar Total Purcase')">
              {{ user.firm.currency }}{{ moneyFormat(item.value).substr(1) }}
            </div>
            <div v-else>
              {{ moneyFormat(item.value) }}
            </div>
          </a-list-item>
        </template>
         <template #renderItem="{ item }">
          <a-list-item>
            <span>{{ item.name }}</span>
          </a-list-item>
        </template>
      </a-list> -->
      <a-divider />
      <h3>{{ $t("Stocks") }}</h3>
      <a-table
        :pagination="false"
        :scroll="{ x: 350 }"
        :columns="detailStock.columns"
        :data-source="detailStock.data"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key == 'sku'">
            {{ record.sku }}
          </template>
          <template v-if="column.key == 'value'">
            <span v-if="text < 0"
              ><b style="color: #cf1322">{{ text }}</b></span
            >
          </template>
        </template>
      </a-table>
      <a-divider />
      <div
        class=""
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
        "
      >
        <div class="">
          <h3>{{ $t("Sell And Returns") }}</h3>
        </div>
        <div class="">
          <a-select
            v-model:value="currency"
            @change="getSellAndReturn()"
            placeholder="Currency"
          >
            <a-select-option :value="'$'">$ Dollar</a-select-option>
            <a-select-option :value="'€'">€ Euro</a-select-option>
            <a-select-option :value="'₺'">₺ Lira</a-select-option>
            <a-select-option :value="'£'">£ Pound</a-select-option>
            <a-select-option :value="'₼'">₼ Manat</a-select-option>
            <a-select-option :value="'₴'">₴ Hryvnia</a-select-option>
            <a-select-option :value="'₽'">₽ Ruble</a-select-option>
            <a-select-option :value="'₹'">₹ Rupi</a-select-option>
            <a-select-option :value="'¥'">¥ Japanese Yen</a-select-option>
            <a-select-option :value="'złoty'">złoty Zloty</a-select-option>
            <a-select-option :value="'SR'">SR Riyal</a-select-option>
          </a-select>
        </div>
      </div>
      <a-list
        v-if="sellAndReturnLoad"
        size="small"
        bordered
        :data-source="saleData"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <a>{{ item.title }}</a>
              </template>
            </a-list-item-meta>
            <div
              v-if="
                item.title == $t('Total Sell Amount') ||
                item.title == $t('Total Return Amount')
              "
            >
              {{ currency }}{{ moneyFormat(item.value).substr(1) }}
            </div>
            <div v-else>
              {{ item.value }}
            </div>
          </a-list-item>
        </template>
        <!-- <template #renderItem="{ item }">
          <a-list-item>
            <span>{{ item.name }}</span>
          </a-list-item>
        </template> -->
      </a-list>
      <div class="" v-else>
        <Loader />
      </div>
    </div>
    <!-- <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button :loading="purcasLoad" @click="purcasePrint(data._id)" type="primary">
        <PrinterOutlined />{{ $t("Purcase Print") }}</a-button
      >
    </div> -->
  </div>
</template>

<script>
import VueBarcode from "@chenfengyuan/vue-barcode";
import { PrinterOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import Loader from "../shared/Loader.vue";
import qz from "qz-tray";
export default {
  props: [
    "data",
    "sizes",
    "colors",
    "detailStock",
    "sellAndRetuns",
    "showDrawer",
  ],
  data() {
    return {
      barcodeValue: "test",
      purcases: [],
      purcasLoad: false,
      user: JSON.parse(localStorage.getItem("user")),
      skuBarcode: false,
      sellAndReturnLoad: true,
      saleData: [],
      currency: "",
      barcodeType: "big",
      barcodeCount: 1,
      printers: [],
      selectedPrinter: null,
    };
  },
  mounted() {
    this.getPurcases();
    this.saleData = this.sellAndRetuns;
    this.currency = JSON.parse(localStorage.getItem("user")).firm.currency;
    const savedPrinter = localStorage.getItem("printer");
    if (savedPrinter) {
      this.selectedPrinter = savedPrinter;
    }
    const savedBarcodeType = localStorage.getItem("barcodeType");
    if (savedBarcodeType) {
      this.barcodeType = savedBarcodeType;
    }
  },
  methods: {
    validateBarcodeCount() {
      if (this.barcodeCount < 1) {
        this.barcodeCount = 1;
      }
    },
    async fetchPrinters() {
      await qz.websocket.disconnect();
      await qz.websocket
        .connect()
        .then(() => {
          console.log("QZ Tray bağlantısı başarılı");
          qz.printers
            .find()
            .then((foundPrinters) => {
              this.printers = foundPrinters.map((printer) => ({
                name: printer,
                value: printer,
              }));
            })
            .catch((err) => console.error("Yazıcıları bulma hatası:", err));
        })
        .catch((err) => console.error("Bağlantı hatası:", err));
    },
    saveSelectedPrinter(value) {
      localStorage.setItem("printer", value);
      this.selectedPrinter = value;
    },
    saveBarcodeType(value) {
      localStorage.setItem("barcodeType", value.target.value);
      this.barcodeType = value.target.value;
    },
    openSkuBarcodeModal() {
      qz.websocket
        .connect()
        .then(() => {
          console.log("QZ Tray bağlantısı başarılı");
          qz.printers
            .find()
            .then((foundPrinters) => {
              this.printers = foundPrinters.map((printer) => ({
                name: printer,
                value: printer,
              }));
            })
            .catch((err) => console.error("Yazıcıları bulma hatası:", err));
        })
        .catch((err) => console.error("Bağlantı hatası:", err));
      this.skuBarcode = true;
    },
    async getSellAndReturn() {
      this.sellAndReturnLoad = await false;
      this.saleData = await [];
      await axios
        .get(`/api/product/${this.data._id}?currency=${this.currency}`)
        .then(async (result) => {
          this.saleData = await [
            {
              title: this.$t("Total Sell Quantity"),
              value: result.data.sell.totalQuantity,
            },
            {
              title: this.$t("Total Sell Amount"),
              value: result.data.sell.totalPrice,
            },
            {
              title: this.$t("Total Return Quantity"),
              value: result.data.return.totalQuantity,
            },
            {
              title: this.$t("Total Return Amount"),
              value: result.data.return.totalPrice,
            },
          ];
          this.sellAndReturnLoad = await true;
        })
        .catch((err) => {
          this.sellAndReturnLoad = true;
          console.log("err", err);
        });
    },
    printBarcode(sku) {
      if (this.barcodeType == "small") {
        this.printSmallBarcode(sku);
      } else {
        this.printBigBarcode(sku);
      }
    },
    printBigBarcode2(sku) {
      const qr = document.getElementById("qr-code-print");
      htmlToImage
        .toPng(qr)
        .then(function (dataUrl) {
          const link = document.createElement("a");
          console.log(dataUrl);
          var popupWindow = window.open("", "Print", "height=500,width=600");
          popupWindow.document.write(
            "<html><head></head><body>" +
              '<div style="display:flex;justify-content:center;align-items:center"><img style="min-width:200px;max-width:200px;min-height:80px;max-height:100px;" src="' +
              dataUrl +
              '" onload="window.print()" /></div>' +
              "</body></html>"
          );
          popupWindow.document.close();
          popupWindow.focus();
          names.print();
          popupWindow.close();
          link.click();
        })
        .catch(function (error) {
          console.error("QR kodu resmi oluşturulurken hata oluştu", error);
        });
    },
    convertTurkishCharacters(text) {
      const charMap = {
        ç: "c",
        Ç: "C",
        ğ: "g",
        Ğ: "G",
        ı: "i",
        İ: "I",
        ö: "o",
        Ö: "O",
        ş: "s",
        Ş: "S",
        ü: "u",
        Ü: "U",
      };
      return text.replace(/[çğışöüÇĞİŞÖÜ]/g, (match) => charMap[match]);
    },
    formatProductName(name, qty) {
      const maxLength = qty;
      const formattedName = this.convertTurkishCharacters(name);
      return formattedName.length > maxLength
        ? formattedName.substr(0, maxLength) + "..."
        : formattedName;
    },

    printSmallBarcode(sku) {
      const printData = {
        name: this.formatProductName(this.data.name, 10),
        sku: this.data.sku,
        price: `${this.moneyFormat(this.data.sale_price).substr(1)}${
          this.user.firm.currency == "₺" ? "TL" : this.user.firm.currency
        }`,
      };

      const config = qz.configs.create(this.selectedPrinter);

      const data = [
        "^XA",
        "^LT-40",
        "^BY1,2,130",
        `^FO310,1^BC^FD${printData.sku}^FS`,
        "^CF0,20",
        `^FO290,160^FD${printData.name}^FS`,
        "^CF0,20,",
        `^FO420,160^FD${printData.price}^FS`,
        "^XZ",
      ];

      const multiPrintData = Array(Number(this.barcodeCount)).fill(data).flat();

      qz.print(config, multiPrintData)
        .then(() => console.log("Yazdırma başarılı"))
        .catch((err) => console.error("Yazdırma hatası:", err));
    },
    printBigBarcode(sku) {
      const printData = {
        name: this.formatProductName(this.data.name, 15),
        sku: this.data.sku,
        price: `${this.moneyFormat(this.data.sale_price).substr(1)}${
          this.user.firm.currency == "₺" ? "TL" : this.user.firm.currency
        }`,
      };

      const config = qz.configs.create(this.selectedPrinter);

      const data = [
        "^XA",
        "^LT10",
        "^BY2,2,100",
        `^FO220,25^BC^FD${printData.sku}^FS`,
        "^CF0,30",
        `^FO220,170^FD${printData.name}^FS`,
        "^CF0,30",
        `^FO450,170^FD${printData.price}^FS`,
        "^XZ",
      ];

      const multiPrintData = Array(Number(this.barcodeCount)).fill(data).flat();

      qz.print(config, multiPrintData)
        .then(() => console.log("Yazdırma başarılı"))
        .catch((err) => console.error("Yazdırma hatası:", err));
    },
    printSmallBarcode2(sku) {
      const qr = document.getElementById("qr-code-print");
      htmlToImage
        .toPng(qr)
        .then(function (dataUrl) {
          const link = document.createElement("a");
          console.log(dataUrl);
          var popupWindow = window.open("", "Print", "height=500,width=600");
          popupWindow.document.write(
            "<html><head></head><body>" +
              '<div style="display:flex;justify-content:center;align-items:center;margin-top:70px;gap: 90px;margin-left: -130px;"><div style="width: 60px;"><img style="transform: rotate(90deg);width: 265px;height: 120px;;" src="' +
              dataUrl +
              '" onload="window.print()" /></div><div style="width: 60px;"><img style="transform: rotate(90deg);width: 265px;height: 120px;;" src="' +
              dataUrl +
              '" onload="window.print()" /></div><div style="width: 60px;"><img style="transform: rotate(90deg);width: 265px;height: 120px;;" src="' +
              dataUrl +
              '" onload="window.print()" /></div><div style="width: 60px;"><img style="transform: rotate(90deg);width: 265px;height: 120px;;" src="' +
              dataUrl +
              '" onload="window.print()" /></div></div>' +
              "</body></html>"
          );
          popupWindow.document.close();
          popupWindow.focus();
          names.print();
          popupWindow.close();
          link.click();
        })
        .catch(function (error) {
          console.error("QR kodu resmi oluşturulurken hata oluştu", error);
        });
    },
    showModalBarcode() {
      this.skuBarcode = !this.skuBarcode;
    },
    async purcasePrint(data) {
      this.purcasLoad = true;
      await axios
        .get(`/api/product/generate-purchase-pdf/${data}`)
        .then((result) => {
          console.log(result.data.path);
          window.open(result.data.path, "_blank");
          this.purcasLoad = false;
        })
        .catch((err) => {
          this.purcasLoad = false;
          console.log(err);
        });
    },
    getPurcases() {
      this.purcases.push(
        {
          name: this.$t("Purchase Cost"),
          value: this.data.purchase_cost,
        },
        {
          name: this.$t("Material Cost"),
          value: this.data.material_cost,
        },
        {
          name: this.$t("Cutting Cost"),
          value: this.data.cut_cost,
        },
        {
          name: this.$t("Planting Cost"),
          value: this.data.planting_cost,
        },
        {
          name: this.$t("Printing Cost"),
          value: this.data.print_cost,
        },
        {
          name: this.$t("Stone Cost"),
          value: this.data.stone_cost,
        },
        {
          name: this.$t("Embroidery Cost"),
          value: this.data.embroidery_cost,
        },
        {
          name: this.$t("Washing Cost"),
          value: this.data.washing_cost,
        },
        {
          name: this.$t("Accessory Cost"),
          value: this.data.accessory_cost,
        },
        {
          name: this.$t("Ironing Cost"),
          value: this.data.ironing_cost,
        },
        {
          name: this.$t("Packaging Cost"),
          value: this.data.packaging_cost,
        },
        {
          name: this.$t("Other Costs"),
          value: this.data.other_cost,
        },
        {
          name: this.$t("Total Purcase"),
          value: this.data.purchase_price,
        },
        {
          name: this.$t("Dollar Rate"),
          value: this.data.dollar_rate,
        },
        {
          name: this.$t("Dollar Total Purcase"),
          value: this.data.dollar_purchase_price,
        }
      );
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: { PrinterOutlined, barcode: VueBarcode, QrcodeVue, Loader },
};
</script>

<style lang="scss" scoped></style>
