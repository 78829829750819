<template>
  <div class="">
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
      "
    >
      <PageTitle :title="$t('Internals')" />
      <router-link to="/internals"
        ><a-button>{{ $t("Go to Back") }}</a-button></router-link
      >
    </div>
    <a-layout-content
      :style="{
        margin: '0px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <a-tag color="yellow" style="width: 100%; padding: 10px; margin: 10px 0 20px 0">{{
        $t("Only the description can be changed because internal processing is done")
      }}</a-tag>
      <a-form v-if="load" layout="vertical">
        <div
          class=""
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 15px;
          "
        >
          <div class="">
            <div
              class=""
              style="
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 15px;
              "
            >
              <span for="">{{ $t("Type") }}:</span>
              <a-tag v-if="form.type == 'input'" message="Sell" color="green">{{
                $t("Input")
              }}</a-tag>
              <a-tag v-else message="Output" color="yellow">{{ $t("Output") }}</a-tag>
            </div>
            <div
              class=""
              style="
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 15px;
                margin-top: 15px;
              "
            >
              <span for="">{{ $t("Branch") }}:</span>
              <span
                ><b>{{ form.branch_id.name }}</b></span
              >
            </div>
            <div
              class=""
              style="
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 15px;
                margin-top: 15px;
              "
            >
              <span for="">{{ $t("Date") }}:</span>
              <span
                ><b>{{ new Date(form.date).toLocaleString().substring(0, 10) }}</b></span
              >
            </div>
            <div class="" style="margin-top: 15px">
              <label for="">{{ $t("Description") }}</label>
              <a-textarea
                rows="5"
                cols="80"
                v-model:value="form.desc"
                :placeholder="$t('Description')"
              />
            </div>
          </div>
          <div class="">
            <h3>
              <b>{{ $t("Products") }}</b>
            </h3>
            <a-divider />
            <div
              class="title"
              style="display: flex; justify-content: start; align-items: flex-start"
            >
              <div class="">
                <div
                  class=""
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;
                  "
                  v-for="(item, index) in products"
                  :key="index"
                >
                  <a-form-item>
                    <label for="" style="font-size: 12px; color: #959595">{{
                      $t("SKU")
                    }}</label>
                    <a-input
                      v-model:value="item.sku"
                      placeholder="SKU"
                      @change="cc()"
                      disabled
                    />
                  </a-form-item>
                  <a-form-item>
                    <label for="" style="font-size: 12px; color: #959595">{{
                      $t("Quantity")
                    }}</label>
                    <a-input
                      v-model:value="item.quantity"
                      placeholder="Quantity"
                      @change="cc()"
                      disabled
                    />
                  </a-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-form-item>
          <div class="" style="display: flex; justify-content: end">
            <a-button type="primary" :loading="loadBtn" @click="update()">{{
              $t("Update")
            }}</a-button>
          </div>
        </a-form-item>
      </a-form>
      <Loader v-else />
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle.vue";
import { notification } from "ant-design-vue";
import axios from "axios";
import Loader from "@/components/shared/Loader.vue";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  components: { PageTitle, PageTitle, Loader, MinusOutlined, PlusOutlined },
  data() {
    return {
      form: {},
      loadBtn: false,
      load: false,
      products: [],
    };
  },
  created() {
    this.getData();
    this.getBranchs();
  },
  methods: {
    async update() {
      this.loadBtn = true;
      var data = {
        desc: this.form.desc,
      };
      await axios
        .patch(`/api/internal/${this.$route.params.id}`, data)
        .then(async (result) => {
          this.loadBtn = false;
          await notification.success({
            message: this.$t("Success"),
            description: this.$t("Transaction Successfully Performed"),
            duration: 1.5,
          });
        })
        .catch(async (err) => {
          this.loadBtn = false;
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    async getBranchs() {
      await axios
        .get("/api/branch/all")
        .then((result) => {
          this.branchs = result.data;
        })
        .catch((err) => {});
    },
    getData() {
      axios
        .get(`/api/internal/${this.$route.params.id}`)
        .then((result) => {
          this.form = result.data;
          this.load = true;
          this.products = result.data.products;
        })
        .catch((err) => {});
    },
    addProduct() {
      this.products.push({
        id: Math.ceil(Math.random() * 8498615),
        sku: "",
        quantity: null,
      });
    },
    cc() {
      console.log(this.products);
    },
    removeProduct(id) {
      const check = this.products.find((e) => e.id == id);
      const index = this.products.indexOf(check);
      this.products.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
