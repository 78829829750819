<template>
    <div>
      <div class="">
        <div class="info">
          <h3>{{$t('Info')}}</h3>
          <div class="name" style="margin-bottom: 10px">
            <span
              >{{ $t('Name') }}: <b>{{ data.name }}</b></span
            >
          </div>
          <div class="email" style="margin-bottom: 10px">
            <span
              >{{$t('E-mail')}}: <b>{{ data.email }}</b></span
            >
          </div>
          <div class="name" style="margin-bottom: 10px">
            <span
              >{{$t('Role')}}: <b>{{ data.role_id.name }}</b></span
            >
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    props: ["data"],
  };
  </script>

  <style lang="scss" scoped></style>
